import styled from 'styled-components';
import StyledDatepicker from 'StyledDatepicker.js';
import {Form, Input, Button} from 'global/styles/styles.js';
const S = {};

S.AccountEditProfileForm = styled(Form)`
 width: 100%;
 display: flex;
`;

S.SaveIcon = styled.i`
    width: .92rem;
    height: .92rem;
    background: transparent url(/images/svg/save-icon.svg) no-repeat 0 0;
    background-size: .92rem .92rem;
    position: absolute;
    right: 9px;
    top: 33px;
    margin: auto 0;
`;

S.Row = styled.div``;

S.Label = styled.label`
    flex-basis: 27rem;
    margin-right: .83rem;
    text-align: left;
    color: #adadad;
    font-size: 1.0833rem;
    font-weight: 400;
    display: flex;
`;

S.Input = styled(Input)`
    box-shadow: none;
    color: #000;
    display: block;
    width: 100%;
    font-size: 1.5rem;
    font-weight: 500;
    border: none;
    border-bottom: .08rem solid #dedede;
    padding-right: 1.67rem;
    background-color: transparent;
    height: 2.5rem;
    border-radius: 0;
    &[disabled]{
        border: none;
        cursor: not-allowed;
    }
    &.has-error{
        border: .08rem solid red;
        border-radius: .42rem .42rem 0 0;
    }
`;

S.SubmitBtn = styled(Button)`
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 700;
    background-color: #3c3c3c;
    color: #fff;
    cursor: pointer;
    width: 17rem;
    height: 4.7916rem;
    text-align: center;
    border-radius: 5px;
    &[disabled]{
        background-color: #eaeaea;
        color: #000;
    }
`;

S.Error = styled.div`
    color: red;
    float: left;
    width: 100%;
    background-color: #fba59f;
    margin-top: 0;
    border-radius: 0 0 2px 2px;
    padding: 2px .85rem 2px .85rem;
`;

S.SubmissionError = styled.div`
    margin: 0;
    border-radius: .25rem;
    width: 100%;
    color: red;
    font-size: 1rem;
    text-align: left;
    padding: .83rem;
    background-color: #fba59f;
`;

S.SuccessModal = styled.div`
     width: 100%;
`;

S.SuccessModalTitle = styled.h4`
    color: #2d9ef5;
    text-transform: uppercase;
    font-size: 1.83rem;
    padding: 0;
    font-weight: 800;
    font-style: italic;
    float: none!important;
    text-align: center;
    margin-bottom: 3.42rem;
`;

S.SuccessModalMessage = styled.p`
    text-align: center;
    color: #000;
    margin: 0;
    font-size: 2rem;
    letter-spacing: .2px;
    font-weight: 400;
`;

S.InputWrapper = styled.div`
    position: relative;
    margin-bottom: 2.25rem;

    & select{
        display: block;
    }
    
    ${StyledDatepicker.Datepicker}{
        flex: 1;
        border: none;
        font-size: 17px;
        font-weight: 700;
        width: 100%;
}
`;

S.ChangePasswordBtn = styled(Button)`
    font-size: 1rem;
    line-height: 1rem;
    background-color: #3c3c3c;
    color: #fff;
    cursor: pointer;
    width: 17rem;
    height: 4.7916rem;
    text-align: center;
    border-radius: 5px;
`;

export default S;