import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import _size from 'lodash/size';
import _set from 'lodash/set';
import _get from 'lodash/get';
import _reduce from 'lodash/reduce';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import S from 'StyledLeftMenu.js';
import DropdownMenu from 'DropdownMenu.js';
import FavouriteLeagues from 'FavouriteLeagues.js';
import Loader from 'Loader.js';
import {
    fetchCategories,
    selectLeagueById,
    deselectLeagueById,
    changeSportId,
    changeTimeFilter,
    toggleSelectedLeague,
    toggleSelectedLeaguesForSports,
    resetSlipList
} from 'categoriesActions.js';
import {toggleFavouriteForLeague} from 'favouritesActions.js';
import {dropdownMenuSelector} from 'categoriesSelector.js';
import {favouriteLeaguesSelector} from 'favouriteLeaguesSelector.js';
import {translation, waitUntilComponentRendered} from 'helpers/utilsHelper.js';
import {CSSTransition} from 'react-transition-group';
import _ from 'lodash';


class LeftMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categoriesIsOpen: true,
            resetButtonVisible:false
        };
    }

    static getDerivedStateFromError = (error) => {
        return {hasError: true};
    };

    state = {hasError: false};

    componentDidCatch = (error, info) => {
        console.log({error});
    };

    componentDidMount = async() => {
        const {dispatch} = this.props;
        try {

            this.listenOnCustomEvent();

            const categories = await dispatch(fetchCategories());

            if (location.search && /\?filterEventsByTime=\d+/.test(location.search)) {
                const time = location.search.replace(/\?filterEventsByTime=(\d+).*/, '$1');
                await dispatch(changeTimeFilter(Number(time)));
            }

            if (location.pathname && /\/sports\/events\/.+(?:,.+)*\/\d+(,\d+)*\/.*/u.test(location.pathname)) {
                let categoriesFromPath = location.pathname.replace(/\/sports\/events\/.+(?:,.+)*\/(\d+(,\d+)*)\/.*/u, '$1');

                categoriesFromPath = String(categoriesFromPath).split(',');

                const favouriteLeaguesGroupBySport = _reduce(categoriesFromPath, (initialArray, leagueId) => {

                    leagueId = Number(leagueId);
                    const category = _find(categories, {categoryId: leagueId});
                    const sportId = _get(category, 'sportId');

                    const leaguesIdsMappedToSportExist = _find(initialArray, {sportId});
                    if (leaguesIdsMappedToSportExist) {
                        const leaguesFromSport = _get(leaguesIdsMappedToSportExist, 'leagues');
                        leaguesFromSport.push(leagueId);
                    } else {
                        initialArray.push({sportId, leagues: [].concat(leagueId)})
                    }
                    return initialArray;
                }, []);

                dispatch(toggleSelectedLeaguesForSports(favouriteLeaguesGroupBySport));

                console.log('dispatch toggleSelectedLeaguesForSports');

                try {
                    Promise.all([
                        waitUntilComponentRendered(app.component.EventsList),
                        waitUntilComponentRendered(app.component.SportCategoryFilter)
                    ]).then(([eventListComponent, sportCategoryFilterComponent]) => {

                        const waitUntilRequestFullfilled = (component) => {
                            var d = $.Deferred();
                            var isRendered = () => {
                                if (!component.dataLoaded) {
                                    setTimeout(isRendered, 100);
                                } else {
                                    d.resolve(component);
                                }
                            };
                            isRendered();
                            return d.promise();
                        };

                        waitUntilRequestFullfilled(app.component.EventsList).then(() => {
                            console.log('component method loaded');
                            this.dispatchCustomEvent(eventListComponent, sportCategoryFilterComponent);
                        })

                    });

                } catch (e) {
                    console.log(e.message);
                }
            }

        } catch (error) {
            console.log('fetchCategories error:', error);
        }
    };

    dispatchCustomEvent = (eventListComponent, sportCategoryFilterComponent) => {

        console.log('dispatchCustomEvent');

        const sportCategoryFilterLoadedCustomEvent = new CustomEvent('sportCategoryFilterLoaded', {
            detail: {sports: eventListComponent.listData.sports, view: 'full'},
            bubbles: true
        });
        sportCategoryFilterComponent.rootSelector().get(0).dispatchEvent(sportCategoryFilterLoadedCustomEvent);
    };
    resetSlipList = ()=>{
        const {dispatch} = this.props;
        dispatch(resetSlipList());

        app.component.PopularCategoriesWidget.selectedLeagues = {};
        
        app.router.redirect('/');
    }

    listenOnCustomEvent = () => {
        document.addEventListener('sportCategoryFilterLoaded', (e) => {
            app.component.SportCategoryFilter.renderSportCategoryFilter(e.detail.sports, null, e.detail.view);

            console.log('run from load');
        });
    };

    toggleCategoriesVisibility = () => {
        this.setState(prevState => ({
            categoriesIsOpen: !prevState.categoriesIsOpen
        }));
    }

    render() {
        const {isPending, categories, ...rest} = this.props;
        const {hasError} = this.state;
        const {time, toggleFavouriteForLeague, favouriteLeagues, orgCategories, selectedLeaguesBySport, sportsOrder, toggleSelectedLeague, toggleSelectedLeaguesForSports, outrights, isFilteredCategoriesRequestPending, isLogged, currentTheme} = rest;
        const favouriteLeaguesProps = {
            time,
            toggleFavouriteForLeague,
            favouriteLeagues,
            orgCategories,
            selectedLeaguesBySport,
            sportsOrder,
            toggleSelectedLeague,
            toggleSelectedLeaguesForSports,
            outrights,
            isFilteredCategoriesRequestPending,
            currentTheme
        };
        const shouldResetButtonBeVisible = outrights.length > 0 || !_isEmpty(selectedLeaguesBySport)? true:false;

        if (hasError) {
            return <div>Error fallback component!</div>
        }
        if (isPending) {
            return <div>Loading ...</div>
        }

        return (
            <S.LeftMenu className="left-menu">
                <S.ConfigRemover isVisible={shouldResetButtonBeVisible} onClick={this.resetSlipList} data-translation='leftMenu_resetList'>{translation('leftMenu_resetList')}</S.ConfigRemover>
                {isLogged && <FavouriteLeagues {...favouriteLeaguesProps}/>}
                <S.Header className="categories-header" onClick={this.toggleCategoriesVisibility} isActive={this.state.categoriesIsOpen}>
                    <S.HeaderIcon className={`sport-icon`}><use href={`#sport-0`}></use></S.HeaderIcon>
                    <S.HeaderText className="header-text">{translation('leftMenu_allSportsHeader')}</S.HeaderText>
                    <S.Arrow className="categories-header-arrow"/>
                </S.Header>
                {<S.DropdownMenuWrapper className="dropdown-menu-wrapper" isVisible={this.state.categoriesIsOpen}>
                        {isFilteredCategoriesRequestPending && (
                            <S.Cover className="cover"><Loader size="5" color="#F05A22"/></S.Cover>)}
                        <CSSTransition in={this.state.categoriesIsOpen} timeout={1000} className="slide-transition" classNames="slide-transition"  unmountOnExit>     
                            <DropdownMenu config={categories} {...rest} />
                        </CSSTransition> 
                </S.DropdownMenuWrapper>
                }
            </S.LeftMenu>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {Auth:{isLogged}, Categories:{isPending, error, selectedLeagues, prevSortId, categories:orgCategories, time, categoriesByTime, selectedLeaguesBySport, sportsOrder, outrights, isFilteredCategoriesRequestPending}, Theme:{currentTheme}} = state;
    const categories = dropdownMenuSelector(state, props);
    const favouriteLeagues = favouriteLeaguesSelector(state, props);
    return {
        isFilteredCategoriesRequestPending,
        sportsOrder,
        selectedLeaguesBySport,
        favouriteLeagues,
        isLogged,
        selectedLeagues,
        categories,
        isPending,
        error,
        prevSortId,
        orgCategories,
        categoriesByTime,
        time,
        outrights,
        currentTheme
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        selectLeagueById: bindActionCreators(selectLeagueById, dispatch),
        deselectLeagueById: bindActionCreators(deselectLeagueById, dispatch),
        changeSportId: bindActionCreators(changeSportId, dispatch),
        changeTimeFilter: bindActionCreators(changeTimeFilter, dispatch),
        toggleSelectedLeague: bindActionCreators(toggleSelectedLeague, dispatch),
        toggleSelectedLeaguesForSports: bindActionCreators(toggleSelectedLeaguesForSports, dispatch),
        toggleFavouriteForLeague: (categoryId, status, e) => {
            e.stopPropagation();
            dispatch(toggleFavouriteForLeague(categoryId, status));
        },
        resetSlipList:bindActionCreators(resetSlipList,dispatch),
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenu);
