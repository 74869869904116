import styled from 'styled-components';
import {List, Link} from 'global/styles/styles.js';
const S = {};

S.MenuList = styled(List)`
    height: 100%;
    display: flex;
    align-items: center;
    flex: 0 1 43rem;
    margin-right: .83rem;
    padding-left: 3rem;
`;

S.MenuLink = styled(Link)`
    height: 100%;
    line-height: 6.67rem;
    color: ${({theme:{$topMenuLinkColor}})=> $topMenuLinkColor};
    text-decoration: none;
    width: 100%;
    font-size: 1.4rem;
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
    font-style: normal;
    padding: 0 .8rem 1rem .8rem;
    text-decoration: none !important;
`;

S.MenuListItem = styled.li`
    display: flex;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: .2s all linear;
    text-align: center;
    padding: 0 1rem;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-style: italic;
    position: relative;
    &:after {
        display: none;
    }
    
    &.is-active {
        &:after {
            content: '';
            position: absolute;
            bottom: 30%;
            left: .8rem;
            right: .8rem;
            height: .6rem;
            background-color: ${({theme:{$topMenuListItemActiveColor}})=> $topMenuListItemActiveColor};
            display: block;
        }
        
        & ${S.MenuLink}
        {
            font-weight: 600;
            color: ${({theme:{$topMenuActiveLinkColor}})=> $topMenuActiveLinkColor};
        }
    }
`;

S.SubMenuList = styled(List)``;

S.SubMenuListItem = styled.li``;

S.SubMenuLink = styled.a``;

export default S;