import React from 'react';
import S from 'StyledLoginForm.js';
import {Field, reduxForm, SubmissionError } from 'redux-form';
import {notifyError, translation} from 'helpers/utilsHelper.js';
import { activateSportRadarEventsTrack } from 'integrationActions.js';

// Validation
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';

const validate = values => {
    const errors = validation(values, validators.LOGIN);
    return errors;
};

const renderField = ({
    input,
    label,
    type,
    meta: {touched, error, warning}
}) => {
    return (
        <S.Row>
            <S.InputWrapper>
                <S.Input {...input} type={type} required="required"/>
                <S.Label>{label}</S.Label>
                {touched && ((error && <S.Error>{error[0]}</S.Error>) || (warning && <S.Error>{warning}</S.Error>))}
            </S.InputWrapper>
        </S.Row>
    )
};

const LoginForm = ({loginOnSubmit, toggleForgetPasswordModal, handleSubmit, pristine, submitting, error}) => {

    const onFormSubmit = async(values) => {
        try {
            const user = await loginOnSubmit(values);
            activateSportRadarEventsTrack("login", "user", { action: "complete", userId: user.userId.toString()});
        } catch (err) {
            throw new SubmissionError({_error: err});
        }
    };

    return (
        <S.LoginForm method="post" onSubmit={handleSubmit(onFormSubmit)}>

            <Field
                name="login"
                type="text"
                component={renderField}
                label="Login"
            />
            <Field
                name="password"
                type="password"
                component={renderField}
                label="Password"
            />

            {error && <S.SubmissionError className="submission-error">{error}</S.SubmissionError>}

            <S.SubmitBtn type="submit" disabled={pristine || submitting}>Log in</S.SubmitBtn>

            <S.ForgetPasswordLink onClick={toggleForgetPasswordModal}>
                {translation('loginModal_forgotPassword')}
            </S.ForgetPasswordLink>

        </S.LoginForm>
    );
}

export default reduxForm({
    form: 'login',
    validate
})(LoginForm)