
import typeToReducer from 'type-to-reducer';
import update from 'immutability-helper';

import {
    LOAD_GAME_TYPE_GROUPS_FOR_SPORT
} from 'marketActions.js'

let initialState = {
    error: null,
    marketGroupTypes: []
}

export default typeToReducer({
    [LOAD_GAME_TYPE_GROUPS_FOR_SPORT]: {
        PENDING: (state, action) => {
            return {...state}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {marketGroupTypes}}) => {
            return {...state, isPending: false, marketGroupTypes};
        }
    }
}, initialState);
