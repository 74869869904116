import React, {useState, useRef, useEffect} from 'react';
import S from 'StyledGameTypeGroupFilterWithCategories.js';
import {translation} from 'helpers/utilsHelper.js';

let GameTypeGroupFilterWithCategories = ({
    soccerGameTypeGroupIds,
    categories,
    fetchCategoriesByGroupId,
    selectLeagueForSport,
    setCategoriesGroupId}) => {

    const filterListRef = React.createRef();
    const filterContainerRef = React.createRef();
    const [activeGameTypeGroupId, setActiveGameTypeGruopId] = useState(null);
    const [checkedCategories, setCheckedCategories] = useState([]);

    useEffect(() => {
        setActiveGameTypeGruopId(soccerGameTypeGroupIds[0]);
    },[soccerGameTypeGroupIds]);

    const filterScrollLeft = () => {
        let currentMargin = parseInt($(filterContainerRef.current).css('margin-left').replace('px',''));
        if (currentMargin + 80 <= 0){
            $(filterContainerRef.current).css('margin-left', (currentMargin + 80) + 'px');
        }
    };

    const filterScrollRight = () => {
        const currentMargin = parseInt($(filterContainerRef.current).css('margin-left').replace('px',''));
        const width = parseInt($(filterContainerRef.current).css('width').replace('px',''));
        const viewWidth = parseInt($(filterListRef.current).css('width').replace('px',''));
        if (width - (currentMargin*(-1) + viewWidth ) >= 0){
            $(filterContainerRef.current).css('margin-left', (currentMargin - 80) + 'px');
        }
    };

    const selectGameTypeGroup = (gameTypeGroupId) => {
        if (gameTypeGroupId != activeGameTypeGroupId){
            setActiveGameTypeGruopId(gameTypeGroupId);
            fetchCategoriesByGroupId(gameTypeGroupId);
            setCheckedCategories([]);
        }
    };

    const getMarketGroupButtons = () => {
        let gameTypeGroupBtnsJSX = [];
        for (let gameTypeGroupId of soccerGameTypeGroupIds) {
            gameTypeGroupBtnsJSX.push(
                <S.FilterBtn key={gameTypeGroupId} 
                    className = { (gameTypeGroupId == activeGameTypeGroupId) ? 'active':'' }
                    onClick={() => selectGameTypeGroup(gameTypeGroupId)}>
                    { translation(`marketType_${gameTypeGroupId}`) }
                </S.FilterBtn>
            );
        }

        return gameTypeGroupBtnsJSX;
    };

    const clickCategoryCheckbox = (categoryId) => {
        if (checkedCategories.indexOf(categoryId) > -1){
            setCheckedCategories(checkedCategories.filter(i => i != categoryId));
        } else {
            setCheckedCategories([...checkedCategories, categoryId]);
        }
    };

    const getCategoriesList = () => {
        let categoriesJSX = [];
        for (let category of categories){
            const { eventsCount, id, name, parentId, parentName } = category; 
            const checkboxIsActive = checkedCategories.indexOf(id) > -1 ? true : false; 
            const categoryName = `${parentName}, ${name}`;
            categoriesJSX.push(
                <S.FilterCategoryRow key={id}>
                    <S.FilterCategoryNameContainer>
                        <S.FilterCategoryFlag src={app.config.getStaticImageForCategories(parentId)} onError={(e) => app.config.handleIncorrectImages(e)}></S.FilterCategoryFlag>
                        <S.FilterCategoryName> { categoryName } </S.FilterCategoryName>
                    </S.FilterCategoryNameContainer>
                    <S.FilterCheckboxContainer>
                        <S.FilterEventCount> { eventsCount } </S.FilterEventCount>
                        <S.FakeChbox className={ checkboxIsActive ? 'is-active':null} onClick={() => clickCategoryCheckbox(id)}></S.FakeChbox>
                    </S.FilterCheckboxContainer>
                </S.FilterCategoryRow>
            );
        }
        return categoriesJSX; 
    };

    const showFilteredEvents = () => {
        if (!_.size(checkedCategories)){
            return;
        }

        const sportName = categories && _.size(categories) ? categories[0].sportName : 'Soccer';

        const params = {};
        params['categories'] = checkedCategories.join(',');
        params['selectedSports'] = sportName;

        selectLeagueForSport(checkedCategories,1)
        setCategoriesGroupId(activeGameTypeGroupId)
        const urlParams = {};
        urlParams['groupId'] = activeGameTypeGroupId;

        app.router.redirect('/sports/events/:selectedSports/:categories', params, urlParams);
    };

    const clearSelectionForCategories = () => {
        setCheckedCategories([]);
    };

    return (
        <S.ComponentWrapper>
            <S.PanelHeader>
                <S.PanelHeaderLeftSideColor></S.PanelHeaderLeftSideColor>
                <S.PanelHeaderText>
                    {  translation('gameTypeGroupCategoriesFilter_header') }
                    <S.NewIcon></S.NewIcon>
                </S.PanelHeaderText>
            </S.PanelHeader>

            <S.FilterContainer>
                <S.FilterScrollBtnContainer> 
                    <S.FilterScrollBtn className="left" onClick={ () => filterScrollLeft() }></S.FilterScrollBtn>
                </S.FilterScrollBtnContainer>

                <S.FilterBtnsList ref={filterListRef}>
                    <S.FilterBtnsRelativeContainer>
                        <S.FilterBtnsContainer ref={filterContainerRef}>
                             { soccerGameTypeGroupIds && getMarketGroupButtons() }
                        </S.FilterBtnsContainer>
                    </S.FilterBtnsRelativeContainer>
                </S.FilterBtnsList>
                
                <S.FilterScrollBtnContainer>
                <S.FilterScrollBtn className="right" onClick={ () => filterScrollRight() }></S.FilterScrollBtn>
                </S.FilterScrollBtnContainer>
            </S.FilterContainer>

            <S.FilterCategoriesContainer className="game-group-category-filter-container" ref={(node) => node && node.style.setProperty('overflow','hidden','important')}>
                    { categories && getCategoriesList() }
            </S.FilterCategoriesContainer>

            <S.FooterPanel>
                <S.ShowEventsButton className={ _.size(checkedCategories) ? 'active' : null} onClick={() => showFilteredEvents() }>
                    { translation('gameTypeGroupCategoriesFilter_button') }
                </S.ShowEventsButton>
                <S.ClearSelectionButton className={ _.size(checkedCategories) ? null : 'hidden' } onClick={() => clearSelectionForCategories() }>
                    { translation('gameTypeGroupCategoriesFilter_clear') }
                </S.ClearSelectionButton>
            </S.FooterPanel>
        </S.ComponentWrapper>
    );
};

export default GameTypeGroupFilterWithCategories;
