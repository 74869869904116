import styled,{css} from 'styled-components';
import StyledLoader from 'StyledLoader.js';
import {List} from 'global/styles/styles.js';
const S = {};

const duration = 1000;

S.FavouriteLeagues = styled.div`
    margin: 0 0 10px;
    font-size: 13px;
    background-color: ${({theme:{$arrowFavouriteLeaguesBgColor}})=> $arrowFavouriteLeaguesBgColor};
    border-radius: 3px;
    overflow: hidden;
`;

S.Arrow = styled.div`
    height: 100%;
    margin: 0 5px 0 0;
    display: flex;
    align-items: center;
    margin-right: 5px;
    transform: rotate(90deg);
    &:before {
        content: '';
        width:  1.2rem;
        height: .87rem;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        background-color: ${({theme:{$arrowFavouriteLeagues}})=> $arrowFavouriteLeagues};
        mask-size:  1.2rem .87rem;
        -webkit-mask-size:  1.2rem .87rem;
        mask: url(/images/svg/chevron_right_grey.svg) no-repeat 50% 50%;
        -webkit-mask: url(/images/svg/chevron_right_grey.svg) no-repeat 50% 50%;
    }
    &.favourite-leages-header-arrow {
        &:before {
            background-color: ${({ theme: { $favouriteLeaguesHeaderArrowColor } }) => $favouriteLeaguesHeaderArrowColor};
        }
    }
`;

S.FavouriteIcon = styled.div`
    width: 15px;
    height: 15px;
    cursor: pointer;
    line-height: 0;
    & svg{
        & path{
            fill: #FFB203 !important;
        }
    }
`;

S.Header = styled.div`
    background-color: ${({theme:{$favouriteLeaguesHeaderBgColor}})=> $favouriteLeaguesHeaderBgColor};
    border-radius: 3px 3px 0 0;
    height: 48px;
    text-transform: uppercase;
    color: ${({theme:{$favouriteLeaguesHeaderColor}})=> $favouriteLeaguesHeaderColor};
    display: flex;
    padding: 0 11px 0 17px;
    align-items: center;
    cursor: pointer;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        height: 48px;
        width: 3px;
        border-top-left-radius: 3px;
        background-color: ${({ theme: { $favouriteLeaguesHeaderLeftSidePanelColor } }) => $favouriteLeaguesHeaderLeftSidePanelColor};
        left: 0;
    }

    & ${S.Arrow} {
        margin-left: auto;
    }

    & ${S.FavouriteIcon}{
        width: 15px;
        height: 15px;
        margin-right: 24px;
        & svg {
            & path{
                fill: #BCBCBC !important;
            }
        }
    }

    & ${S.HeaderText}{
        color: ${({theme:{$favouriteLeaguesHeaderText}})=> $favouriteLeaguesHeaderText};
        font-size: 1.16rem;
    }

    ${({isActive}) => {

    return isActive == true && css`
        background-color: ${({theme:{$favouriteLeaguesHeaderActiveBgColor}})=> $favouriteLeaguesHeaderActiveBgColor};

        & ${S.Arrow}{
            transform: rotate(270deg);
        }

        & ${S.FavouriteIcon}{
            & svg {
                    & path {
                        fill: #FFB203 !important;
                    }
                }
            }
        `;
    }};
`;

S.HeaderText = styled.div`
    &:first-letter{
       text-transform: uppercase;
    }
`;

S.Body = styled.div`
    
    position: relative;

    &.slide-transition-enter {
        max-height: 0px;
        overflow: hidden;
        transition: max-height ${duration}ms;
    }
        
    &.slide-transition-enter-active {
        max-height: 500px;
    }

    &.slide-transition-exit {
        max-height: 500px;
        overflow: hidden;
        transition: max-height ${duration}ms;
    }
        
    &.slide-transition-exit-active {
        max-height: 0px;
    }   
`;

S.ShowAllIcon = styled.i`
    width: 19px;
    height: 19px;
`;

S.EventsCount = styled.div`
    font-size: 12px;
    width: 10px;
    text-align: right;
    color: ${({ theme: { $favouriteLeaguesEventCountColor } }) => $favouriteLeaguesEventCountColor};
`;

S.ShowAll = styled.div`
    display: flex;
    height: 48px;
    padding: 0 11px 0 17px;
    align-items: center;
    cursor: pointer;
    background-color: ${({theme:{$favouriteLeaguesShowAllBgColor}})=> $favouriteLeaguesShowAllBgColor};
    color: ${({theme:{$favouriteLeaguesShowAllColor}})=> $favouriteLeaguesShowAllColor};
    margin-bottom: 1px;

    & ${S.ShowAllIcon}{
        margin-right: 20px;
    }

    & ${S.EventsCount}{
        margin-right: 19px;
        margin-left: auto;
    }

    & ${S.Arrow}{
        transform: rotate(0deg);
        &:before {
            background-color: ${({ theme: { $favouriteLeaguesArrowColor } }) => $favouriteLeaguesArrowColor};
        }
    }
`;

S.ShowAllText = styled.div`
    color: ${({theme:{$favouriteLeaguesShowAllColor}})=> $favouriteLeaguesShowAllColor};
`;

S.List = styled(List)`
`;

S.ListItem = styled.li`
    padding: 0 11px 0 17px;
    display: flex;
    align-items: center;
    color: ${({theme:{$favouriteLeaguesItemColor}})=> $favouriteLeaguesItemColor};
    height: 48px;
    background-color: ${({theme:{$favouriteLeaguesItemBg}})=> $favouriteLeaguesItemBg};
    cursor: pointer;
    margin-bottom: 1px;
    &:last-of-type{
    margin-bottom: 0;
    }
    &:hover{
        background-color: ${({theme:{$favouriteLeaguesItemBgHover}})=> $favouriteLeaguesItemBgHover};
    }

    ${({isSelected}) => {
    return (isSelected == true) && `
            color: #f05a22;
        `;
    }};

    & ${S.FavouriteIcon}{
        margin-left: auto;
        margin-right: 20px;
        width: 9px;
        height: 9px;
    }

    & ${S.EventsCount}{
    margin-right: 19px;
    }

    & ${S.Arrow}{
        transform: rotate(0deg);
        &:before {
            background-color: ${({ theme: { $favouriteLeaguesArrowColor } }) => $favouriteLeaguesArrowColor};
        }
        
    }

`;

S.LeagueName = styled.div``;

S.Cover = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.5);
    justify-content: center;
    align-items: center;
    display: flex;

    & ${StyledLoader.LoaderWrapper}{

    }
`;

export default S;