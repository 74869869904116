import styled from 'styled-components';
import {List} from 'global/styles/styles.js';

const S = {};

const liveCalendarItemDateTime = ({theme:{$liveCalendarItemDateTime}}) => $liveCalendarItemDateTime;
const liveCalendarItemColor = ({theme:{$liveCalendarItemColor}}) => $liveCalendarItemColor;
const liveCalendarItemBgColor = ({theme:{$liveCalendarItemBgColor}}) => $liveCalendarItemBgColor;
const liveCalendarItemHoverBgColor = ({theme:{$liveCalendarItemHoverBgColor}}) => $liveCalendarItemHoverBgColor;
const liveCalendarItemArrowBg = ({theme:{$liveCalendarItemArrowBg}}) => $liveCalendarItemArrowBg;
const liveCalendarDateFilterBgColor = ({theme:{$liveCalendarDateFilterBgColor}}) => $liveCalendarDateFilterBgColor;
const liveCalendarDateFilterItemBgColor = ({theme:{$liveCalendarDateFilterItemBgColor}}) => $liveCalendarDateFilterItemBgColor;
const liveCalendarDateFilterItemColor = ({theme:{$liveCalendarDateFilterItemColor}}) => $liveCalendarDateFilterItemColor;
const liveCalendarSportsFilterColor = ({theme:{$liveCalendarSportsFilterColor}}) => $liveCalendarSportsFilterColor;
const groupFilterBorderColor = ({theme:{$groupFilterBorderColor}}) => $groupFilterBorderColor;
const liveCalendarSliderButton = ({theme:{$liveCalendarSliderButton}}) => $liveCalendarSliderButton;
const liveCalendarFlashscoreIcon = ({theme:{$liveCalendarFlashscoreIcon}}) => $liveCalendarFlashscoreIcon;





S.LiveCalendar = styled.div`
`;

S.LiveCalendarFilters = styled.div``;

S.SelectedSport = styled.div``;

S.SportsFilter = styled.div`
  display:flex;
  width: 100%;
  height: 4rem;
  background-color: transparent;
  border-radius: 3px;
  margin-bottom: .67rem;
    & .swiper-arrow {
      &.swiper-prev,
      &.swiper-next {
        width: 3.4rem;
        border: none;
        background-color: ${liveCalendarSliderButton};
        & svg{
        & path{
            stroke: ${liveCalendarItemArrowBg};
        }
    }
      }
      &.swiper-next {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        & svg{
          transform: rotate(90deg);
        }
      }
      &.swiper-prev {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        & svg{
          transform: rotate(-90deg);
        }
      }
    }
`;

S.SportsFilterOption =  styled.button`       
        height: 4rem; 
        padding: 0 1.5rem;
        border:none;
        border-right:2px solid ${groupFilterBorderColor};
        background-color: ${liveCalendarDateFilterBgColor};
        font-size: 1.083rem;
        color:${liveCalendarSportsFilterColor};
        cursor: pointer;
        &:hover{
            background-color: #44474e;
            color:#fff;
        }
        ${({isActive}) => {
        if(isActive == true){
            const css = `
                    background-color: #44474e;
                    color: #fff;
                `;
            return css;
        }
   }}
`;
S.SportsFilterOptionInner = styled.div`
        display: flex;
        align-items: center;
        justify-content: center;

`

S.SportsFilterOptionIcon =  styled.svg`
    width: 1.3rem;
    height: 1.3rem;
    margin-right: 1rem;
    flex-shrink: 0;
    fill: #fff;
`;

S.SportsFilterOptionLabel =  styled.div`
    color:inherit;
    line-height: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  
`;

S.FiltersWrapper = styled.div`
  display:flex;
  gap:0.75rem;
  margin-bottom: .67rem;
  width:100%;
  max-width:100%;
`
S.ScrollWrapper = styled.div`
    flex-grow:1;
    min-width:0;
    border-radius: 3px;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      height: 2px;
}
`
S.DatesFilter = styled(List)`
    
    display: flex;
    justify-content: space-between;
    align-items:center;
    width:max-content;
    min-width: 100%;
    height:4rem;
    padding: 1.25rem;
    border-radius: 3px;
    background-color: ${liveCalendarDateFilterBgColor};
`;

S.DatesFilterItem = styled.li`
    display:flex;
    flex: 1 1 0%;
    gap:0.4rem;
    justify-content: center;
    align-items: center;
    height: 2.67rem;
    width: auto;
    background-color: ${liveCalendarDateFilterItemBgColor};
    margin-right: 0.67rem;
    padding: 0 0.4rem;
    box-shadow: none;
    transform: none;
    border-radius: 3px;
    color: ${liveCalendarDateFilterItemColor};
    font-size: 1.083rem;
    text-transform: uppercase;
    cursor: pointer;
    &:last-of-type{
      margin-right: 0;
    }
    &:hover{
      background-color: #f05a22;
      color: #fff;
    }

   ${({isActive}) => {
        if(isActive == true){
            const css = `
                    background-color: #f05a22;
                    color: #fff;
                `;
            return css;
        }
   }}
`;
S.DatesFilterItemText = styled.div`
`
S.LivestreamFilterButton = styled.button`
   display:flex;
   justify-content:center;
   align-items:center;
   height:4rem;
   width:4rem;
   border:none;
   border-radius:3px;
   background-color: ${liveCalendarDateFilterBgColor};
   svg.icon-stream{
    stroke:${liveCalendarDateFilterItemColor};
  }

 ${({isActive}) => {
      if(isActive == true){
          const css = `
                  background-color: #f05a22;
                  color: #fff;
                  svg.icon-stream{
                    stroke:#fff;
              `;
          return css;
      }
 }}

`
S.LiveCalendarList = styled(List)``;

S.LiveCalendarEmptyList = styled.div`
display:flex;
align-items: center;
justify-content: center;
height:5.3rem;
padding: 1rem;
border-radius: 3px;
background-color:  ${liveCalendarItemBgColor};
.message{
    padding: 0.7rem 0.5rem 0.5rem 0.5rem;
    color: ${liveCalendarItemColor};

}
.icon{
    svg {
        height: 1.2rem;
        width: 1.2rem;
        path{
          fill:${liveCalendarItemColor};
      }
    }
}
`;

S.LiveCalendarItem = styled.li`
    display: flex;
    height: 5.33rem;
    margin-bottom: .67rem;
    padding: 0 1.25rem;
    border-radius: 3px;
    background-color:  ${liveCalendarItemBgColor};
    cursor: pointer;
    
    &:hover{
     background-color: ${liveCalendarItemHoverBgColor}
    }
`;

S.LiveCalendarItemIcon = styled.svg`
    align-self: center;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1.0833333333rem;
`;

S.LiveCalendarItemDateTime = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 1.083rem;
    color: ${liveCalendarItemDateTime};
`;

S.Date = styled.div``;

S.Time = styled.div``;

S.LiveCalendarItemInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

S.LiveCalendarItemEventName = styled.div`
    color: ${liveCalendarItemColor};
`;

S.LiveCalendarItemCategories = styled.div`
 color: ${liveCalendarItemColor};
`;
S.LiveCalendarIcons = styled.div`
display:flex;
align-items:center;
gap:1.5rem;
margin-left:auto;
`
S.LiveCalendarFlashscoreLink = styled.div`
width:2.1rem;
height:1.5rem;
background-image: url(${liveCalendarFlashscoreIcon});
`

S.LiveCalendarStreamIcon = styled.div`
  svg.icon-stream{
    stroke:${liveCalendarItemArrowBg};
  }
  `
S.LiveCalendarItemArrow = styled.div`
    transform: rotate(90deg);
    & svg{
        & path{
            stroke: ${liveCalendarItemArrowBg};
        }
    }
`;


export default S;