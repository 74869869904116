import React, {memo, useContext, useCallback, useState, useEffect} from 'react';
import _map from 'lodash/map';
import S from 'StyledCategoriesTimeFilter.js';
import classNames from 'classnames';
import {ReactReduxContext} from 'react-redux';
import {changeTimeFilter} from 'categoriesActions.js';
import TimeIcon from 'time_icon.svg';
import TimeIconGrey from 'time_icon_grey.svg';

const CategoriesTimeFilter = (props) => {

    const [currentTime, setCurrentTime] = useState(null);
    const timesMap = [1, 3, 6, 12, 24, 48];
    const {store} = useContext(ReactReduxContext);
    const {Categories: {time}, Theme:{currentTheme}} = store.getState();
    const timeIconSVG =  currentTheme == 'light' ? TimeIconGrey : TimeIcon;

    // did mount
    useEffect(() => {
        if (location.search && /\?filterEventsByTime=\d+/.test(location.search)) {
            const time = location.search.replace(/\?filterEventsByTime=(\d+).*/, '$1');
            setCurrentTime(time);
        }
    }, []);

    const onChangeTimeFilter = (hour) => {
        const newTime = (currentTime != hour) ? hour : null;
        setCurrentTime(newTime);
        store.dispatch(changeTimeFilter(hour));
    };

    return (
        <S.CategoriesTimeFilterList className="categories-time-filter-list">

            <S.TimeIcon className={`time-icon ${currentTheme}`} dangerouslySetInnerHTML={{__html: timeIconSVG}}/>

            {_map(timesMap, (hour) => {
                const itemClass = classNames({'is-active': hour == currentTime});
                return (
                    <S.CategoriesTimeFilterItem key={hour}
                                                className={itemClass}
                                                onClick={onChangeTimeFilter.bind(null, hour)}>
                        {`${hour}H`}
                    </S.CategoriesTimeFilterItem>
                )
            })}
        </S.CategoriesTimeFilterList>
    );
};


export default memo(CategoriesTimeFilter);
