import styled from 'styled-components';
import {List} from 'global/styles/styles.js';
const S = {};

S.TopMenu = styled.div`
    z-index: 100;
    height: auto;
    background: ${({theme:{$topMenuBackgroundColor}})=> $topMenuBackgroundColor};
    position: relative;
    min-height: 1px;
    padding-right: 0;
    padding-left: 0;
    float: left;
    width: 100%;
`;

S.MainRow = styled.div`
    display: flex;
    height: 2.67rem;
    justify-content: flex-end;
    background-color: ${({theme:{$topMenuTopBarColor}})=> $topMenuTopBarColor};
    -webkit-box-shadow: 0 1px 15px 0 rgba(0,0,0,.37);
    -moz-box-shadow: 0 1px 15px 0 rgba(0,0,0,.37);
    box-shadow: 0 1px 15px 0 rgba(0,0,0,.37);
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 0 2rem 0 .70834rem;
`;

S.MenuRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 2rem 0 .70834rem;
    background-color: ${({theme:{$topMenuBackgroundColor}})=> $topMenuBackgroundColor};
    height: 7.17rem;
`;

S.Link = styled.a`
    display: flex;
    margin-right: .83rem;
    width: 15.25rem;
    align-items: center;
`;

S.Logo = styled.img`
    width: 100%;
    max-width: 15.25rem;
    height: auto;
    position: relative;
`;

export default S;