import React, {useEffect} from 'react';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _get from 'lodash/get';
import classNames from 'classnames';
import S from 'StyledTopMenuList.js';
import topMenuItems from 'topMenuItems.enum.js';
import subMenuItems from 'subMenuItems.enum.js';
import {translation, redirect} from 'helpers/utilsHelper';

const DISABLED_TOP_MENU_ITEM = "LIVES";

const TopMenuList = ({activeItem, submenuItem, changeActiveItem, changeSubmenuActiveItem}) => {

    useEffect(() => {
        const submenuLinks = _get(subMenuItems, [activeItem], {});
        const firstSubmenuLinksKey = _get(Object.keys(submenuLinks), [0]);
        if (firstSubmenuLinksKey && !(submenuItem in submenuLinks)) {
            changeSubmenuActiveItem(firstSubmenuLinksKey);
        }
    }, []);

    const onMenuLinkClick = (e, href, topItemName) => {
        e.preventDefault();
        redirect(href);
    };

    const onSubmenuLinkClick = (e, url, topItemName, submenuItemName) => {
        e.preventDefault();
        e.stopPropagation();
        redirect(url);
    };

    return (
      (app.config.hideMigrationComponents ?
        <S.MenuListItem>
          <S.MenuLink className="top-menu-link" href={"https://nairabet.com/"}>
            {translation('topMenu_newSite')}
          </S.MenuLink>
        </S.MenuListItem>

        :

        <S.MenuList>
            {_map(topMenuItems, ({ translationKey, href, openInNewTab, submenu, dataTest = '', ...rest}, topItemName) => {
                if (app.config.inPlayDisabled && topItemName === DISABLED_TOP_MENU_ITEM) return null;
                const url = typeof href === 'function' ? href() : href;
                const itemClass = classNames({'is-active': (activeItem == topItemName), 'top-menu-item': true});
                const hasSubmenu = (submenu && _size(submenu) > 0);
                return (
                    <S.MenuListItem key={topItemName} className={itemClass}>
                        {!openInNewTab
                            ?
                            <S.MenuLink {...rest}
                                        className="top-menu-link"
                                        onClick={(e) => {
                                            onMenuLinkClick(e, url, topItemName);
                                        }}
                                        data-test={dataTest}>

                                {translation(translationKey)}

                                {hasSubmenu &&
                                (
                                    <S.SubMenuList className="submenu-list">
                                        {_map(submenu, ({ translationKey, href, dataTest = ''}, submenuItemName) => {
                                            const url = typeof href === 'function' ? href() : href;
                                            return (
                                                <S.SubMenuListItem key={submenuItemName} isActive={submenuItem == submenuItemName} className="submenu-list-item">

                                                    <S.SubMenuLink {...rest}
                                                                   className="sub-menu-link"
                                                                   onClick={(e) => {
                                                                       onSubmenuLinkClick(e, url, topItemName, submenuItemName);
                                                                   }}
                                                                   data-test={dataTest}>

                                                        <span>{translation(translationKey)}</span>

                                                    </S.SubMenuLink>

                                                </S.SubMenuListItem>
                                            )
                                        })}
                                    </S.SubMenuList>
                                )
                                }
                            </S.MenuLink>
                            :
                            <S.MenuLink {...rest} className="top-menu-link" target="_blank" href={url}>
                                {translation(translationKey)}
                            </S.MenuLink>
                        }
                    </S.MenuListItem>
                )
            })}
        </S.MenuList>
        )
    );
};

export default TopMenuList;

