import styled from 'styled-components';
import {List, Button} from 'global/styles/styles.js';
const S = {};

S.UnloggedPanel = styled.div`
    display: flex;
`;
S.Button = styled(Button)`
    transition: .2s all linear;
    color: ${({theme:{$topMenuUnloggedBtnColor}})=> $topMenuUnloggedBtnColor};
    font-weight: 700;
    text-transform: lowercase;
    font-size: 1.5rem;
    font-family: Arial,sans-serif;
    line-height: 3.33rem;
    border-radius: .42rem;
    padding: 0 1.25rem;
    border: none;
    letter-spacing: -.17rem;
    background: none;
`;

S.ButtonsWrapper = styled.div`
    display: flex;
`;


export default S;