import React from 'react';
import S from 'StyledBetHistoryItemDetails.js';
import {CSSTransition} from 'react-transition-group';
import {translation, decimal} from 'helpers/utilsHelper.js'
import {formatDate} from 'helpers/datesHelper.js';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

const BetHistoryItemDetails = ({isVisible, transaction}) => {

    const getTransactionDetailsStatus = (statusCode) => {
        let resultText = 'details_placed';

        switch (statusCode) {
            case 0 :
                resultText = 'details_placed';
                break;
            case 1 :
                resultText = 'details_placed';
                break;
            case 2 :
                resultText = 'details_won';
                break;
            case 3 :
                resultText = 'details_lost';
                break;
            case 4 :
                resultText = 'details_cancel';
                break;
        }

        return resultText;
    };

    return (
        <CSSTransition in={isVisible}
                       className="slide-transition"
                       classNames="slide-transition"
                       unmountOnExit
                       timeout={300}>

            <S.BetHistoryDetailsWrapper className="bet-history-details-wrapper">

            {_map(_get(transaction, 'blocks'), (blocks) => {

                return (!_isEmpty(blocks) && (

                    <S.BetHistoryItemDetails id={`bet-history-item-details-${transaction.transactionId}`} className="bet-history-item-details" key={`bet_history_details_${transaction.transactionId}`}>

                        <S.BetHistoryDetailsHeader className="bet-history-item-details-header">
                            <div className="details-time">{translation('account_betHistoryItemDetails_time')}</div>
                            <div className="details-bet">{translation('account_betHistoryItemDetails_bet')}</div>
                            <div className="details-result">{translation('account_betHistoryItemDetails_result')}</div>
                            <div className="details-odds">{translation('account_betHistoryItemDetails_odds')}</div>
                            <div className="details-score">{translation('account_betHistoryItemDetails_score')}</div>
                            <div className="details-status">{translation('account_betHistoryItemDetails_status')}</div>
                        </S.BetHistoryDetailsHeader>

                        {_map(blocks, ({eventId, eventStart, eventName, gameName, outcomeName, outcomeResult, odds, score, statusCode}) => {

                            const statusResult = getTransactionDetailsStatus(statusCode);

                            return (
                                <S.BetHistoryDetailsBody key={`bet_history_details_body_${eventId}`}>
                                    <div className="details-time">
                                        <span>{formatDate(eventStart, 'dd-MM-yyyy')}&nbsp;{formatDate(eventStart, 'HH:mm:ss')}</span>
                                    </div>
                                    <div className="details-bet">
                                    <span>
                                        <span className="event-name">{eventName}</span>
                                        <span className="game-name">{gameName}</span>
                                        <span className="outcome-name">{outcomeName}</span>
                                    </span>
                                    </div>
                                    <div className="details-result">
                                        <span>{!_isEmpty(outcomeResult) ? outcomeResult : ''}</span>
                                    </div>
                                    <div className="details-odds">{decimal(odds)}</div>
                                    <div className="details-score">{score}</div>
                                    <div className={`details-status ${statusResult}`}>{translation(statusResult)}</div>
                                </S.BetHistoryDetailsBody>
                            )
                        })}

                    </S.BetHistoryItemDetails>
                ));

            })}

            </S.BetHistoryDetailsWrapper>
        </CSSTransition>
    );
};

export default BetHistoryItemDetails;
