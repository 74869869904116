const theme = {
    // Smartphones Mobiles (Portrait)
    mqMobile: `@media (min-width: 320px) and (max-width: 480px)`,
    // Smartphones Mobiles (Landscape)
    mqMobileHorz: `@media (min-width: 481px) and (max-width: 767px)`,
    // Tablets, Ipads (Portrait)
    mqTablets: `@media all (min-width: 768px) and (max-width: 1024px)`,
    // Tablets, Ipads (Landscape)
    mqTabletsHorz: `@media all (min-width: 768px) and (max-width: 1024px) and (orientation: landscape)`,
    // Desktops
    mqSmallDesktops: `@media (min-width: 1025px)`,
    mqMediumDesktops: `@media (min-width: 1281px)`,
    mqLargeDesktops: `@media (min-width: 1600px)`,
    mqXLargeDesktops: `@media (min-width: 1920px)`,

    // Main colors
    $color_1: `#222428`, // left column active bg
    $color_2: `#2D9EF5`, // active elements, checkboxes, buttons
    $color_3: `#3EE2EF`,
    $color_4: `#2999BF`,
    $color_5: `#424C71`, // top header
    $color_6: `#70BF44`, // active buttons
    $color_7: `#51A517`,
    $color_8: "#303339", // left column lighter bg
    $color_9: `#EFEFEF`, // table header bg
    $color_10: `#3F3F3F`, // dark grey
    $color_11: `#E8E8E8`, // left column darker bg
    $color_12: `#3C3C3C`, // carousel bg
    $color_13: `#F97447`,
    $color_14: `#F9C023`,
    $color_15: `#F82B00`,
    $color_16: `#2B2E34`,

    // Additional colors
    $color_17: `#F75703`,
    $color_18: `#BC4405`,
    $color_19: `#F8A072`,
    $color_20: `#404040`,
    $color_21: `#222222`,
    $color_22: `#383838`,
    $color_23: `#333333`,
    $color_24: `#191919`,
    $color_25: `#ffffff`,
    $color_26: `#A1A1A1`,
    $color_27: `#000000`,
    $color_28: `#00A651`,
    $color_29: `#078143`,
    $color_30: `#004D26`,
    $color_31: `#007639`,
    $color_32: `#85C9A6`,
    $color_33: `#CACACA`,
    $color_34: `#242c2f`,
    $color_35: `#282828`,
    $color_36: `#A3A3A3`,
    $color_37: `#F3F3F3`,
    $color_38: `#EAEAEA`,
    $color_39: `#a8a8a8`,
    $color_40: `#BCBCBC`,
    $color_41: `#787878`,
    $color_42: `#2d9ef5`,
    $color_43: `#2d9ef5`,
    $color_44: `#F05A22`,
    $color_45: `#27292E`,
    $color_46: `#44474E`,
    $color_47: `transparent`,
    $color_48: `#3D424B`,
    $color_49: `#E5E5E5`,
    $color_50: `#303237`,

    $color_51: `#CCC`,
};
// default/general
theme['$basicIconColor'] = theme['$color_40'];
theme['$iconColorOption1'] = theme['$color_40'];

// top menu
theme['$topMenuBackgroundColor'] = theme['$color_45'];
theme['$topMenuTopBarColor'] = theme['$color_5'];
theme['$topMenuListItemActiveColor'] = theme['$color_2'];
theme['$topMenuLinkColor'] = theme['$color_39'];
theme['$topMenuActiveLinkColor'] = theme['$color_10'];

theme['$topMenuUnloggedBtnColor'] = theme['$color_25'];
theme['$topMenuLoggedOutBtnColor'] = theme['$color_25'];
theme['$topMenuLoggedOutBtnBgColor'] = theme['$color_5'];
theme['$topMenuLoggedUserLoginColor'] = theme['$color_25'];

// categories

theme['$categoriesHeaderLeftSidePanelColor'] = theme['$color_44'];
theme['$categoriesHeaderBgColor'] = theme['$color_46'];
theme['$categoriesHeaderItemColor'] = theme['$color_49'];
theme['$categoriesHeaderIconColor'] = theme['$color_40'];

theme['$categoriesDropdownBgColor'] = theme['$color_47'];
theme['$categoriesDropdownItemBgColor'] = theme['$color_8'];
theme['$categoriesDropdownShowAllBgColor'] = theme['$color_8'];
theme['$categoriesDropdownItemColor'] = theme['$color_40'];
theme['$categoriesDropdownItemActiveColor'] = theme['$color_2'];
theme['$categoriesDropdownItemBgColorHover'] = theme['$color_1'];
theme['$categoriesDropdownItemBorderBottom'] = theme['$color_16'];
theme['$categoriesActiveEventsCountBgColor'] = theme['$color_2'];
theme['$categoriesActiveEventsCountColor'] = theme['$color_25'];
theme['$categoriesDropDownItemInnerActiveBorder'] = theme['$color_44'];
theme['$categoriesDropdownArrowColor'] = theme['$color_40'];
theme['$categoriesDropdownActiveArrowColor'] = theme['$color_40'];

theme['$categoriesDropdownItemLvl2BgColor'] = theme['$color_16'];
theme['$categoriesDropdownItemLvl2ActiveBgColor'] = theme['$color_16'];
theme['$categoriesDropdownItemLvl2ActiveTitleColor'] = theme['$color_42'];
theme['$categoriesDropdownItemLvl2ActiveArrowColor'] = theme['$color_44'];
theme['$categoriesDropdownItemLvl2ActiveEventCount'] = theme['$color_44'];
theme['$categoriesDropdownItemLvl3BgColor'] = theme['$color_45'];
theme['$categoriesEventsCount'] = theme['$color_40'];
theme['$categoriesLvl3CheckboxBgColor'] = 'transparent';
theme['$categoriesLvl3CheckboxActiveBgColor'] = theme['$color_44'];
theme['$categoriesLvl3CheckboxBorderColor'] = theme['$color_41'];
theme['$categoriesLvl3CheckboxActiveBorderColor'] = theme['$color_44'];

// login form
theme['$loginFormSubmitBtnColor'] = theme['$color_25'];
theme['$loginFormSubmitBtnBgColor'] = theme['$color_2'];

// register form
theme['$registerFormSubmitBtnColor'] = theme['$color_25'];
theme['$registerFormSubmitBtnBgColor'] = theme['$color_2'];

// account
theme['$accountTopLeftSideBgColor'] = theme['$color_5'];

// favourites
theme['$arrowFavouriteLeagues'] = theme['$color_40'];
theme['$arrowFavouriteLeaguesBgColor'] = theme['$color_47'];
theme['$favouriteLeaguesHeaderBgColor'] = theme['$color_8'];
theme['$favouriteLeaguesHeaderLeftSidePanelColor'] = theme['$color_44'];
theme['$favouriteLeaguesHeaderColor'] = theme['$color_40'];
theme['$favouriteLeaguesHeaderArrowColor'] = theme['$color_40'];
theme['$favouriteLeaguesHeaderActiveBgColor'] = theme['$color_46'];
theme['$favouriteLeaguesHeaderText'] = theme['$color_49'];
theme['$favouriteLeaguesShowAllBgColor'] = theme['$color_8'];
theme['$favouriteLeaguesShowAllColor'] = theme['$color_40'];
theme['$favouriteLeaguesItemBg'] = theme['$color_8'];
theme['$favouriteLeaguesItemBgHover'] = theme['$color_48'];
theme['$favouriteLeaguesItemColor'] = theme['$color_40'];
theme['$favouriteLeaguesEventCountColor'] = theme['$color_40'];
theme['$favouriteLeaguesArrowColor'] = theme['$color_40'];

// categories time filter
theme['$categoriesTimeFilterBgColor'] = theme['$color_8'];
theme['$categoriesTimeFilterItemColor'] = theme['$color_40'];
theme['$categoriesTimeFilterItemBorderColor'] = theme['$color_46'];
theme['$categoriesTimeFilterItemActiveBorderColor'] = theme['$color_44'];
theme['$categoriesTimeFilterItemActiveBgColor'] =  theme['$color_44'];
theme['$categoriesTimeFilterItemActiveColor'] =  theme['$color_25'];
theme['$categoriesTimeFilterBorderBottom'] = theme['$color_1'];


// live calendar

theme['$liveCalendarSportsFilterBgColor'] = theme['$color_46'];
theme['$liveCalendarSportsFilterOptionBgColor'] = theme['$color_45'];
theme['$liveCalendarSportsFilterColor'] = theme['$color_25'];

theme['$liveCalendarHeaderText'] = theme['$color_49'];

theme['$liveCalendarDateFilterBgColor'] = theme['$color_50'];
theme['$liveCalendarDateFilterItemBgColor'] = theme['$color_45'];
theme['$liveCalendarDateFilterItemColor'] = theme['$color_49'];

theme['$liveCalendarItemColor'] = theme['$color_49'];
theme['$liveCalendarItemBgColor'] = theme['$color_8'];
theme['$liveCalendarItemHoverBgColor'] = theme['$color_46'];
theme['$liveCalendarItemDateTime'] = theme['$color_51'];
theme['$liveCalendarItemArrowBg'] = theme['$color_49'];
theme['$liveCalendarSliderButton'] = theme['$color_12'];
theme['$liveCalendarFlashscoreIcon'] = '/images/svg/flashscore-dark-theme.svg';


// game type group filter with categories

theme['$groupFilterMainContainerBgColor'] = theme['$color_8'];
theme['$groupFilterHeaderBgColor'] = theme['$color_46'];
theme['$groupFilterHeaderColor'] = theme['$color_25'];
theme['$groupFilterHeaderLeftSideBgColor'] = theme['$color_44'];
theme['$groupFilterBtnFilterTextColor'] = theme['$color_49'];
theme['$groupFilterBtnFilterTextActiveColor'] = theme['$color_44'];
theme['$groupFilterCategoryNameColor'] = theme['$color_25'];
theme['$groupFilterCountTextColor'] = theme['$color_40'];
theme['$groupFilterBorderColor'] = theme['$color_1'];
theme['$groupFilterButtonBgColor'] = theme['$color_46'];
theme['$groupFilterButtonTextColor'] = theme['$color_25'];
theme['$groupFilterButtonActiveBgColor'] = theme['$color_44'];
theme['$groupFilterButtonTextActiveColor'] = theme['$color_25'];
theme['$groupFilterScrollThumbColor'] = theme['$color_44'];
theme['$groupFilterButtonScrollLeft'] = '/images/svg/market-filter-btn-left-black.svg';
theme['$groupFilterButtonScrollRight'] = '/images/svg/market-filter-btn-right-black.svg';

export default theme;