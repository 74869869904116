import styled from 'styled-components';
import {Form, Input, Button} from 'global/styles/styles.js';

const S = {};

S.ComponentWrapper = styled.div`
    font-size: 12px;
`;

S.PanelHeader = styled.div`
    background-color: ${({ theme: { $groupFilterHeaderBgColor } }) => $groupFilterHeaderBgColor};
    color: ${({ theme: { $groupFilterHeaderColor } }) => $groupFilterHeaderColor};
    width: 100%;
    height: 4rem;
    line-height: 4rem;
    border-radius: 3px;
    text-transform: uppercase;
    position: relative;
`;

S.PanelHeaderLeftSideColor = styled.div`
    background-color: ${({ theme: { $groupFilterHeaderLeftSideBgColor } }) => $groupFilterHeaderLeftSideBgColor};
    height: 4rem;
    width: 3px;
    position: absolute;
    border-top-left-radius: 3px;
`;

S.PanelHeaderText = styled.div`
    padding-left: 1rem;
    display: flex;
    align-items: center;
    font-size: 1.1666666667rem;
`;

S.FilterContainer = styled.div`
    width: 100%;
    display: flex;
    position: relative;
`;

S.FilterScrollBtnContainer = styled.div`
    background-color: ${({ theme: { $groupFilterMainContainerBgColor } }) => $groupFilterMainContainerBgColor};
    width: 11%;
    height: 3.5rem;
`

S.FilterScrollBtn = styled.div`
    position: absolute;
    top: 1rem;
    width: 21px;
    height: 21px;
    cursor: pointer;

    &.left {
        background-image: url(${({ theme: { $groupFilterButtonScrollLeft } }) => $groupFilterButtonScrollLeft});
        left: 1.5rem;
    }

    &.right {
        background-image: url(${({ theme: { $groupFilterButtonScrollRight } }) => $groupFilterButtonScrollRight});
        right: 1.5rem;
    }   
`;

S.FilterBtnsList = styled.div`
    background-color: ${({ theme: { $groupFilterMainContainerBgColor } }) => $groupFilterMainContainerBgColor};
    width: 78%;
    overflow: hidden;
    height: 3.5rem;
    line-height: 3.5rem;
    margin: auto;
`;

S.FilterBtnsRelativeContainer = styled.div`
    position: relative;
    display: flex;
    height: 100%;
`;

S.FilterBtnsContainer = styled.div`
    justify-content: flex-start;
    display: flex;
    align-items: center;
    position: absolute;
    margin-left: 0px;
`;

S.FilterBtn = styled.div`
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 13px;
    cursor: pointer;
    height: 3.5rem;
    white-space: nowrap;
    color: ${({ theme: { $groupFilterBtnFilterTextColor } }) => $groupFilterBtnFilterTextColor};
    &.active {
        color: ${({ theme: { $groupFilterBtnFilterTextActiveColor } }) => $groupFilterBtnFilterTextActiveColor};
        border-bottom: 2px solid ${({ theme: { $groupFilterBtnFilterTextActiveColor } }) => $groupFilterBtnFilterTextActiveColor};
    }
`;

S.FilterCategoriesContainer = styled.div`
    width: 100%;
    max-height: 286px;
    &.ps{
        & .ps__scrollbar-y-rail{
           & .ps__scrollbar-y{
               background-color: #F05A22!important;
           }
       }
     }
`;

S.FilterCategoryRow = styled.div`
    margin-top: 1px; 
    height: 56px;
    display: flex;
    width: 100%;
    padding: 1.6rem;
    justify-content: space-between;
    background-color: ${({ theme: { $groupFilterMainContainerBgColor } }) => $groupFilterMainContainerBgColor};
    &:last-of-type {
        margin-bottom: 1px;
    }
`;

S.FilterCategoryNameContainer = styled.div`
   display: flex;
   width: 80%;
`;

S.FilterCategoryFlag = styled.img`
    width: 16px;
    height: 16px;
    margin-right: 1rem;
`;

S.FilterCategoryName = styled.div`
    font-size: 13px;
    color: ${({ theme: { $groupFilterCategoryNameColor } }) => $groupFilterCategoryNameColor};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

S.FilterCheckboxContainer = styled.div`
    display: flex;
`;

S.FilterEventCount = styled.div`
    font-size: 13px;
    color: ${({ theme: { $groupFilterCountTextColor } }) => $groupFilterCountTextColor};
`;

S.FakeChbox = styled.div`
    width: 1.16rem;
    height: 1.16rem;
    border: .17rem solid ${({theme:{$categoriesLvl3CheckboxBorderColor}}) => $categoriesLvl3CheckboxBorderColor};
    background-color: ${({theme:{$categoriesLvl3CheckboxBgColor}}) => $categoriesLvl3CheckboxBgColor};
    border-radius: 2px;
    margin-right: 2px;
    margin-left: 12px;
    &.is-active {
        border-color: ${({theme:{$categoriesLvl3CheckboxActiveBorderColor}}) => $categoriesLvl3CheckboxActiveBorderColor};
        background-color:  ${({theme:{$categoriesLvl3CheckboxActiveBgColor}}) => $categoriesLvl3CheckboxActiveBgColor};
        &:after {
            content: "\f3fd";
            display: block;
            margin-left: 2px;
            color: #000;
            font-family: Ionicons;
            font-size: 15px;
            margin-top: -.4rem!important;
            color: #fff;
        }
}
`;

S.FooterPanel = styled.div`
    width: 100%;
    background-color: ${({ theme: { $groupFilterMainContainerBgColor } }) => $groupFilterMainContainerBgColor};
    text-align: left;
    height: 56px;
`;

S.ClearSelectionButton = styled(Button)`
    border: 0px;
    background-color: transparent;
    height: 3rem;
    width: 10rem;
    float: right;
    margin-right: 1.5rem;
    margin-top: 0.8rem;
    color: ${({theme:{$groupFilterButtonActiveBgColor}}) => $groupFilterButtonActiveBgColor};
    background-image: url(/images/svg/close_btn_orange.svg);
    background-repeat: no-repeat;
    background-size: 10px 10px;
    background-position: 6px 13px;
    &.hidden {
        display: none;
    }
`;

S.ShowEventsButton = styled(Button)`
    border-radius: 3px;
    height: 3rem;
    width: 10rem;
    float: right;
    margin-right: 1.5rem;
    margin-top: 0.8rem;
    background-color: ${({ theme: { $groupFilterButtonBgColor } }) => $groupFilterButtonBgColor};
    color: ${({ theme: { $groupFilterButtonTextColor } }) => $groupFilterButtonTextColor};
    cursor: default;
    &.active {
        background-color: ${({ theme: { $groupFilterButtonActiveBgColor } }) => $groupFilterButtonActiveBgColor};
        color: ${({ theme: { $groupFilterButtonTextActiveColor } }) => $groupFilterButtonTextActiveColor};
        cursor: pointer;
    }
`;

S.NewIcon = styled.div`
    height: 1.15rem;
    width: 2.77rem;
    margin-left: 1rem;
    background-image: url(/images/svg/new.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
`; 



export default S;