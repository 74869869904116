import {createSelector} from 'reselect';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';
import {formatDate} from 'datesHelper.js';

const liveEvents = state => state.LiveCalendar.liveEvents;
const selectedDate = state => state.LiveCalendar.selectedDate;
const selectedSport = state => state.LiveCalendar.selectedSport;
const showStreamingOnly = state => state.LiveCalendar.showStreamingOnly;

const liveCalendarSelector = createSelector(
    liveEvents,
    selectedDate,
    selectedSport,
    showStreamingOnly,
    (liveEvents, selectedStartDate, selectedSportId, showStreamingOnly) => {
        const tommorow = new Date(`${selectedStartDate}T00:00:00`);
        tommorow.setDate(tommorow.getDate() + 1);
        tommorow.setHours(0, 0, -1);

        const startDate = new Date(`${selectedStartDate}T00:00:00`).getTime();
        const endDate = tommorow.getTime();

        const todayFormatted = formatDate(new Date(), 'yyyy-MM-dd');
        if (selectedSportId || todayFormatted != selectedStartDate || showStreamingOnly) {
            const filteredResults = _filter(liveEvents, ({sportId, eventStart, extras}) => {
                const sportCondition = selectedSportId ? (selectedSportId == sportId) : true;
                const streamCondition = showStreamingOnly? (extras?.hasPerformVideo || extras?.betradarLivestreamLCO || extras?.BetbazarStreamingData) : true;
                return (sportCondition && streamCondition && (eventStart >= startDate && eventStart <= endDate));
            });
            return _sortBy(filteredResults, ['eventStart']);
        }
        const filteredResults = _filter(liveEvents, ({eventStart}) => {
            return (eventStart >= startDate && eventStart <= endDate);
        });
        return _sortBy(filteredResults, ['eventStart']);
    }
);

const allEventsSelector = createSelector(liveEvents,(liveEvents)=>{
    return liveEvents
})

export {liveCalendarSelector, allEventsSelector}