import {formatDate} from 'datesHelper.js';
import typeToReducer from 'type-to-reducer';
import update from 'immutability-helper';
import {
    FETCH_LIVE_CALENDAR_EVENTS,
    CHANGE_LIVE_CALENDAR_DATE,
    CHANGE_LIVE_CALENDAR_SPORT,
    CHANGE_LIVE_CALENDAR_STREAM_FILTER
} from 'liveCalendarActions.js';

const selectedDate = formatDate(new Date(), 'yyyy-MM-dd');
let initialState = {
    isPending: true,
    error: null,
    liveEvents: [],
    selectedSport: 0,
    selectedDate,
    showStreamingOnly:false
};

export default typeToReducer({
    [FETCH_LIVE_CALENDAR_EVENTS]: {
        SUCCESS: (state, {payload: {liveEvents}}) => {
            return {...state, liveEvents, isPending: false}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, error, isPending: false};
        }
    },
    [CHANGE_LIVE_CALENDAR_DATE]: (state, {payload: {selectedDate}}) => {
        return update(state, {selectedDate: {$set: selectedDate}});
    },
    [CHANGE_LIVE_CALENDAR_SPORT]: (state, {payload: {selectedSport}}) => {
        return update(state, {selectedSport: {$set: selectedSport}});
    },
    [CHANGE_LIVE_CALENDAR_STREAM_FILTER]: (state, {payload: {showStreamingOnly}}) => {
        return update(state, {showStreamingOnly: {$set: showStreamingOnly}});
    }
}, initialState);
