import React from 'react';
import S from 'StyledPagination.js';
import _size from 'lodash/size';

const Pagination = ({currentPage, limit, cb, data}) => {

    const setPage = (nr) => {
        if (nr < 0) {
            nr = 0;
        }
        cb(nr);
    };

    return (
        <S.Pagination className="pagination">
            <S.PaginationItem className="pagination-item">
                <S.PaginationBtn className="pagination-btn-prev ion ion-ios-arrow-left"
                                 onClick={() => setPage(currentPage - 1)}
                                 disabled={currentPage == 0}></S.PaginationBtn>
            </S.PaginationItem>
            <S.PaginationItem className="pagination-item">
                <S.PaginationBtn className="pagination-btn-next ion ion-ios-arrow-right"
                                 onClick={() => setPage(currentPage + 1)}
                                 disabled={(_size(data) < limit)}></S.PaginationBtn>
            </S.PaginationItem>
        </S.Pagination>
    );
};

export default Pagination;