import styled from 'styled-components';
import {Form, Input, Button} from 'global/styles/styles.js';
const S = {};

S.LoginForm = styled(Form)`
width: 100%;
`;

S.Row = styled.div``;

S.Label = styled.label`
    pointer-events: none;
    transition: .2s;
    position: absolute;
    top: 1.4rem;
    left: 1.8rem;
    color: #787d83!important;
    font-size: 1.33rem;
    font-weight: 400;
`;

S.InputWrapper = styled.div`
    position: relative;
    margin-bottom: 2.25rem;
`;

S.Input = styled(Input)`
    box-shadow: none;
    border-radius: .42rem;
    border: .08rem solid #e1e1e1;
    color: #000;
    font-size: 1.33rem;
    padding: 2.1rem 1.75rem;
    background-color: #f6f6f6;
    display: block;
    width: 100%;
    height: 34px;
    &:valid{
        & + ${S.Label}{
        margin-top: -1.42rem;
        font-size: 1.08rem;
        }
    }
`;

S.SubmitBtn = styled(Button)`
    border-radius: .42rem;
    background-color: ${({theme:{$loginFormSubmitBtnBgColor}}) => $loginFormSubmitBtnBgColor};
    height: 4.6rem;
    line-height: 5rem;
    font-size: 1rem;
    font-weight: 600;
    padding: 0;
    width: 100%;
    margin-bottom: 2.18rem;
    margin-top: .69rem;
    color: ${({theme:{$loginFormSubmitBtnColor}}) => $loginFormSubmitBtnColor};
    min-width: 20%;
    cursor: pointer;
`;

S.Error = styled.div`
    color: red;
    margin: 5px 0;
`;

S.ForgetPasswordLink = styled.div`
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.33rem;
    color: #bebebe;
    display: block;
    text-align: center;
    border: none;
    padding: 0;
    width: 100%;
    margin: 0 0 2rem;
    cursor: pointer;
`;

S.SubmissionError = styled.div`
    margin: 0;
    border-radius: .25rem;
    width: 100%;
    color: red;
    font-size: 1rem;
    text-align: left;
    padding: .83rem;
    background-color: #fba59f;
`;

export default S;