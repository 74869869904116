import React, {memo, useMemo} from 'react';
import _map from 'lodash/map';
import _each from 'lodash/each';
import _size from 'lodash/size';
import _reduce from 'lodash/reduce';
import _difference from 'lodash/difference';
import _sortBy from 'lodash/sortBy';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _find from 'lodash/find';
import _without from 'lodash/without';
import S from 'StyledFavouriteLeagues.js';
import Loader from 'Loader.js';
import useToggle from 'UseToggle.js';
import ShowAllIcon from 'show_all.svg';
import ShowAllIconGrey from 'show_all_grey.svg';
import FavouriteIcon from 'favourite_icon.svg';
import memoize from 'fast-memoize';
import {translation, findCategoryItemByIdLevel} from 'helpers/utilsHelper.js';
import {CSSTransition} from 'react-transition-group';

const FavouriteLeagues = (props) => {

    const {favouriteLeagues, toggleFavouriteForLeague, time, orgCategories, sportsOrder, selectedLeaguesBySport, toggleSelectedLeague, toggleSelectedLeaguesForSports, outrights, isFilteredCategoriesRequestPending, currentTheme} = props;

    const [showList, toggleListVisibility] = useToggle(true);

    const showAllIconSVG = (currentTheme == 'light') ? ShowAllIconGrey : ShowAllIcon;

    const toggleSelectedForLeague = (leagueId, sportId) => {
        toggleSelectedLeague(leagueId, sportId);
        redirectToEventList(leagueId, sportId);
    };

    const isOutright = (categoryId, categories) => {
        const league = _find(categories, {categoryId});
        return _get(league, 'treatAsSport') == -500;
    };

    const isOutrightMemoized = memoize(isOutright);

    const redirectToEventList = (leagueId, sportId) => {

        let selectedLeaguesBySportCopy = {...selectedLeaguesBySport};
        let sportsOrderCopy = [...sportsOrder];
        let outrightsCopy = [...outrights];
        let hasNewLeagues = false;
        let leaguesIdsMappedToSport = null;
        let isArray = false;

        if (leagueId && sportId) {
            leaguesIdsMappedToSport = [{sportId, leagues: [].concat(leagueId)}];
        } else {
            leaguesIdsMappedToSport = leagueId;
            isArray = true;
        }

        _each(leaguesIdsMappedToSport, ({sportId, leagues}, idx) => {

            sportId = Number(sportId);

            _each(leagues, (leagueId) => {
                const isOutright = isOutrightMemoized(leagueId, orgCategories);
                if (isOutright) {
                    let idOfSport = -1;
                    const indexOfLeague = outrights.indexOf(leagueId);

                    if (indexOfLeague != -1) {
                        if (outrightsCopy.length == 1) {
                            const indexOfSportId = sportsOrderCopy.indexOf(idOfSport);
                            sportsOrderCopy.splice(indexOfSportId, 1);
                        }
                        outrightsCopy = _without(outrightsCopy, ...([].concat(leagueId)));
                    } else {
                        const leagues = [].concat(leagueId);
                        const newLeaguesIds = _difference(leagues, outrightsCopy);
                        if (newLeaguesIds.length) {

                            if (isArray) {
                                outrightsCopy.push(...newLeaguesIds);
                            } else {
                                outrightsCopy.unshift(...newLeaguesIds);
                            }

                            const indexOfSportId = sportsOrderCopy.indexOf(idOfSport);
                            if (isArray) {
                                if (indexOfSportId == -1) {
                                    sportsOrderCopy.push(idOfSport);
                                }
                            } else {
                                if (indexOfSportId != -1) {
                                    sportsOrderCopy.splice(indexOfSportId, 1);
                                }
                                sportsOrderCopy.unshift(idOfSport);
                            }
                        }
                    }
                } else {
                    const leaguesForSport = _get(selectedLeaguesBySportCopy, [sportId], []);
                    const indexOfLeague = leaguesForSport.indexOf(leagueId);
                    if (indexOfLeague != -1) {
                        const leaguesForSportSliced = _without(leaguesForSport, ...([].concat(leagueId)));
                        _set(selectedLeaguesBySportCopy, [sportId], leaguesForSportSliced);

                        if (leaguesForSport.length == 1) {
                            const indexOfSportId = sportsOrderCopy.indexOf(sportId);
                            sportsOrderCopy.splice(indexOfSportId, 1);
                        }
                    } else {

                        const leagues = [].concat(leagueId);
                        const newLeaguesIds = _difference(leagues, leaguesForSport);
                        if (newLeaguesIds.length) {
                            hasNewLeagues = true;
                            if (isArray) {
                                leaguesForSport.push(...newLeaguesIds);
                            } else {
                                leaguesForSport.unshift(...newLeaguesIds);
                            }

                            _set(selectedLeaguesBySportCopy, [sportId], leaguesForSport);

                            const indexOfSportId = sportsOrderCopy.indexOf(sportId);
                            if (isArray) {
                                if (indexOfSportId == -1) {
                                    sportsOrderCopy.push(sportId);
                                }
                            } else {
                                if (indexOfSportId != -1) {
                                    sportsOrderCopy.splice(indexOfSportId, 1);
                                }
                                sportsOrderCopy.unshift(sportId);
                            }
                        }
                    }
                }
            });

        });

        let allLeaguesFromSports = _reduce(sportsOrderCopy, (initialArray, sportId) => {
            let leaguesForSport = null;
            if (sportId == -1) {
                leaguesForSport = outrightsCopy;
            } else {
                leaguesForSport = _get(selectedLeaguesBySportCopy, [sportId], []);
            }
            return initialArray.concat(leaguesForSport);
        }, []);

        let sportsName = _reduce(sportsOrderCopy, (initialArray, sportId) => {
            let sportName = null;
            if (sportId == -1) {
                sportName = 'Outrights';
            } else {
                const sportCategory = _find(orgCategories, {sportId});
                sportName = _get(sportCategory, 'sportName');
            }
            sportName = sportName.replace(/\s+/g, '-');
            return initialArray.concat(sportName);
        }, []);

        const params = {};
        params['categories'] = allLeaguesFromSports.join(',');
        params['selectedSports'] = sportsName.join(',');

        const urlParams = {};
        if (time) {
            urlParams['filterEventsByTime'] = time;
        }
        if (_size(params['categories']) && _size(params['selectedSports'])) {
            app.router.redirect('/sports/events/:selectedSports/:categories/', params, urlParams);
        } else {
            app.router.redirect('/');
        }
    };

    const showAllLeagues = (e) => {
        e.nativeEvent.stopImmediatePropagation();

        const favouriteLeaguesGroupBySport = _reduce(allFavouriteLeaguesSorted, (initialArray, {categoryId, sportId}) => {
            const leaguesIdsMappedToSportExist = _find(initialArray, {sportId});
            if (leaguesIdsMappedToSportExist) {
                const leaguesFromSport = _get(leaguesIdsMappedToSportExist, 'leagues');
                leaguesFromSport.push(categoryId);
            } else {
                initialArray.push({sportId, leagues: [].concat(categoryId)})
            }
            return initialArray;
        }, []);

        toggleSelectedLeaguesForSports(favouriteLeaguesGroupBySport);
        redirectToEventList(favouriteLeaguesGroupBySport);
    };

    const allFavouriteLeaguesEventsCount = useMemo(() => {
        const eventsCount = _reduce(favouriteLeagues, (total, {eventsCount}) => {
            return total + eventsCount;
        }, 0);
        return eventsCount
    }, [favouriteLeagues]);

    const allFavouriteLeaguesSorted = useMemo(() => {
        const favouritesWithOrders = _map(favouriteLeagues, (o) => {
            const {categoryId, sortOrder} = o;
            const calegoryLvl1 = findCategoryItemByIdLevel(categoryId, 1, orgCategories);
            const calegoryLvl2 = findCategoryItemByIdLevel(categoryId, 2, orgCategories);
            const calegoryLvl1Order = _get(calegoryLvl1, 'sortOrder');
            const calegoryLvl2Order = _get(calegoryLvl2, 'sortOrder');
            const order = `${calegoryLvl1Order}_${calegoryLvl2Order}_${sortOrder}`;
            return {...o, order}
        });

        return _sortBy(favouritesWithOrders, ['order']);
    }, [favouriteLeagues]);

    const isSelected = (leagueId, sportId) => {
        const isOutright = isOutrightMemoized(leagueId, orgCategories);
        if (isOutright) {
            return (outrights.indexOf(leagueId) != -1);
        } else {
            const leaguesForSport = _get(selectedLeaguesBySport, [sportId], []);
            return (leaguesForSport.indexOf(leagueId) != -1);
        }
    };

    return (
        <S.FavouriteLeagues className="favourite-leagues">

            <S.Header className="favourite-leagues-header" onClick={toggleListVisibility} isActive={showList}>
                <S.FavouriteIcon dangerouslySetInnerHTML={{__html: FavouriteIcon}}/>
                <S.HeaderText>{translation('favouriteLeagues_myFavouriteLeagues')}</S.HeaderText>
                <S.Arrow className="favourite-leages-header-arrow"/>
            </S.Header>

            {_size(allFavouriteLeaguesSorted) > 0 && (<CSSTransition in={showList} timeout={10000} className="slide-transition" classNames="slide-transition" unmountOnExit>
                
                <S.Body className="favourite-leagues-body" isVisible={showList}>
                    
                    {isFilteredCategoriesRequestPending && (<S.Cover><Loader size="5" color="#F05A22"/></S.Cover>)}

                    <S.ShowAll onClick={showAllLeagues}>
                        <S.ShowAllIcon dangerouslySetInnerHTML={{__html: showAllIconSVG}}></S.ShowAllIcon>
                        <S.ShowAllText>{translation('common_showAll')}</S.ShowAllText>
                        <S.EventsCount>{allFavouriteLeaguesEventsCount}</S.EventsCount>
                        <S.Arrow/>
                    </S.ShowAll>

                    <S.List className="favourite-leagues-body">
                        {_map(allFavouriteLeaguesSorted, ({categoryName, categoryId, eventsCount, sportId}) => {
                            const selected = isSelected(categoryId, sportId);
                            return (
                                <S.ListItem key={categoryId}
                                            isSelected={selected}
                                            onClick={toggleSelectedForLeague.bind(null, categoryId, sportId)}>
                                    <S.LeagueName>{categoryName}</S.LeagueName>
                                    <S.FavouriteIcon dangerouslySetInnerHTML={{__html: FavouriteIcon}}
                                                    onClick={toggleFavouriteForLeague.bind(null, categoryId, false)}>
                                    </S.FavouriteIcon>
                                    <S.EventsCount>{eventsCount}</S.EventsCount>
                                    <S.Arrow/>
                                </S.ListItem>
                            )
                        })}
                    </S.List>
                </S.Body>
                
            </CSSTransition>)}

        </S.FavouriteLeagues>
    );
};

export default memo(FavouriteLeagues);

