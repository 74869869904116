import React, {useState, useMemo} from 'react';
import Box from 'react-styled-box';
import ModalOpacity from 'ModalOpacity.js';
import useToggle from 'UseToggle.js';
import S from 'StyledAccountEditProfileForm.js';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {translation} from 'helpers/utilsHelper.js';
import {NRBvalidatior, validateDO} from 'helpers/validateHelper.js';
import {isValueUnique} from 'customerActions.js';
import classNames from 'classnames';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import _map from 'lodash/map';
import ChangePasswordForm from 'ChangePasswordForm.js';
import Datepicker from 'Datepicker.js';
import { subMonths } from 'date-fns/esm';
import {formatDate} from 'helpers/datesHelper.js'

// Validation
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';


const validate = values => {
    const errors = validation(values, validators.EDIT_ACCOUNT);
    return errors;
};

const asyncValidate = async(values, dispatch, props, field) => {
    if (field) {
        const fieldName = field;
        const fieldValue = values[field];

        try {
            const result = await dispatch(isValueUnique(fieldName, fieldValue));
            return result;
        } catch (err) {
            throw {[fieldName]: [translation(err)]};
        }
    }
};

const renderField = ({
    input,
    label,
    type,
    meta: {touched, error, warning, valid, asyncValidating, initial}
}) => {

    const inputClass = classNames({
        'async-validating': asyncValidating,
        'has-error': (touched && error),
        'is-valid': (touched && valid)
    });

    const isDisabled = !!initial;

    return (
        <S.Row>
            <S.InputWrapper>
                <S.Label>{label}</S.Label>
                <S.Input {...input} type={type} required="required" className={inputClass} disabled={isDisabled}/>
                {!valid && <S.SaveIcon/>}
                {touched && ((error && <S.Error>{error[0]}</S.Error>) || (warning && <S.Error>{warning}</S.Error>))}
            </S.InputWrapper>
        </S.Row>
    )
};

const renderDatepicker = ({
  input,
  label,
  meta: {touched, error, warning, valid, initial}
})=>{

  const val = input.value || initial;

  return (
    <S.Row>
      <S.InputWrapper>
        <S.Label>{label}</S.Label>
        <Datepicker
            selected={val}
            selectsStart
            startDate={val}
            endDate={val}
            dateFormat="dd-MM-yyyy"
            cb={(value)=>{
              input.onChange(value)
            }}
            minDate={subMonths(new Date(), 1836)}
            maxDate={new Date()}

        />
        <S.SaveIcon/>
        {(error && <S.Error>{error[0]}</S.Error>) || (warning && <S.Error>{warning}</S.Error>)}
      </S.InputWrapper>
    </S.Row>
  )
};

const renderSelect = ({
    input,
    label,
    options,
    meta: {touched, error, warning, valid, initial}
}) => {

    const inputClass = classNames({
        'has-error': (touched && error),
        'is-valid': (touched && valid)
    });

    const isDisabled = !!initial;

    return (
        <S.Row>
            <S.InputWrapper>
                <S.Label>{label}</S.Label>
                <select {...input} required="required" className={inputClass} disabled={isDisabled}>
                    <option>{translation('common_select')}</option>
                    {_map(options, ({name, value}) => {
                        return (<option key={value} value={value}>{name}</option>)
                    })}
                </select>
                {!valid && <S.SaveIcon/>}
                {touched && ((error && <S.Error>{error[0]}</S.Error>) || (warning && <S.Error>{warning}</S.Error>))}
            </S.InputWrapper>
        </S.Row>
    )
};

const AccountEditProfileForm = ({
    userData,
    initialize,
    initialValues,
    userId,
    changePasswordOnSubmit,
    updateUserDataOnSubmit,
    handleSubmit,
    pristine,
    submitting,
    refreshData,
    error
}) => {

    const [showSuccessModal, toggleSuccessModal] = useToggle(false);
    const [showChangePasswordModal, toggleChangePasswordModal] = useToggle(false);
    const [infoModalSuccessMsg, setInfoModalSuccessMsg] = useState('');

    const onFormSubmit = async(values) => {
        try {
            const postData = _pick(values, 
              ['address', 'mobileNumber', 'city', 'postalCode', 'documentNumber', 'countryOfBirth', 'firstName', 'lastName', 'dateOfBirth']);
            if (postData.dateOfBirth instanceof Date) {
                postData.dateOfBirth = formatDate(postData.dateOfBirth, 'dd-MM-yyyy');
            }
            const success = await updateUserDataOnSubmit(postData);
            setInfoModalSuccessMsg(translation('account_editProfileForm_updateSuccess'));
            
            await refreshData();
            toggleSuccessModal();

        } catch ({message}) {
            throw new SubmissionError({_error: message});
        }
    };

    const countries = useMemo(() => {
        const countries = app.service.Language.cachedDict.countries;
        const mainCountryCode = process.env.MAIN_COUNTRY_CODE;
        const options = _map(countries, (countryName, countryCode) => {
            const option = {};
            option['name'] = countryName;
            option['value'] = countryCode;
            return option;
        });
        return options;
    }, []);

    return (
        <S.AccountEditProfileForm className="account-edit-form" method="post" onSubmit={handleSubmit(onFormSubmit)}>

            <ModalOpacity isOpen={showChangePasswordModal}
                          toggleOpen={toggleChangePasswordModal}
                          showHeaderIcon={false}
                          title={translation('account_editProfileForm_changePassword_formHeader')}>

                <ChangePasswordForm changePasswordOnSubmit={changePasswordOnSubmit} userId={userId}/>

            </ModalOpacity>

            <ModalOpacity isOpen={showSuccessModal}
                          toggleOpen={toggleSuccessModal}
                          showHeaderIcon={false}
                          showHeaderTitle={false}>

                <S.SuccessModal>
                    <S.SuccessModalMessage>{infoModalSuccessMsg}</S.SuccessModalMessage>
                </S.SuccessModal>

            </ModalOpacity>

            <Box width="100%">
                <Box flexDirection="column" width="50%" padding="0 40px 0 0">
                    <Box flexDirection="column" flexGrow={1} margin="0 0 20px 0">
                        <Field
                            name="firstName"
                            type="text"
                            component={renderField}
                            label={translation('account_editProfileForm_firstName')}
                        />
                        <Field
                            name="loginName"
                            type="text"
                            component={renderField}
                            label={translation('account_editProfileForm_loginName')}
                        />
                        {/* <Field name="countryOfBirth"
                               component={renderSelect}
                               options={countries}
                               label={translation('account_editProfileForm_countryOfBirth')}
                        /> */}
                        <Field
                            name="postalCode"
                            type="text"
                            component={renderField}
                            label={translation('account_editProfileForm_postalCode')}
                        />

                        { typeof initialValues.dateOfBirth == "string" ?

                          <Field
                              name="dateOfBirth"
                              type="text"
                              component={renderField}
                              label={translation('account_editProfileForm_dateOfBirth')}
                          />
                          :
                          <Field
                              name="dateOfBirth"
                              type="text"
                              component={renderDatepicker}
                              label={translation('account_editProfileForm_dateOfBirth')}
                          />
                        }

                        <Field
                            name="email"
                            type="text"
                            component={renderField}
                            label={translation('account_editProfileForm_email')}
                        />
                        {_get(userData, 'bonusCode') && <Field
                            name="bonusCode"
                            type="text"
                            component={renderField}
                            label={translation('account_editProfileForm_registrationOffers')}
                        />}
                    </Box>

                    <Box justifyContent="center">
                        <S.ChangePasswordBtn onClick={toggleChangePasswordModal}>
                            {translation('account_editProfileForm_changePassword')}
                        </S.ChangePasswordBtn>
                    </Box>

                </Box>

                <Box flexDirection="column" width="50%" padding="0 0 0 40px">
                    <Box flexGrow={1} flexDirection="column" margin="0 0 20px 0">
                        <Field
                            name="lastName"
                            type="text"
                            component={renderField}
                            label={translation('account_editProfileForm_lastName')}
                        />
                        <Field
                            name="email"
                            type="text"
                            component={renderField}
                            label={translation('account_editProfileForm_email')}
                        />
                        {/*<Field name="nationality"*/}
                               {/*component={renderSelect}*/}
                               {/*options={countries}*/}
                               {/*label={translation('register_nationality')}*/}
                        {/*/>*/}
                        <Field
                            name="city"
                            type="text"
                            component={renderField}
                            label={translation('account_editProfileForm_city')}
                        />
                        <Field
                            name="address"
                            type="text"
                            component={renderField}
                            label={translation('account_editProfileForm_address')}
                        />
                        {_get(userData, 'nationality') == 'PL' && <Field
                            name="personalIdentifier"
                            type="text"
                            component={renderField}
                            label={translation('account_editProfileForm_pesel')}
                        />}
                        {/*<Field*/}
                            {/*name="documentNumber"*/}
                            {/*type="text"*/}
                            {/*component={renderField}*/}
                            {/*normalize={(value)=> value.replace(/\s+/g,'')}*/}
                            {/*label={(_get(userData, 'nationality') == 'PL') ? translation('register_identityCard') : translation('register_passportNr')}*/}
                        {/*/>*/}
                        <Field
                            name="mobileNumber"
                            type="text"
                            component={renderField}
                            label={translation('account_editProfileForm_telNumber')}
                        />
                    </Box>

                    <Box justifyContent="center">
                        <S.SubmitBtn type="submit" disabled={pristine || submitting}>
                            {translation('account_editProfileForm_save')}
                        </S.SubmitBtn>
                    </Box>

                </Box>
            </Box>

        </S.AccountEditProfileForm>
    );
};

export default reduxForm({
    form: 'accountEditProfile',
    validate,
    enableReinitialize: true
   // asyncValidate,
   // asyncBlurFields: ['mobileNumber', 'bankAccount', 'documentNumber']
})(AccountEditProfileForm);

