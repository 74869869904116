import styled from 'styled-components';
import {List} from 'global/styles/styles.js';
const S = {};

S.AccountWrapper = styled.div`
    height: 100%;
    display: block;
    flex-direction: column;
    flex:1;
    width: 100%;
    overflow: auto;
`;
S.AccountSidebar = styled.div`
    flex-basis: 17.75rem;
    background: #fff;
    
`;
S.AccountBody = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    background: #fff;
    border-bottom: 1px solid #a3a3a3;
    width: 100%;
`;
S.AccountTop = styled.div`
    height: 5.83rem;
    flex-basis: 10.83rem;
    background-color: #fff;
    padding: 0;
    display: flex;
    position: relative;
    border-top-left-radius: 0.42rem;
    border-top-right-radius: 0.42rem;
`;
S.AccountBottom = styled.div`
    flex: 1;
    padding: 0;
    display: flex;
    min-height: calc(100% - 5.83rem);
    height: auto;
`;
S.AccountTopLeftSide = styled.div`
    position: relative;
    min-height: 1px;
    padding-right: 0;
    padding-left: 0;
    width: 16.6666666667%;
    flex-basis: 17.75rem;
    background-color: ${({theme:{$accountTopLeftSideBgColor}})=> $accountTopLeftSideBgColor};
    border-top-left-radius: .42rem;
    color: #fff;
    display: flex;
    align-items: center;
`;

S.AccountTopRightSide = styled.div`
    position: relative;
    min-height: 1px;
    width: 83.3333333333%;
    flex: 1;
    padding: 0 6.67rem 0 .8333rem;
    display: flex;
    align-items: center;
    background-color: #eaeaea;
    border-top-right-radius: .42rem;
`;

S.UserFreebets = styled.div`
    background-color: #fff;
    margin-right: 3.33rem;
    border-radius: 5px;
    padding: 0 1.0833333333rem;
    width: 25%;
    height: 3.333rem;
`;

S.FreebetLabel = styled.div`
    font-weight: 700;
    color: #23282e;
    font-size: 1rem;
    color: #676767;
`;
S.FreebetsCount = styled.div`
    font-size: 1.2rem;
    font-weight: 600;
    color: #23282e;
    line-height: 1;
`;

S.UserMainBalance = styled.div`
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    margin-right: 3.33rem;
    border-radius: 5px;
    padding: 0 1.0833333333rem;
    width: 25%;
    height: 3.333rem;
`;

S.UserMainBalanceLabel = styled.div`
    width: 100%;
    font-weight: 700;
    color: #23282e;
    font-size: 1rem;
    color: #676767;
`;

S.UserMainBalanceValue = styled.div`
    font-size: 1.2rem;
    font-weight: 600;
    color: #23282e;
    align-self: baseline;
     line-height: 1;
`;

S.UserMainBalanceCurrency = styled.div`
    display: inline-block;
    font-weight: 700;
    color: #23282e;
    font-size: 1rem;
    text-transform: uppercase;
     align-self: baseline;
     margin-left: 2px;
     line-height: 1;
       font-size: 1.2rem;
`;

S.UserBonusBalance = styled.div`
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    margin-right: 3.33rem;
    border-radius: 5px;
    padding: 0 1.0833333333rem;
    width: 25%;
    height: 3.333rem;
    padding-right: 0;
`;

S.UserBonusBalanceLabel =  styled.div`
    width: 100%;
    font-weight: 700;
    color: #23282e;
    font-size: 1rem;
    color: #676767;
`;

S.UserIcon = styled.i`
    align-self: stretch;
    flex-basis: 5.833rem;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: url(/images/svg/edit_account_icon.svg);
    background-size: 2.6836rem auto;
`;
S.UserName = styled.div`
    font-size: 1.17rem;
    color: #fff;
    font-weight: 600;
`;
S.UserId = styled.div`
    font-size: 1.17rem;
    font-weight: 700;
`;
S.UserInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

S.BalanceSelect = styled.div`
    min-width: 100%;
    cursor: pointer;
    align-items: stretch;
    top: -3px;
    left: -10px;
    
    & .react-select__menu{
        margin-top: 0;
        
        & .react-select__menu-list{
             padding: 0;
        
            & > div{
                cursor: pointer;
            }
        }
    }
    
    & .react-select__control{
        box-shadow: none;
        min-height: 0;
        border: none;
        
        & .react-select__dropdown-indicator{
            padding: 3px;
        }
    
        & .react-select__value-container{
        
            & .react-select__single-value{
                 font-size: 1.2rem;
                 font-weight: 700;
                 font-family: Roboto,sans-serif;
            }
        }
    
    }
`;


export default S;