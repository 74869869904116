import React, {memo, useMemo, Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { getGameTypeGroupsForSport } from 'marketActions.js';
import { getCategoriesForMarketGroupTypeId, selectLeagueForSport, setCategoriesGroupId } from 'categoriesActions.js';
import GameTypeGroupFilterWithCategories from 'GameTypeGroupFilterWithCategories.js';

class EventsCustomWidget extends Component {
   
    state = {soccerGameTypeGruopIds: [], categories: []};

    componentDidMount = async() => {
        this.fetchGameTypeGroupsWithCategories();
    };
  
    fetchGameTypeGroupsWithCategories = async() => {
        const { getGameTypeGroupsForSport, getCategoriesForMarketGroupTypeId } = this.props;
        const soccerSportId = 1;
        const hardcodedMarketGroupTypesOrder = [2,33,13,6,64,34,10,65,11];
        try {
            const data = await getGameTypeGroupsForSport(soccerSportId);
            if (data) {
                const soccerGameTypes = data.find(o => o.sportId == soccerSportId) || [];
                const marketGroupTypeIdsSorted = _.intersection(hardcodedMarketGroupTypesOrder, soccerGameTypes.marketGroupTypeId);

                if (marketGroupTypeIdsSorted.length > 0) {
                    const categories = await getCategoriesForMarketGroupTypeId(marketGroupTypeIdsSorted[0]);
                    if (categories){
                        const soccerCategories = categories.filter(o => o.sportId == soccerSportId);
                        this.setState((prevState) => {
                            return {...prevState, soccerGameTypeGruopIds: marketGroupTypeIdsSorted, categories: soccerCategories};
                        })

                        app.util.Utils.initScrollbarForComponents({ scrollComponentsArray: [
                            document.querySelector('.game-group-category-filter-container')
                        ]});
                    }
                }
            }

        } catch (error) {
            console.log('fetchGameTypeGroupsWithCategories->', error);
        }
    };

    fetchCategoriesByGroupId = async(gameTypeGroupId) => {

        let { getCategoriesForMarketGroupTypeId, categoriesByMarketGroupTypeId } = this.props;
        let categories;
        
        if (!_.size(categoriesByMarketGroupTypeId[gameTypeGroupId])){
            try {
                categories = await getCategoriesForMarketGroupTypeId(gameTypeGroupId);
            } catch(error){
                console.log('fetchCategoriesByGroupId->', error);
            }
        } else {
            categories = categoriesByMarketGroupTypeId[gameTypeGroupId];
        }

        if (categories){
            const soccerSportId = 1;
            const soccerCategories = categories.filter(o => o.sportId == soccerSportId);
            this.setState((prevState) => {
                return {...prevState, categories: soccerCategories};
            })
        }

        setTimeout(()=> this.updateVerticalScrollbar(), 100);
    };  

    selectLeagueForSport = async(leagueId, sportId) => {
        const {selectLeagueForSport} =this.props
        if(leagueId !== null && sportId !== null){
            selectLeagueForSport(leagueId,sportId)
        }
    };

    setCategoriesGroupId = (groupId) => {
        const {setCategoriesGroupId} =this.props
        setCategoriesGroupId(groupId)
    };

    updateVerticalScrollbar = () => {
        document.querySelector('.game-group-category-filter-container').scrollTop = 0;
        Ps.update(document.querySelector('.game-group-category-filter-container'));
       
    };

    render() {
        return (
            <>
                <GameTypeGroupFilterWithCategories 
                    soccerGameTypeGroupIds={this.state.soccerGameTypeGruopIds}
                    categories={this.state.categories} 
                    fetchCategoriesByGroupId={this.fetchCategoriesByGroupId}
                    selectLeagueForSport={this.selectLeagueForSport}
                    setCategoriesGroupId={this.setCategoriesGroupId}>
                </GameTypeGroupFilterWithCategories>
            </>
        );
    }

}

const mapStateToProps = (state, props) => {
    const { MarketData:{ marketGroupTypes }, Categories: { categoriesByMarketGroupTypeId} } = state;
    return {
        marketGroupTypes,
        categoriesByMarketGroupTypeId
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getGameTypeGroupsForSport: bindActionCreators(getGameTypeGroupsForSport, dispatch),
        getCategoriesForMarketGroupTypeId: bindActionCreators(getCategoriesForMarketGroupTypeId, dispatch),
        selectLeagueForSport: bindActionCreators(selectLeagueForSport, dispatch),
        setCategoriesGroupId: bindActionCreators(setCategoriesGroupId,dispatch),
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsCustomWidget);
