import styled from 'styled-components';
import {Form, Input, Button} from 'global/styles/styles.js';
const S = {};

S.RegisterForm = styled(Form)`
 width: 100%;
`;

S.Row = styled.div``;

S.Label = styled.label`
    pointer-events: none;
    transition: .2s;
    position: absolute;
    top: 1.4rem;
    left: 1.8rem;
    color: #787d83!important;
    font-size: 1.33rem;
    font-weight: 400;
`;

S.FakeChbox = styled.div`
    width: 1.67rem;
    height: 1.67rem;
    border: .17rem solid #787d83;
    border-radius: .25rem;
    margin-right: .83rem;
    cursor: pointer;
    margin-top: .25rem;
    flex-shrink: 0;
    pointer-events: none;
    z-index: 3;
    background-color: #fff;
    position: relative;
`;

S.CheckMark = styled.span`
    position: absolute;
    left: 3px;
    top: 0;
    font-weight: bold;
    color: #787d83;
`;

S.Input = styled(Input)`
    box-shadow: none;
    border-radius: .42rem;
    border: .08rem solid #e1e1e1;
    color: #000;
    font-size: 1.33rem;
    padding: 2.1rem 1.75rem;
    background-color: #f6f6f6;
    display: block;
    width: 100%;
    height: 34px;
    &:valid{
        & + ${S.Label}{
            margin-top: -1.42rem;
            font-size: 1.08rem;
        }
    }
    &.has-error{
        border: .08rem solid red;
        border-radius: .42rem .42rem 0 0;
    }
    &.is-valid{
        border: .08rem solid #63dd7d;
    }
`;

S.SubmitBtn = styled(Button)`
    border-radius: .42rem;
    background-color: ${({theme:{$registerFormSubmitBtnBgColor}}) => $registerFormSubmitBtnBgColor};
    height: 4.6rem;
    line-height: 5rem;
    font-size: 1rem;
    font-weight: 600;
    padding: 0;
    width: 100%;
    margin-bottom: 2.18rem;
    margin-top: .69rem;
    color: ${({theme:{$registerFormSubmitBtnColor}}) => $registerFormSubmitBtnColor};
    min-width: 20%;
    cursor: pointer;
`;

S.Error = styled.div`
    color: red;
    float: left;
    width: 100%;
    background-color: #fba59f;
    margin-top: 0;
    border-radius: 0 0 2px 2px;
    padding: 2px .85rem 2px .85rem;
`;

S.ForgetPasswordLink = styled.div`
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.33rem;
    color: #bebebe;
    display: block;
    text-align: center;
    border: none;
    padding: 0;
    width: 100%;
    margin: 0 0 2rem;
    cursor: pointer;
`;

S.SubmissionError = styled.div`
    margin: 0;
    border-radius: .25rem;
    width: 100%;
    color: red;
    font-size: 1rem;
    text-align: left;
    padding: .83rem;
    background-color: #fba59f;
`;

S.SuccessModal = styled.div`
     width: 100%;
`;

S.SuccessModalTitle = styled.h4`
    color: #2d9ef5;
    text-transform: uppercase;
    font-size: 1.83rem;
    padding: 0;
    font-weight: 800;
    font-style: italic;
    float: none!important;
    text-align: center;
    margin-bottom: 3.42rem;
`;

S.SuccessModalMessage = styled.p`
    text-align: center;
    color: #000;
    margin: 0;
    font-size: 1.1538461538rem;
    letter-spacing: .2px;
    font-weight: 400;
`;

S.LoginBtn = styled(Button)`
    margin-top: 4.75rem;
    width: 21.67rem;
    height: 5rem;
    background-color: ${({theme:{$registerFormSubmitBtnBgColor}}) => $registerFormSubmitBtnBgColor};
    color: ${({theme:{$registerFormSubmitBtnColor}}) => $registerFormSubmitBtnColor};
    text-align: center;
    font-size: 1.83rem;
    font-style: italic;
    font-weight: 800;
    text-transform: uppercase;
    border-radius: .42rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
`;

S.HideIframe = styled.iframe`
    display: none;
`;

S.InputWrapper = styled.div`
    position: relative;
    margin-bottom: 2.25rem;
    
    &.with-checkbox{
        display: flex;
        flex-wrap: wrap;
        
        ${S.Error}{
           width: 100%;
        }  
        ${S.Label}{
            flex: 1;
            font-size: 1.17rem;
            font-weight: 400;
            padding-top: 0;
            color: #787d83;
            display: flex;
            position: relative;
            left: 0;
            cursor: pointer;
            top: 0;
        }
         ${S.Input}{
            position: absolute!important;
            padding: 0!important;
            border: 0!important;
            overflow: hidden;
            z-index: 0;
            left: 3px;
            top: -3px;
            width: auto;
         }
    }
`;

export default S;