import React, {useState} from 'react';
import S from 'StyledUnloggedPanel.js';
import {translation} from 'helpers/utilsHelper.js';
import ModalOpacity from 'ModalOpacity.js';
import useToggle from 'UseToggle.js'
import LoginForm from 'LoginForm.js';
import RegisterForm from 'RegisterForm.js';
import ForgotPasswordForm from 'ForgotPasswordForm.js';
import {integrationBetgeniusCreateScript} from 'integrationActions.js';

const UnloggedPanel = ({loginOnSubmit, registerOnSubmit, forgotPasswordOnSubmit}) => {

    const [showLoginModal, toggleLoginModal] = useToggle(false);
    const [showRegisterModal, toggleRegisterModal] = useToggle(false);
    const [showForgetPasswordModal, toggleForgetPasswordModal] = useToggle(false);

    if(showRegisterModal){
        if(process.env.BETGENIUS_TAGS_INTEGRATION_SCRIPT == 'true'){
            integrationBetgeniusCreateScript('regstart', null);
        }
    }
    return (
        <S.UnloggedPanel className="unlogged-panel">

            <S.ButtonsWrapper className="buttons-wrapper">
                <S.Button className="login-btn"
                          onClick={toggleLoginModal}>
                    {translation('account_unloggedPanel_header_login')}
                </S.Button>
                <S.Button className="register-btn"
                          onClick={toggleRegisterModal}>
                    {translation('account_unloggedPanel_header_register')}
                </S.Button>
            </S.ButtonsWrapper>

            <ModalOpacity isOpen={showLoginModal} toggleOpen={toggleLoginModal} title="Login">
                <LoginForm loginOnSubmit={loginOnSubmit} toggleForgetPasswordModal={toggleForgetPasswordModal}/>
                {/* placeholder */}
            </ModalOpacity>

            <ModalOpacity isOpen={showRegisterModal} toggleOpen={toggleRegisterModal} title="Register">
                <RegisterForm registerOnSubmit={registerOnSubmit} toggleLoginModal={toggleLoginModal}/>
            </ModalOpacity>

            <ModalOpacity isOpen={showForgetPasswordModal} toggleOpen={toggleForgetPasswordModal}
                          title="Forgot password" showHeaderIcon={false}>
                <ForgotPasswordForm forgotPasswordOnSubmit={forgotPasswordOnSubmit}/>
            </ModalOpacity>

        </S.UnloggedPanel>
    );
};

export default UnloggedPanel;
