import _size from 'lodash/size';

export const CHANGE_LIVE_CALENDAR_DATE = 'CHANGE_LIVE_CALENDAR_DATE';
export const CHANGE_LIVE_CALENDAR_SPORT = 'CHANGE_LIVE_CALENDAR_SPORT';
export const FETCH_LIVE_CALENDAR_EVENTS = 'FETCH_LIVE_CALENDAR_EVENTS';
export const CHANGE_LIVE_CALENDAR_STREAM_FILTER = 'CHANGE_LIVE_CALENDAR_STREAM_FILTER';


const changeDate = (selectedDate) => {
    return {
        type: CHANGE_LIVE_CALENDAR_DATE,
        payload: {
            selectedDate
        }
    }
};

const changeSport = (selectedSport) => {
    return {
        type: CHANGE_LIVE_CALENDAR_SPORT,
        payload: {
            selectedSport
        }
    }
};
const changeStreamFilter = (showStreamingOnly) => {
    return {
        type: CHANGE_LIVE_CALENDAR_STREAM_FILTER,
        payload: {
            showStreamingOnly
        }
    }
};

const fetchLiveCalendarEvents = () => {
    return async(dispatch, getState, {LiveApi}) => {
        dispatch(fetchLiveCalendarEventsPending());

        try{
            const {code, data} = await LiveApi.liveCalendarUpcomings();
            if(code!=200 || !_size(data)){
                throw {message: 'fetchLiveCalendarEvents error'}
            }
            dispatch(fetchLiveCalendarEventSuccess(data));
            return data;
        }catch(error){
            dispatch(fetchLiveCalendarEventsFailure(error));
            throw error;
        }
    }
};

const fetchLiveCalendarEventSuccess = (liveEvents) => {
    return {
        type: `${FETCH_LIVE_CALENDAR_EVENTS}_SUCCESS`,
        payload: {liveEvents}
    }
};

const fetchLiveCalendarEventsPending = () => {
    return {
        type: `${FETCH_LIVE_CALENDAR_EVENTS}_PENDING`
    }
};

const fetchLiveCalendarEventsFailure = (error) => {
    return {
        type: `${FETCH_LIVE_CALENDAR_EVENTS}_FAILURE`,
        payload: {error}
    }
};

export {changeSport, changeDate, fetchLiveCalendarEvents, changeStreamFilter}