
import React, {Component} from 'react';
import {NotLogged} from 'global/styles/styles.js';
import {connect} from 'react-redux';
import {paymentProviders} from 'paymentActions.js';
import Loader from 'Loader.js';
import PaymentsList from 'PaymentsList.js';
import _size from 'lodash/size';
import {translation} from 'helpers/utilsHelper.js';
import paymentPrepareTransaction from 'paymentPrepareTransaction.enum.js';
import {providerSettingsSelector} from 'paymentsSelector.js';
import ProviderContent from 'ProviderContent.js';
import S from 'StyledAccountWithdraw.js';

class AccountWithdraw extends Component {

    componentDidMount = async() => {
        const {dispatch} = this.props;
        const {paymentCurrency} = paymentPrepareTransaction.LIST_SETTINGS;
        const currencyCode = process.env.MAIN_CURRENCY_CODE;

        try {
            paymentCurrency ? await dispatch(paymentProviders(currencyCode)) : await dispatch(paymentProviders()); 
        } catch (error) {
            console.log('paymentProviders error:' + error);
        }
    };

    static getDerivedStateFromError = (error) => {
        return {hasError: true};
    };

    render() {
        const {isPending, hasError, paymentProviders, user, isLogged} = this.props;
        const paymentData = paymentProviders.payouts;
        const {PROVIDER, LIST_SETTINGS}  = paymentPrepareTransaction;
        const controller = app.getCurrentController();

        if (hasError) {
            return <div>Error fallback component!</div>
        }
        
        if (isPending) {
            return <Loader/>
        }

        if (!isLogged && controller != 'AdminDeposit' && !isPending){
            return <NotLogged>{translation('payment_notLogged')}</NotLogged>
        }

        if (paymentData.length > 1) {
            return (
                <S.AccountWithdraw>
                    <PaymentsList 
                        paymentData={paymentData} 
                        miniature={LIST_SETTINGS.miniature}
                        userData={user}
                        isLogged={isLogged}
                        isWithdraw={true}
                    />
                </S.AccountWithdraw>
            );
        } else if (paymentData.length == 1) {
            let provider = _.first(paymentData);
            return (
                <S.AccountWithdraw onlyOne={true}>
                    <ProviderContent 
                        providerForm={PROVIDER[provider.providerId]} 
                        paymentData={_.first(paymentData)}
                        userData={user}
                        form ={'providerContentForm_' + provider.providerId}
                        onlyOne = {true}
                        isLogged={isLogged}
                    />
                </S.AccountWithdraw>
            );
        } else {
            return (
                <S.NoActiveProviders>
                   {translation('payments_noActiveProviders')}
                </S.NoActiveProviders>
            );
        }
    }
}

const mapStateToProps = (state, props) => {
    const {AccountWithdraw:{isPending, error, hasError}, Auth:{isLogged, user}} = state;
    const paymentProviders = providerSettingsSelector(state, props);
    return {
        isPending,
        error,
        hasError,
        paymentProviders,
        user,
        isLogged
    }
};

export default connect(mapStateToProps)(AccountWithdraw);
