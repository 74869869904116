import styled from 'styled-components';
import {List} from 'global/styles/styles.js';
const S = {};

const duration = 1000; 

S.DropdownMenuList = styled(List)`
    float:left;
    width: 100%;
    & > li:first-of-type > div {

    }
    & > li:last-of-type > div {

    }
    
    &.slide-transition-enter {
        max-height: 0px;
        overflow: hidden;
        transition: max-height ${duration}ms;
    }
        
    &.slide-transition-enter-active {
        max-height: 1000px;
    }

    &.slide-transition-exit {
        max-height: 1000px;
        overflow: hidden;
        transition: max-height ${duration}ms;
    }
        
    &.slide-transition-exit-active {
        max-height: 0px;
    }   

     background-color: ${({theme:{$categoriesDropdownBgColor}}) => $categoriesDropdownBgColor};
`;

S.DropdownSubmenuList = styled(S.DropdownMenuList)`
    float:left;
    width: 100%;
    &.slide-transition-enter {
        max-height: 0px;
        overflow: hidden;
        transition: max-height ${duration}ms;
    }
        
    &.slide-transition-enter-active {
        max-height: 1000px;
    }

    &.slide-transition-exit {
        max-height: 1000px;
        overflow: hidden;
        transition: max-height ${duration}ms;
    }
        
    &.slide-transition-exit-active {
        max-height: 0px;
    }        
            
`;


S.Title = styled.span`
    overflow: hidden;
    flex: 1;
    padding-right: 10px;
    transform: translateZ(0);

    ${({isSelected}) => {
        return (isSelected == true) && `
           color: #f05a22;
        `;
    }};
    
`;

S.FavouriteIcon = styled.div`
     width: 9px;
     height: 9px;
    cursor: pointer;
    line-height: 0;
    display: none;
    & svg{
        & path{
            fill:   #44474e !important;
        }
    }
    
    &:hover{
        & svg{
        & path{
            fill:  #FFB203 !important;
        }
    }
  
    }
    
        ${({isFavourite}) => {
    
    return (isFavourite == true) && `
             display: block;
             
                    & svg{
        & path{
            fill:  #FFB203 !important;
        }
    }
             
        `;
}};
`;

S.Icon = styled.img`
    flex-shrink: 0;
    margin-right: 2rem;
    width: 18px;
    height: 18px;
    color: ${({theme:{$categoriesSportIconColor}}) => $categoriesSportIconColor};
`;

S.FlagIcon = styled.img`
    flex-shrink: 0;
    margin-right: 10px;
    height: 16px;
    width: 16px;
`;

S.Arrow = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    transition: transform 0.5s, background-color 0.5s;
    &:before {
        content: '';
        width: 1.2rem;
        height: .87rem;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        background-color: ${({theme:{$categoriesDropdownArrowColor}}) => $categoriesDropdownArrowColor};
        mask-size:  1.2rem .87rem;
        -webkit-mask-size:  1.2rem .87rem;
        mask: url(/images/svg/chevron_right_grey.svg) no-repeat 50% 50%;
        -webkit-mask: url(/images/svg/chevron_right_grey.svg) no-repeat 50% 50%;
    }
`;

S.ArrowDown = styled(S.Arrow)`
    &:before {
        width: 0.9rem;
        mask: url(/images/svg/chevron_down_grey.svg) no-repeat 30% 30%;
        -webkit-mask: url(/images/svg/chevron_down_grey.svg) no-repeat 30% 30%;
    }
`;

S.EventsCount = styled.span`
    display: inline-block;
    line-height: 1.3rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({theme:{$categoriesEventsCount}}) => $categoriesEventsCount};
    text-align: center;
    font-size: .9rem!important;
    border-radius: 3px;
    padding: 3px 0;
    flex-shrink: 0;
    margin-right: 15px;
`;

S.DropdownMenuItemInner = styled.div`
    margin-bottom: 1px ;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 48px;
    overflow: hidden;
    box-shadow: none;
    border-radius: 0;
    font-size: 1.1rem!important;
    margin-top: 0;
    background: 0 0;
    background-color: ${({theme:{$categoriesDropdownItemBgColor}}) => $categoriesDropdownItemBgColor};
    padding: 1rem 19px 1rem 17px;
    color: ${({theme:{$categoriesDropdownItemColor}}) => $categoriesDropdownItemColor};
    &:hover {
        background-color: ${({theme:{$categoriesDropdownItemBgColorHover}}) => $categoriesDropdownItemBgColorHover};

        ${S.FavouriteIcon}{
            display: block;
        }
    }
`;

S.DropdownMenuItem = styled.li`

    &[data-level="1"].is-active {
        &>${S.DropdownMenuItemInner}{
            position: relative;
            :before{
                content: '';
                top:0;
                bottom: 0;
                left: 0;
                width: 2px;
                position: absolute;
                background-color: ${({theme:{$categoriesDropDownItemInnerActiveBorder}}) => $categoriesDropDownItemInnerActiveBorder};
            }
            & ${S.EventsCount}{
                display: none;
            }
            & ${S.Arrow} {
                transform: rotate(180deg);
                &:before {
                    background-color: ${({ theme: { $categoriesDropdownActiveArrowColor } }) => $categoriesDropdownActiveArrowColor};
                }
            }
        }
    }

    &[data-level="2"]{
        &>${S.DropdownMenuItemInner}{
            background-color: ${({theme:{$categoriesDropdownItemLvl2BgColor}}) => $categoriesDropdownItemLvl2BgColor};
            margin-left: 1.5rem;
            padding: 1rem 19px 1rem 24px;
            &>${S.EventsCount} {
                margin-right: 17px;
            }
        }
    }

    &[data-level="2"].is-active {
        &>${S.DropdownMenuItemInner} {
            background-color: ${({ theme: { $categoriesDropdownItemLvl2ActiveBgColor } }) => $categoriesDropdownItemLvl2ActiveBgColor};
            & ${S.EventsCount}{
                color: ${({ theme: { $categoriesDropdownItemLvl2ActiveEventCount } }) => $categoriesDropdownItemLvl2ActiveEventCount};
            }
            & ${S.Arrow} {
                transform: rotate(180deg);
                &:before {
                    background-color: ${({ theme: { $categoriesDropdownActiveArrowColor } }) => $categoriesDropdownActiveArrowColor};
                }
            }
        }
    }

    &[data-level="3"]{
        &>${S.DropdownMenuItemInner}{
            margin-left: 1.5rem;
            background-color: ${({theme:{$categoriesDropdownItemLvl3BgColor}}) => $categoriesDropdownItemLvl3BgColor};

            & ${S.EventsCount}{
                width: 12px;
            }
            & ${S.FavouriteIcon}{
            margin-right: 20px;
            }
        }
    }
`;

S.FakeChbox = styled.div`
    width: 1.16rem;
    height: 1.16rem;
    border: .17rem solid ${({theme:{$categoriesLvl3CheckboxBorderColor}}) => $categoriesLvl3CheckboxBorderColor};
    background-color: ${({theme:{$categoriesLvl3CheckboxBgColor}}) => $categoriesLvl3CheckboxBgColor};
    border-radius: 2px;
    margin-right: 10px;
    &.is-active {
        border-color: ${({theme:{$categoriesLvl3CheckboxActiveBorderColor}}) => $categoriesLvl3CheckboxActiveBorderColor};
        background-color:  ${({theme:{$categoriesLvl3CheckboxActiveBgColor}}) => $categoriesLvl3CheckboxActiveBgColor};
        &:after {
            content: "\f3fd";
            display: block;
            margin-left: 2px;
            color: #000;
            font-family: Ionicons;
            font-size: 15px;
            margin-top: -.5rem!important;
            color: #fff;
        }
}
`;

S.ShowAll = styled.div`
    height: 48px;
    background-color: ${({ theme: { $categoriesDropdownShowAllBgColor } }) => $categoriesDropdownShowAllBgColor};
    margin-bottom: 1px;
    display: flex;
    align-items: center;
    font-size: 13px;
    padding: 1rem 19px 1rem 24px;
    margin-left: 18px;
    cursor: pointer;

    & ${S.EventsCount}{
        width: auto;
        margin-left: auto;
        margin-right: 17px;
        line-height: 1;
    }

    & ${S.ArrowDown}:before {
        transform: rotate(-90deg);
    }
    
`;

S.ShowAllTxt = styled.div`
    color: ${({theme:{$categoriesDropdownItemColor}}) => $categoriesDropdownItemColor};
    line-height: 1;
    &:first-letter{
    text-transform: uppercase;
    }
`;

S.ShowAllIcon = styled.i`
    width: 19px;
    height: 19px;
    flex-shrink: 0;
    margin-right: 10px;
`;

export default S;