import React, {useEffect} from 'react';
import Select from 'react-select';
import {topMenuUserIconSVG} from 'global/svgIcons/svgIcons.js'
import S from 'StyledLoggedPanel.js';
import {translation, formatMoney} from 'helpers/utilsHelper.js';
import _each from 'lodash/each';
import _find from 'lodash/find';
import useToggle from 'UseToggle.js';
import AccountModal from 'AccountModal.js';
import Account from 'Account.js';

const LoggedPanel = ({user, logOut, balanceId, toggleBalance, toggleUserAccountModal, isAccountOpen}) => {

    const {login, firstName, userId, currencyCode, balance, bonusBalanceList} = user;
    const [showAccountModal, toggleAccountModal] = useToggle(isAccountOpen);

    const onBalanceChange = ({value}) => {
        const balanceId = value;
        const setBonusBalance = Boolean(balanceId);

        toggleBalance(setBonusBalance, balanceId);
        localStorage.setItem('_bonus_balance_id', balanceId);
    };

    const selectedOption = () => {
        const currentBalanceId = localStorage.getItem('_bonus_balance_id') || balanceId;
        const selectedValue = _find(getBalanceList(), {value: Number(currentBalanceId)});
        return selectedValue;
    };

    const getBalanceList = () => {
        const options = [];
        const mainBalance = {
            value: 0,
            label: `${translation('account_loggedPanel_balance')}: ${formatMoney(balance)} ${currencyCode}`
        };

        options.push(mainBalance);

        _each(bonusBalanceList, ({nameKey, currency, wagerBonusId, amount}) => {
            const bonusBalance = {
                value: wagerBonusId,
                label: `${translation(nameKey)}: ${formatMoney(amount)} ${currency}`
            };
            options.push(bonusBalance);
        });

        return options;
    };

    const openAccount = () => {

        if (process.env.NODE_ENV == 'development') {
            toggleUserAccountModal(!showAccountModal);
            toggleAccountModal();
        }else{
            // spike
            app.component.TopMenu.openAccountModal();
        }
    };

    useEffect(() => {
        const currentBalanceId = localStorage.getItem('_bonus_balance_id') || balanceId;
        const setBonusBalance = Boolean(parseInt(currentBalanceId));

        toggleBalance(setBonusBalance, currentBalanceId);
        app.service.BetSlip.betSlip.placeFromBonusBalance = Boolean(parseInt(currentBalanceId));

        // need to refactor if project use React BetSlip
        app.service.BetSlip.calculateWinning();
    }, [balanceId]);

    useEffect(() => {
        if (showAccountModal && !isAccountOpen) {
            toggleAccountModal();
        } else if (!showAccountModal && isAccountOpen) {
            toggleAccountModal();
        }

    }, [isAccountOpen]);

    // Logout user - need calculateWinning call
    useEffect(() => {
        return () => {
            // need to refactor if project use React BetSlip
            app.service.BetSlip.calculateWinning();
        }
    }, []);

    return (
        <S.LoggedPanel className="logged-panel">

            <AccountModal isOpen={showAccountModal} toggleOpen={toggleAccountModal}>
                <Account user={user} toggleBalance={toggleBalance} balanceId={balanceId} />
            </AccountModal>

            <S.UserAccout className="user-accout" onClick={openAccount}>
                <S.UserIcon dangerouslySetInnerHTML={topMenuUserIconSVG()}></S.UserIcon>
                <S.UserLogin className="user-login">{login}</S.UserLogin>
            </S.UserAccout>

            <S.BalanceSelect as={Select} options={getBalanceList()} value={selectedOption()} onChange={onBalanceChange}
                             isSearchable={false} classNamePrefix="react-select" className="react-select-container"/>

            <S.LogoutBtn className="logout-btn" onClick={logOut}>
                {translation('account_loggedPanel_header_logout')}
            </S.LogoutBtn>

        </S.LoggedPanel>
    );
};

export default React.memo(LoggedPanel);
