import styled from 'styled-components';
import {List, Button} from 'global/styles/styles.js';
const S = {};

S.LoggedPanel = styled.div`
    display: flex;
`;

S.LogoutBtn = styled(Button)`
    background-color: ${({theme:{$topMenuLoggedOutBtnBgColor}})=> $topMenuLoggedOutBtnBgColor};
    min-width: 6rem;
    height: 2.5rem;
    padding: .5rem 1rem;
    border: 0;
    border-radius: .5rem;
    display: block;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    font-size: 1.2rem;
    font-family: Roboto,sans-serif;
    line-height: 1.2rem;
    padding-left: 1rem;
    font-weight: 700;
    color: ${({theme:{$topMenuUnloggedBtnColor}})=> $topMenuUnloggedBtnColor};
    flex-shrink: 0;
    margin-left: 5px;
    text-transform: lowercase;
    
    &:first-letter{
        text-transform: uppercase;
    }
`;

S.UserAccout = styled.div`
    min-width: 6rem;
    height: 2.5rem;
    padding: .5rem 1rem;
    border: 0;
    border-radius: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    font-weight: 700;
    margin-right: 5px;
    flex-shrink: 0;
    cursor: pointer;
`;

S.UserLogin = styled.div`
    color: ${({theme:{$topMenuLoggedUserLoginColor}})=> $topMenuLoggedUserLoginColor};
    font-size: 1.2rem;
    font-size: 1.2rem;
    font-family: Roboto,sans-serif;
    line-height: 1.2rem;
    padding-left: 1rem;
    cursor: pointer;
    flex-shrink: 0;
`;

S.UserIcon = styled.i`
    box-sizing: border-box;
    width: 1.6rem;
    height: 1.6rem;
    display: inline-block;
    line-height: 1.6rem;
    flex-shrink: 0;
    & > svg {
        & > path{
            fill: ${({theme:{$topMenuLoggedUserLoginColor}})=> $topMenuLoggedUserLoginColor} !important;
        }
    }
`;

S.BalanceSelect = styled.div`
    min-width: 300px;
    cursor: pointer;
    align-items: stretch;
    
    & .react-select__menu{
        margin-top: 0;
        
        & .react-select__menu-list{
             padding: 0;
        
            & > div{
                cursor: pointer;
            }
        }
    }
    
    & .react-select__control{
        box-shadow: none;
        min-height: 0;
        
        & .react-select__dropdown-indicator{
            padding: 3px;
        }
    
        & .react-select__value-container{
        
            & .react-select__single-value{
                 font-size: 1.2rem;
                 font-weight: 700;
                 font-family: Roboto,sans-serif;
            }
        }
    
    }
`;

export default S;