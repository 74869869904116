import styled from 'styled-components';
import {Form, Input, Button} from 'global/styles/styles.js';
const S = {};

S.ForgotPasswordForm = styled(Form)`
width: 100%;
`;

S.Row = styled.div``;

S.Label = styled.label`
    pointer-events: none;
    transition: .2s;
    position: absolute;
    top: 1.4rem;
    left: 1.8rem;
    color: #787d83!important;
    font-size: 1.33rem;
    font-weight: 400;
`;

S.InputWrapper = styled.div`
    position: relative;
    margin-bottom: 2.25rem;
`;

S.Input = styled(Input)`
    box-shadow: none;
    border-radius: .42rem;
    border: .08rem solid #e1e1e1;
    color: #000;
    font-size: 1.33rem;
    padding: 2.1rem 1.75rem;
    background-color: #f6f6f6;
    display: block;
    width: 100%;
    height: 34px;
    &:valid{
        & + ${S.Label}{
        margin-top: -1.42rem;
        font-size: 1.08rem;
        }
    }
`;

S.SubmitBtn = styled(Button)`
    width: 21rem;
    height: 5rem;
    color: #fff;
    background-color: ${({ theme: { $resetPasswordFormBtnColor } }) => $resetPasswordFormBtnColor};
    font-size: 2rem;
    font-style: italic;
    font-weight: 800;
    text-align: center;
    line-height: 5rem;
    padding: 0;
    text-transform: uppercase;
    margin: 0 auto;
    border: 1px solid transparent;
    border-radius: 4px;
    display: block;
    cursor: pointer;
    &:hover{
        background-color: #51a517;
        transition: .2s all linear;
    }
`;

S.Error = styled.div`
    color: red;
    margin: 5px 0;
`;

S.SubmissionError = styled.div`
    margin: 0 0 10px;
    border-radius: .25rem;
    width: 100%;
    color: red;
    font-size: 1rem;
    text-align: left;
    padding: .83rem;
    background-color: #fba59f;
`;

S.Info = styled.p`
    text-align: center;
    color: #000;
    margin: 0 0 10px;
`;

S.SuccessModal = styled.div`
`;

S.SuccessModalTitle = styled.h4`
    color: #2d9ef5;
    text-transform: uppercase;
    font-size: 1.83rem;
    padding: 0;
    font-weight: 800;
    font-style: italic;
    float: none!important;
    text-align: center;
    margin-bottom: 3.42rem;
`;

S.SuccessModalMessage = styled.p`
    text-align: center;
    color: #000;
    margin: 0;
    font-size: 1.1538461538rem;
    letter-spacing: .2px;
    font-weight: 400;
`;

S.ErrorModal = styled.div``;

export default S;
