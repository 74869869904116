import _get from 'lodash/get';
import _each from 'lodash/each';
import _difference from 'lodash/difference';
import _find from 'lodash/find';
import _has from 'lodash/has';
import typeToReducer from 'type-to-reducer';
import update from 'immutability-helper';
import memoize from 'fast-memoize';
import {
    FETCH_CATEGORIES,
    ADD_LEAGUE_BY_ID,
    REMOVE_LEAGUE_BY_ID,
    CHANGE_SPORT_ID,
    SET_CATEGORIES_BY_TIME,
    CHANGE_TIME_FILTER,
    SELECT_LEAGUE_FOR_SPORT,
    UNSELECT_LEAGUE_FOR_SPORT,
    CHANGE_SPORT_ORDER,
    SELECT_LEAGUES_GROUP_BY_SPORT,
    REMOVE_SPORT_BY_ID,
    RESET_SLIP_LIST,
    LOAD_CATEGORIES_FOR_MARKET_GROUP_TYPE_ID,
    SET_CATEGORIES_GROUP_ID,

} from 'categoriesActions.js';

const removeLeagueById = (leagues, searchId) => {
    return leagues.filter(leagueId => leagueId != searchId);
};

const isOutright = (categoryId, categories) => {
    const league = _find(categories, {categoryId});
    const treatAsSport = _get(league, 'treatAsSport');
    return treatAsSport == -500;
};

const isOutrightMemoized = memoize(isOutright);

let initialState = {
    isPending: true,
    error: null,
    categories: [],
    selectedLeagues: [],
    prevSortId: null,
    time: null,
    categoriesByTime: {},
    sportsOrder: [],
    selectedLeaguesBySport: {},
    outrights: [],
    categoriesByMarketGroupTypeId: {},
    isFilteredCategoriesRequestPending: false,
    groupTypeId: null,
};

export default typeToReducer({
    [REMOVE_SPORT_BY_ID]: (state, {payload:{sportId}}) => {
        let outrights = state.outrights;
        let selectedLeaguesBySport = state.selectedLeaguesBySport;
        let sportsOrder = state.sportsOrder;

        if (_has(selectedLeaguesBySport, [sportId])) {
            const sportIdToRemove = String(sportId);
            selectedLeaguesBySport = update(selectedLeaguesBySport, {$unset: [sportIdToRemove]});

            const indexOfSportInOrder = sportsOrder.indexOf(sportId);
            sportsOrder = update(sportsOrder, {$splice: [[indexOfSportInOrder, 1]]});
        } else {
            outrights = update(outrights, {$set: []});

            const indexOfSportInOrder = sportsOrder.indexOf(sportId);
            sportsOrder = update(sportsOrder, {$splice: [[indexOfSportInOrder, 1]]});
        }

        return {...state, sportsOrder, outrights, selectedLeaguesBySport};
    },
    [SELECT_LEAGUES_GROUP_BY_SPORT]: (state, {payload:{leaguesGroupBySport}}) => {
        let selectedLeaguesBySport = state.selectedLeaguesBySport;
        let sportsOrder = state.sportsOrder;
        let outrights = state.outrights;

        _each(leaguesGroupBySport, ({sportId, leagues}) => {

            sportId = Number(sportId);

            _each(leagues, (leagueId) => {
                const isOutright = isOutrightMemoized(leagueId, state.categories);
                if (isOutright) {
                    outrights = update(outrights, {$push: [leagueId]});
                } else {
                    selectedLeaguesBySport = update(selectedLeaguesBySport, {
                        [sportId]: (prevLeagues) => {
                            prevLeagues = prevLeagues || [];
                            const newLeaguesIds = _difference([].concat(leagueId), prevLeagues);
                            if (newLeaguesIds.length) {
                                return update(prevLeagues, {$push: newLeaguesIds});
                            } else {
                                return update(prevLeagues, {$set: prevLeagues});
                            }
                        }
                    });
                }

                const idOfSport = isOutright ? -1 : sportId;
                const indexOfSport = sportsOrder.indexOf(idOfSport);
                if (indexOfSport == -1) {
                    sportsOrder = update(sportsOrder, {$push: [idOfSport]});
                }
            });
        });

        return {...state, selectedLeaguesBySport, outrights, sportsOrder};
    },
    [CHANGE_SPORT_ORDER]: (state, {payload:{sportId}}) => {
        const indexOfSport = state.sportsOrder.indexOf(sportId);
        let sportsOrder = state.sportsOrder;
        if (indexOfSport != -1) {
            sportsOrder = update(sportsOrder, {$splice: [[indexOfSport, 1], [0, 0, sportId]]});
        } else {
            sportsOrder = update(sportsOrder, {$unshift: [sportId]});
        }
        return {...state, sportsOrder}
    },
    [RESET_SLIP_LIST]:(state)=>{
        return{...state,outrights : [],selectedLeaguesBySport:{},sportsOrder:[], groupTypeId:null}
    },
    [SELECT_LEAGUE_FOR_SPORT]: (state, {payload:{leagueId, sportId}}) => {
        let selectedLeaguesBySport = state.selectedLeaguesBySport;
        let outrights = state.outrights;
        let sportsOrder = state.sportsOrder;
        let hasNewLeagues = false;
        const isArray = Array.isArray(leagueId);
        const leagues = [].concat(leagueId);

        _each(leagues, (leagueId, idx) => {
            const isOutright = isOutrightMemoized(leagueId, state.categories);
            if (isOutright) {
                const newLeaguesIds = _difference([].concat(leagueId), outrights);
                if (newLeaguesIds.length) {
                    if (isArray) {
                        outrights = update(outrights, {$push: [leagueId]});
                    } else {
                        outrights = update(outrights, {$unshift: [leagueId]});
                    }
                }
            } else {
                selectedLeaguesBySport = update(selectedLeaguesBySport, {
                    [sportId]: (sportId) => {
                        sportId = sportId || [];
                        const newLeaguesIds = _difference([].concat(leagueId), sportId);
                        if (newLeaguesIds.length) {
                            hasNewLeagues = true;
                            if (isArray) {
                                return update(sportId, {$push: newLeaguesIds});
                            } else {
                                return update(sportId, {$unshift: newLeaguesIds});
                            }
                        } else {
                            return update(sportId, {$set: sportId});
                        }
                    }
                });
            }

            const idOfSport = isOutright ? -1 : sportId;
            const indexOfSport = sportsOrder.indexOf(idOfSport);
            if (isArray) {
                if (indexOfSport == -1) {
                    sportsOrder = update(sportsOrder, {$unshift: [idOfSport]});
                }
            } else {
                if (indexOfSport != -1) {
                    sportsOrder = update(sportsOrder, {$splice: [[indexOfSport, 1], [0, 0, idOfSport]]});
                } else {
                    sportsOrder = update(sportsOrder, {$unshift: [idOfSport]});
                }
            }
        });

        if (isArray) {
            let idOfSport = null;
            if (hasNewLeagues) {
                idOfSport = sportId;
            } else {
                idOfSport = -1;
            }
            const indexOfSport = sportsOrder.indexOf(idOfSport);
            if (indexOfSport != -1) {
                sportsOrder = update(sportsOrder, {$splice: [[indexOfSport, 1], [0, 0, idOfSport]]});
            }
        }

        return {...state, selectedLeaguesBySport, outrights, sportsOrder};
    },
    [UNSELECT_LEAGUE_FOR_SPORT]: (state, {payload:{leagueId, sportId}}) => {
        let selectedLeaguesBySport = state.selectedLeaguesBySport;
        let sportsOrder = state.sportsOrder;
        let outrights = state.outrights;
        let sportIdCopy = sportId;
        let groupTypeId = state.groupTypeId;
        const isArray = Array.isArray(leagueId);
        if(isArray){
            const leagues = [].concat(leagueId);
            _each(leagues,(leagueId,idx)=>{
                const isOutright = isOutrightMemoized(leagueId, state.categories);
                sportId = isOutright ? -1 : sportIdCopy;

                if (isOutright) {
                    if (outrights.length == 1) {
                        const indexOfSport = sportsOrder.indexOf(sportId);
                        sportsOrder = update(sportsOrder, {$splice: [[indexOfSport, 1]]});
                    }
                    const indexOfLeague = outrights.indexOf(leagueId);
                    if (indexOfLeague != -1) {
                        outrights = update(outrights, {$splice: [[indexOfLeague, 1]]});
                    }
                } else {
                    const leaguesForSport = _get(selectedLeaguesBySport, [sportId], []);
                    const indexOfLeague = leaguesForSport.indexOf(leagueId);
                    if (indexOfLeague != -1) {
                        selectedLeaguesBySport = update(selectedLeaguesBySport, {[sportId]: {$splice: [[indexOfLeague, 1]]}});
                    }
                    if (leaguesForSport.length == 1) {
                        selectedLeaguesBySport = update(selectedLeaguesBySport, {$unset: [sportId]});
                        const indexOfSport = sportsOrder.indexOf(sportId);
                        sportsOrder = update(sportsOrder, {$splice: [[indexOfSport, 1]]});
                    }
                }
            })
        }else{
            const isOutright = isOutrightMemoized(leagueId, state.categories);
            sportId = isOutright ? -1 : sportId;
    
            if (isOutright) {
                if (outrights.length == 1) {
                    const indexOfSport = sportsOrder.indexOf(sportId);
                    sportsOrder = update(sportsOrder, {$splice: [[indexOfSport, 1]]});
                }
                const indexOfLeague = outrights.indexOf(leagueId);
                if (indexOfLeague != -1) {
                    outrights = update(outrights, {$splice: [[indexOfLeague, 1]]});
                }
            } else {
                const leaguesForSport = _get(state.selectedLeaguesBySport, [sportId], []);
                const indexOfLeague = leaguesForSport.indexOf(leagueId);
    
                if (indexOfLeague != -1) {
                    selectedLeaguesBySport = update(selectedLeaguesBySport, {[sportId]: {$splice: [[indexOfLeague, 1]]}});
                }
                if (leaguesForSport.length == 1) {
                    selectedLeaguesBySport = update(selectedLeaguesBySport, {$unset: [sportId]});
                    const indexOfSport = sportsOrder.indexOf(sportId);
                    sportsOrder = update(sportsOrder, {$splice: [[indexOfSport, 1]]});
                }
            }
        }
        if(Object.keys(selectedLeaguesBySport).length === 0){
            groupTypeId = update(groupTypeId, {$set: null});
        }

        return {...state, selectedLeaguesBySport, sportsOrder, outrights, groupTypeId}
    },
    [FETCH_CATEGORIES]: {
        PENDING: (state, action) => {
            return {...state}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {categories}}) => {
            return {...state, isPending: false, categories};
        }
    },
    [ADD_LEAGUE_BY_ID]: (state, {payload: {leagueId}}) => {
        return {...state, selectedLeagues: [...state.selectedLeagues, leagueId]};
    },
    [REMOVE_LEAGUE_BY_ID]: (state, {payload: {leagueId}}) => {
        return {...state, selectedLeagues: removeLeagueById(state.selectedLeagues, leagueId)};
    },
    [CHANGE_SPORT_ID]: (state, {payload: {prevSortId}}) => {
        return {...state, selectedLeagues: [], prevSortId};
    },
    [SET_CATEGORIES_BY_TIME]: (state, {payload: {time, categories}}) => {
        const categoriesByTime = update(state.categoriesByTime, {[time]: {$set: categories}});
        const selectedLeaguesBySport = update(state.selectedLeaguesBySport, {$set: {}});
        const sportsOrder = update(state.sportsOrder, {$set: []});
        const outrights = update(state.outrights, {$set: []});
        return {
            ...state,
            categoriesByTime,
            selectedLeaguesBySport,
            sportsOrder,
            outrights,
            isFilteredCategoriesRequestPending: false
        };
    },
    [CHANGE_TIME_FILTER]: (state, {payload: {time}}) => {
        return update(state, {
            time: {$set: time},
            sportsOrder: {$set: []},
            outrights: {$set: []},
            selectedLeaguesBySport: {$set: {}}
        });
    },
    ['CHANGE_TIME_FILTER_PENDING']: (state, action) => {
        return {...state, isFilteredCategoriesRequestPending: true}
    },
    ['CHANGE_TIME_FILTER_FAILURE']: (state, {payload: {error}}) => {
        return {...state, isFilteredCategoriesRequestPending: false, error};
    },
    [LOAD_CATEGORIES_FOR_MARKET_GROUP_TYPE_ID]: {
        PENDING: (state, action) => {
            return {...state}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {groupTypeId, categories}}) => {
            return update(state, {
                categoriesByMarketGroupTypeId: {
                    [groupTypeId]: { $set: categories}
                },
                isPending: {$set: false}
            });
        }
    },
    [SET_CATEGORIES_GROUP_ID]: (state, {payload:{groupId}}) => {
        let groupTypeId = state.groupTypeId;
       if(groupTypeId !== groupId){
            groupTypeId = update(groupTypeId, {$set: groupId});
        }
        return {...state, groupTypeId}
    },

}, initialState);
