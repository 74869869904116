import styled, {css} from 'styled-components';
import StyledLoader from 'StyledLoader.js';
import {List} from 'global/styles/styles.js';
const S = {};

S.LeftMenu = styled.div`
    float: left;
    width: 100%;
`;
S.DropdownMenuWrapper = styled.div`
    position: relative;
    float: left;
    width: 100%;
   
    & > ul{
        border-radius: 3px;
        overflow: hidden;
    }

`;

S.Cover = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.5);
        justify-content: center;
    align-items: center;
    display: flex;

    & ${StyledLoader.LoaderWrapper}{

    }
`;
S.ConfigRemover = styled.div`
position:fixed;
bottom:1.66rem;
left:3.33rem;
display:${props=>props.isVisible? "flex":'none'};
justify-content: center;
align-items:center;
min-width:14.08rem;
min-height:4rem;
z-index:999;
background-color:#FF0000;
color:#fff;
@media(min-width:1600px){
    min-width:17.08rem;
}
&:hover{
    cursor:pointer;
}
`
S.Arrow = styled.div`
    height: 100%;
    margin: 0 5px 0 0;
    display: flex;
    align-items: center;
    margin-right: 5px;
    transform: rotate(90deg);
    &:before {
        content: '';
        width:  1.2rem;
        height: .87rem;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        background-color: ${({theme:{$arrowFavouriteLeagues}})=> $arrowFavouriteLeagues};
        mask-size:  1.2rem .87rem;
        -webkit-mask-size:  1.2rem .87rem;
        mask: url(/images/svg/chevron_right_grey.svg) no-repeat 50% 50%;
        -webkit-mask: url(/images/svg/chevron_right_grey.svg) no-repeat 50% 50%;
    }
    &.categories-header-arrow {
        &:before {
            background-color: ${({ theme: { $categoriesHeaderIconColor } }) => $categoriesHeaderIconColor};
        }
    }
`;

S.HeaderIcon = styled.svg`
    width: 15px;
    height: 15px;
    margin-right: 2rem;
    fill: ${({ theme: { $categoriesHeaderIconColor } }) => $categoriesHeaderIconColor};;
`;

S.HeaderText = styled.div`
    &:first-letter{
       text-transform: uppercase;
    }
`;



S.Header = styled.div`
    background-color: ${({ theme: { $categoriesHeaderBgColor } }) => $categoriesHeaderBgColor};
    border-radius: 3px 3px 0 0;
    height: 48px;
    text-transform: uppercase;
    color: ${({ theme: { $categoriesHeaderItemColor } }) => $categoriesHeaderItemColor};
    display: flex;
    padding: 0 11px 0 17px;
    align-items: center;
    cursor: pointer;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        height: 48px;
        width: 3px;
        border-top-left-radius: 3px;
        background-color: ${({ theme: { $categoriesHeaderLeftSidePanelColor } }) => $categoriesHeaderLeftSidePanelColor};
        left: 0;
    }

    & ${S.Arrow} {
        margin-left: auto;
    }

    & ${S.HeaderText}{
        text-transform: uppercase;
        color: ${({ theme: { $categoriesHeaderItemColor } }) => $categoriesHeaderItemColor};
        font-size: 1.16rem;
    }

    ${({ isActive }) => {
        return isActive == true && css`
            /* background-color: ${({ theme: { $favouriteLeaguesHeaderActiveBgColor } }) => $favouriteLeaguesHeaderActiveBgColor}; */

            & ${S.Arrow}{
                transform: rotate(270deg);
            }
        `;
    }};
`;




export default S;