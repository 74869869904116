import React from 'react';
import S from 'StyledRegisterForm.js';
import ModalOpacity from 'ModalOpacity.js';
import useToggle from 'UseToggle.js'
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {translation, pushEventToGoogleAnalytics} from 'helpers/utilsHelper.js';
import {isValueUnique} from 'authActions.js';
import {integrationIncomeAccessAcitveIframe, integrationIncomeAccessGetBtag, integrationBetgeniusCreateScript} from 'integrationActions.js';
import classNames from 'classnames';
import _pick from 'lodash/pick';

// Validation
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';

const validate = values => {
    const errors = validation(values, validators.REGISTER);
    return errors;
};

const asyncValidate = async(values, dispatch, props, field) => {

    if (field) {
        const fieldName = field;
        const fieldValue = values[field];

        try {
            const result = await dispatch(isValueUnique(fieldName, fieldValue));
            return result;
        } catch (err) {
            throw {[fieldName]: [translation(err)]};
        }
    }
};

const renderField = ({
    input,
    label,
    type,
    meta: {touched, error, warning, valid, asyncValidating}
}) => {
    const inputClass = classNames({
        'async-validating': asyncValidating,
        'has-error': (touched && error),
        'is-valid': (touched && valid)
    });
    return (
        <S.Row>
            <S.InputWrapper>
                <S.Input {...input} type={type} required="required" className={inputClass}/>
                <S.Label>{label}</S.Label>
                {touched && ((error && <S.Error>{error[0]}</S.Error>) || (warning && <S.Error>{warning}</S.Error>))}
            </S.InputWrapper>
        </S.Row>
    )
};

const CheckboxWithLabelAndError = ({
    input,
    label,
    type,
    required,
    meta: {touched, error, warning}
}) => {
    return (
        <S.Row>
            <S.InputWrapper className="with-checkbox">
                <S.Input {...input} value={input.value} checked={input.value}
                        onChange={event => input.onChange(event.target.checked)} type={type} required={required}/>
                <S.FakeChbox>
                    {input.value && <S.CheckMark>&#10004;</S.CheckMark>}
                </S.FakeChbox>
                <S.Label>{label}</S.Label>
                {touched && ((error && <S.Error>{translation(error[0])}</S.Error>) || (warning && <S.Error>{warning}</S.Error>))}
            </S.InputWrapper>
        </S.Row>
    );

};

const RegisterForm = ({registerOnSubmit, toggleLoginModal, handleSubmit, pristine, submitting, error}) => {

    const [showSuccessModal, toggleSuccessModal] = useToggle(false);
    const incomeAccessIframeRef = React.createRef();

    const onFormSubmit = async(values) => {

        try {
            const postData = _pick(values, ['password', 'mobile', 'loginName', 'email', 'bonus']);
            const queryParam = app.router.getURLParams();

            postData.countryCode = process.env.MAIN_COUNTRY_CODE;
            postData.currencyCode = process.env.MAIN_CURRENCY_CODE;

            const incomeAccess = process.env.INTEGRATION_INCOME_ACCESS ? JSON.parse(process.env.INTEGRATION_INCOME_ACCESS) : undefined;
            if (incomeAccess && integrationIncomeAccessGetBtag()) {
                const btag = integrationIncomeAccessGetBtag();
                postData.clickId = btag;
                postData.affiliateId = btag;
            }

            const customerId = await registerOnSubmit(postData);
            toggleSuccessModal();
            if (queryParam.registeredFromAd) {
                pushEventToGoogleAnalytics('registered-from-ad', customerId.value);
            }

            if (incomeAccess && integrationIncomeAccessGetBtag()) {
                integrationIncomeAccessAcitveIframe(customerId, incomeAccessIframeRef);
            }

            if(process.env.BETGENIUS_TAGS_INTEGRATION_SCRIPT == 'true'){
                integrationBetgeniusCreateScript('regconfirm', {AccountID:customerId.value});
            }
            
        } catch (err) {
            throw new SubmissionError({_error: err});
        }
    };

    return (
        <S.RegisterForm method="post" onSubmit={handleSubmit(onFormSubmit)}>

        <ModalOpacity isOpen={showSuccessModal} toggleOpen={toggleSuccessModal} showHeaderIcon={false}
                          showHeaderTitle={false}>
                <S.SuccessModal>
                    <S.SuccessModalMessage>{translation('modal_successInfo')}</S.SuccessModalMessage>
                    <S.LoginBtn onClick={toggleLoginModal}>{translation('modal_btnLogin')}</S.LoginBtn>
                    <S.HideIframe ref={incomeAccessIframeRef}></S.HideIframe>
                </S.SuccessModal>
            </ModalOpacity>

            <Field
                name="loginName"
                type="text"
                component={renderField}
                label="Username"
            />
            <Field
                name="password"
                type="password"
                component={renderField}
                label="Password"
            />
            <Field
                name="confirmPassword"
                type="password"
                component={renderField}
                label="Confirm password"
            />
            <Field
                name="email"
                type="text"
                component={renderField}
                label="E-mail"
            />
            <Field
                name="mobile"
                type="text"
                component={renderField}
                label="Mobile"
            />
            <Field
                name="bonus"
                type="checkbox"
                component={CheckboxWithLabelAndError}
                label={translation('account_registerForm_acceptBonus')}
                required={false}
            />
            <Field
                name="acceptRules"
                type="checkbox"
                component={CheckboxWithLabelAndError}
                label={translation('account_registerForm_chbox2')}
                required={true}
            />

            {error && <S.SubmissionError className="submission-error">{error}</S.SubmissionError>}

            <S.SubmitBtn type="submit" disabled={pristine || submitting}>{translation('account_registerForm_signup')}</S.SubmitBtn>

        </S.RegisterForm>
    );
};

export default reduxForm({
    form: 'register',
    validate,
    asyncValidate,
    asyncBlurFields: ['loginName', 'email', 'mobile']
})(RegisterForm);