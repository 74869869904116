import React from 'react';
import {NavLink} from 'react-router-dom';
import S from 'StyledAccountMenu.js';
import _map from 'lodash/map';
import accountMenuItems from 'accountMenuItems.enum.js';
import {translation} from 'helpers/utilsHelper';

const AccountMenu = ({freebetCount}) => {
    return (
        <S.AccountMenu className="account-menu">
            {_map(accountMenuItems, ({translationKey, href, id, ...rest}, itemName) => {
                return (
                    <S.AccountMenuItem key={itemName} className="account-menu-item">
                        <S.AccountMenuLink id={id ? id : ''} {...rest} as={NavLink} to={href} exact
                                           activeClassName="active">
                            {translation(translationKey)}
                            {(itemName=='FREEBETS') && <S.FreebetCount>{freebetCount}</S.FreebetCount>}
                            </S.AccountMenuLink>
                    </S.AccountMenuItem>
                )
            })}
        </S.AccountMenu>
    );
};

export default AccountMenu;
