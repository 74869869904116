import styled from 'styled-components';
import {List, Button} from 'global/styles/styles.js';
const S = {};

S.BetHistoryItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

S.BetHistoryItem = styled.div`
    display: flex;
    & > div{
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 1rem;
            text-align: left;
            padding: .67rem;
            min-height: 3.33rem;
            border-right: 1px solid #fff;
            border-bottom: 1px solid #fff;
            color: #000;
            align-items: center;
           
           &.transaction-amount-cashout{
                flex: 1 1 0;
           }
            
        &.transaction-id{
            flex: 1 1 0;
        }
        
        &.transaction-date-cashout,
        &.transaction-date{
            flex: 1.5 1 0;
        }
        
        &.transaction-stake-cashout,
        &.transaction-stake{
            flex: 1 1 0;
        }
        &.transaction-win{
            flex: 1 1 0;
        }
        &.transaction-confirmed-win{
            flex: 1 1 0;
        }
        &.transaction-bonus{
            flex: 1 1 0;
        }
        &.transaction-type{
            flex: 1 1 0;
        }
        &.transaction-cashout{
            flex: 1 1 0;
            display: flex;
            align-items: center;
            justify-content: center;
            
            & > button{
                background: #166931;
                text-transform: uppercase;
                border-radius: .33rem;
                border: 0;
                color: #fff;
                cursor: pointer;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-top: 0;
                background-color: #00a651;
                font-weight: 400;
                font-size: .85rem;
                padding: .15rem 1rem;
                height: 1.8rem;
            }
        }
        &.transaction-rebet{
            flex: 1 1 0;
            display: flex;
            align-items: center;
            justify-content: center;
        
            & button{
                vertical-align: middle;
                color: #fff;
                background-image: url(/images/svg/rebet.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: 2.25rem;
                padding: .15rem 1.75rem;
                margin-top: 0;
                background-color: #00a651;
                font-weight: 400;
                font-size: .85rem;
                height: 1.8rem;
                border: none;
                border-radius: 4px;
            }
        
        }
        &.transaction-status{
            flex: 1 1 0;
            border-right: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #fff;
        
              &.default {
              }
    
              &.pending_acceptation {
              }
    
              &.won {
                background-color: #6CD700;
                font-weight: bold;
              }
    
              &.cashout {
                background-color: $lightGreenMain;
              }
    
              &.cancel {
                background-color: #e5a332;
              }
    
              &.cashback {
              }
    
              &.lost {
                background-color: #FF4545;
              }
    
              &.placed {
                background-color: #00CFFF;
              }
    
              &.safebet {
              }
              
              & button{
                    border: none;
                    font-weight: 700;
                    vertical-align: middle;
                    display: block;
                    width: 25%;
                    background: 0 0;
                    color: #fff;
                    padding: 0;
                    height: auto;
                    margin-top: 0;
                    font-size: 1.25rem;
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    
                    & > span{
                        font-size: 12px;
                        font-weight: 400;
                    }
                    
              }
        
        }
        
    }
`;

S.ErrorModal = styled.div`
     width: 100%;
`;

S.ErrorModalTitle = styled.h4`
    color: red;
    text-transform: uppercase;
    font-size: 1.83rem;
    padding: 0;
    font-weight: 800;
    font-style: italic;
    float: none!important;
    text-align: center;
    margin-bottom: 3.42rem;
`;

S.ErrorModalMessage = styled.p`
    text-align: center;
    color: #000;
    margin: 0;
    font-size: 1.2rem;
    letter-spacing: .2px;
    font-weight: 400;
`;

export default S;