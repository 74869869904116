import styled from 'styled-components';
import StyledDatepicker from 'StyledDatepicker.js';
import StyledModal from 'StyledModal.js';
import {List, Button} from 'global/styles/styles.js';
const S = {};

S.AccountBetHistory = styled.div`
    width: 100%;
    padding: .8333rem 0 0;
    padding-left: 30px;
    padding-right: 30px;
`;
S.BetHistoryFilters = styled.div`
    display: flex;
`;
S.BetHistoryFiltersDates = styled.div`
    display: flex;
    & > div{
         margin-right: 10px;
         &:last-of-type{
             margin-right: 0;
         }
    }
`;
S.DateWrapper = styled.div`
    width: 237px;
    height: 46px;
    display: flex;
    position: relative;
    border-top: 2px solid #eee;
    border-bottom: 2px solid #eee;
    border-right: 2px solid #eee;
    & .react-datepicker-wrapper,
    & .react-datepicker__input-container{
       display: flex;
    }
    & .react-datepicker-wrapper{
       width: calc(100% - 4.5rem);
    }
    & .react-datepicker__input-container{
       width: 100%;
    }
    
    ${StyledDatepicker.Datepicker}{
        flex: 1;
        padding-left: 10px;
        border: none;
        font-size: 17px;
        font-weight: 700;
        width: 100%;
    }
`;
S.DateLabel = styled.div`
    display: flex;
    background-color: #eee;
    font-weight: 700;
    color: #676767;
    width: 4.5rem;
    justify-content: center;
    align-items: center;
`;
S.DateIcon = styled.i`
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 33px;
    font-size: 1.67rem;
    justify-content: center;
    align-items: center;
    display: flex !important;
    pointer-events: none;
`;

S.BetHistoryFiltersBtns = styled.div`
    display: flex;
    margin-left: 10px;
    & > button{
        margin-right: 15px;
        &:last-of-type{
            margin-right: 0;
        }
    }
`;
S.FilterBtn = styled(Button)`
    margin-top: 0;
    background-color: #eee;
    color: #bcbcbc;
    font-weight: 400;
    padding-left: 1rem;
    padding-right: 1rem;
    width: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 4px;
    display: block;
    justify-content: center;
    align-items: center;
    position: relative;
    white-space: nowrap;
    padding-top: 0;
    text-decoration: none;
   
    &.active{
            color: #676767;
            font-weight: 700;
            text-overflow: ellipsis;
            overflow: hidden;
           
           &:after{
            content: '';
            position: absolute;
            background-color: #676767;
            height: .25rem;
            bottom: 6px;
            left: 1rem;
            right: 1rem;
           } 
    }
`;

S.BonusListHeader= styled.div`
    background-color: #a3a3a3;
    color: #fff;
    padding: 1rem;
    font-weight: 500;
    border-bottom: .1rem solid #fff;
    font-size: 1.5rem;
    font-size: 1.5rem;
`;


S.BonusList = styled.div``;


S.BetHistoryTransactionsList = styled.div`
    margin-top: 10px;
    background-color: #f3f3f3;
`;

S.NoResult = styled.div`
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

S.BetHistoryTransactionsListItem = styled.div`
display: flex;
&>div {
	padding: 1.2rem .67rem;
	border: none;
	font-size: 1rem;
	color: #fff;
	font-weight: 700;
	text-align: left;
	text-transform: uppercase;
	border-bottom: .08rem solid #fff;
	border-right: .08rem solid #fff;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: 500;
	color: #000;
	font-size: 1rem;
	padding: 1.2rem .67rem;
	border-bottom: .08rem solid #fff;
	border-right: .08rem solid #fff;
	&.wallet-status {
		width: 13.2995706264%;
		padding-left: 2.7523945833%;
		display: flex;
		align-items: center;
		&.red{
		    & .status-icon{
		        background-image: url(/images/svg/status_red.svg);
		    }
		}
		&.green{
		    & .status-icon{
		      background-image: url(/images/svg/status_green.svg);
		    }
		}
		
		& .status-icon{
		    margin-left: 1.17rem;
            width: 1.3819275738rem;
            height: 1.3819275738rem;
            background-color: transparent;
            background-position: 0 0;
            background-size: cover;
		}
	}
	&.wallet-type {
		width: 20.1805570847%;
	}
	&.wallet-info {
		width: 20.1805570847%;
	}
	&.wallet-amount {
		width: 15.6005724981%;
		&.green{}
		&.red{}
	}
	&.wallet-date {
		width: 16.9767697897%;
		display: flex;
		justify-content: space-between;
	}
	&.wallet-id {
		flex: 1;
	}
}
`;

S.BetHistoryTransactions = styled.div`
`;

S.FakeTableHead = styled.div`
    background-color: #a3a3a3;
    display: flex;
    & > div{
        padding: 1.2rem .67rem;
        border: none;
        font-size: 1rem;
        color: #fff;
        font-weight: 700;
        text-align: left;
        text-transform: uppercase;
        border-bottom: .08rem solid #fff;
        border-right: .08rem solid #fff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        
        &.transaction-id{
                flex: 1 1 0;
        }
         &.transaction-date-cashout,
        &.transaction-date{
              flex: 1.5 1 0;
         }
         &.transaction-stake-cashout,
         &.transaction-stake{
               flex: 1 1 0;
           }
         &.transaction-win{
                  flex: 1 1 0;
           }
         &.transaction-confirmed-win{
                  flex: 1 1 0;
           }
           &.transaction-bonus{
               flex: 1 1 0;
            }
           &.transaction-typ{
              flex: 1 1 0;
           }
           &.transaction-cashout{
               flex: 1 1 0;
                display: flex;
                align-items: center;
                justify-content: left;
           }
           &.transaction-status{
               flex: 1 1 0;
               border-right: none;
           }
           &.transaction-rebet{
               flex: 1 1 0;
                display: flex;
                align-items: center;
                justify-content: center;
           }
    }
`;

S.Button = styled(Button)`
    display: block;
    line-height: 2rem;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    height: 100%;
    border: 0;
    box-sizing: border-box;
    width: 100%;
    margin: 5px 0;
    &.reject{
        background-color: #bebebe;
    }
    &.accept{
        background-color: #00a651;
    }
`;

S.BtnReset = styled(Button)`
    margin-top: .465rem;
    height: 2.4rem;
    background-color: #2d9ef5;
    font-size: 1rem;
    font-weight: 700;
    vertical-align: middle;
    color: #fff;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-left: auto;
    border-radius: 4px;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
        
    & > span{
        font-size: 1rem;
        font-weight: 700;
        color: #fff;
    }
`;

S.BtnRefresh = styled(S.BtnReset)`
    margin-left: 10px;
`;

export default S;