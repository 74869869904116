import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import S from 'StyledAccountWallet.js';
import Loader from 'Loader.js';
import {translation, formatMoney} from 'helpers/utilsHelper.js';
import {formatDate} from 'helpers/datesHelper.js';
import {changeDateByType, changeActiveTab, fetchTransactionsByFilter, setCurrentPage} from 'accountWalletActions.js';
import classNames from 'classnames';
import _map from 'lodash/map';
import _size from 'lodash/size';
import Datepicker from 'Datepicker.js';
import Pagination from 'Pagination.js';

class AccountWallet extends Component {

    componentDidMount = async() => {
        const {fetchTransactionsByFilter} = this.props;
        try {
            const data = await fetchTransactionsByFilter();
        } catch (error) {
            console.log('fetchTransactionsByFilter:' + error);
        }
    };

    handleChangeStart = (date) => {
        const {changeDateByType, fetchTransactionsByFilter, endDate, activeTab, currentPage, limit} = this.props;
        const startDate = new Date(date);
        const params = {
            activeTab,
            endDate,
            startDate,
            currentPage,
            limit
        };

        changeDateByType('startDate', startDate);
        fetchTransactionsByFilter(params);
    };

    handleChangeEnd = (date) => {
        const {changeDateByType, fetchTransactionsByFilter, startDate, activeTab, currentPage, limit} = this.props;
        const endDate = new Date(date);
        const params = {
            activeTab,
            endDate,
            startDate,
            currentPage,
            limit
        };

        changeDateByType('endDate', endDate);
        fetchTransactionsByFilter(params);
    };

    onChangeFilter = (activeTab) => {
        const {changeActiveTab, fetchTransactionsByFilter, startDate, endDate, currentPage, limit} = this.props;
        const params = {
            activeTab,
            endDate,
            startDate,
            currentPage,
            limit
        };

        changeActiveTab(activeTab);
        fetchTransactionsByFilter(params);
    };

    getTransactionStatusClass = (statusCode) => {

        const status = statusCode.toUpperCase();
        switch (status) {
            case 'DEC' :
                return 'red';
            case 'FLD' :
                return 'red';
            case 'SCS' :
                return 'green';
            case 'IPR' :
                return 'gray';
            default:
                return '';
        }
    };

    parseStatus = (status) => {
        return 'transfer_statusList.' + status;
    };

    parseType = (type) => {
        return 'transferTypeList.' + type;
    };

    goPage = async(currentPage) => {
        const {fetchTransactionsByFilter, startDate, endDate, limit, activeTab, setCurrentPage} = this.props;
        const params = {
            activeTab,
            endDate,
            startDate,
            currentPage,
            limit
        };
        try {
            await fetchTransactionsByFilter(params);
            setCurrentPage(currentPage);
        } catch (error) {
            console.log('goPage:'+error);
        }
    };

    render() {
        const {isPending, error, startDate, endDate, activeTab, data, currentPage, limit} = this.props;

        return (
            <S.AccountWallet className="account-wallet">
                <S.WalletFilters className="wallet-filters">

                    <S.WalletFiltersDates className="wallet-filters-dates">

                        <S.DateWrapper className="date-wrapper">
                            <S.DateLabel>{translation('common_dateFrom')}</S.DateLabel>
                            <Datepicker
                                selected={startDate}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="yyyy-MM-dd"
                                cb={this.handleChangeStart}
                            />
                            <S.DateIcon className="ion ion-ios-calendar-outline"></S.DateIcon>
                        </S.DateWrapper>

                        <S.DateWrapper className="date-wrapper">
                            <S.DateLabel>{translation('common_dateTo')}</S.DateLabel>
                            <Datepicker
                                selected={endDate}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="yyyy-MM-dd"
                                cb={this.handleChangeEnd}
                            />
                            <S.DateIcon className="ion ion-ios-calendar-outline"></S.DateIcon>
                        </S.DateWrapper>

                    </S.WalletFiltersDates>

                    <S.WalletFiltersBtns className="wallet-btns-wrapper">
                        <S.FilterBtn
                            onClick={this.onChangeFilter.bind(this, 'all')}
                            className={classNames({active: (activeTab == 'all')})}>{translation('account_wallet_types_all')}</S.FilterBtn>
                        <S.FilterBtn
                            onClick={this.onChangeFilter.bind(this, 'deposit')}
                            className={classNames({active: (activeTab == 'deposit')})}>{translation('account_wallet_types_deposits')}</S.FilterBtn>
                        <S.FilterBtn
                            onClick={this.onChangeFilter.bind(this, 'payout')}
                            className={classNames({active: (activeTab == 'payout')})}>{translation('account_wallet_types_payouts')}</S.FilterBtn>
                    </S.WalletFiltersBtns>

                </S.WalletFilters>

                <S.WalletTransactions className="wallet-transaction-list">
                    {isPending ?
                        <Loader/>
                        :
                        (
                            <>
                            <S.WalletTransactionsList className="transaction-list">

                                <S.FakeTableHead>
                                    <div className="wallet-status">{translation('account_wallet_transfers_status')}</div>
                                    <div className="wallet-type">{translation('account_wallet_transfers_type')}</div>
                                    <div className="wallet-info">{translation('account_wallet_transfers_info')}</div>
                                    <div className="wallet-amount">{translation('account_wallet_transfers_amount')}</div>
                                    <div className="wallet-date">{translation('account_wallet_transfers_date')}</div>
                                    <div className="wallet-id">{translation('common_id')}</div>
                                </S.FakeTableHead>

                                {_map(data, ({amount, regDate, type, statusCode, transactionId}) => {
                                    return (
                                        <S.WalletTransactionsListItem className="transaction-list-item"
                                                                      key={transactionId}>

                                            <div
                                                className={`wallet-status ${this.getTransactionStatusClass(statusCode)}`}>
                                                <div className="status-icon"></div>
                                            </div>
                                            <div className="wallet-type">{translation(this.parseType(type))}</div>
                                            <div
                                                className="wallet-info">{translation(this.parseStatus(statusCode))}</div>
                                            <div
                                                className={`wallet-amount ${amount > 0 ? 'green' : 'red'}`}>{formatMoney(amount)}</div>
                                            <div className="wallet-date">
                                                <span>{formatDate(regDate, 'dd-MM-yyyy')}</span>
                                                <span>{formatDate(regDate, 'HH:mm')}</span>
                                            </div>
                                            <div className="wallet-id">{transactionId}</div>

                                        </S.WalletTransactionsListItem>
                                    )
                                })}

                                {!_size(data) && (<S.NoResult className="no-result">{translation('account_wallet_transfers_notFound')}</S.NoResult>)}

                            </S.WalletTransactionsList>

                            {_size(data)>0 && (<Pagination currentPage={currentPage} limit={limit} data={data} cb={this.goPage}/>)}

                            </>
                        )
                    }
                </S.WalletTransactions>

            </S.AccountWallet>
        );
    }
}

const mapStateToProps = ({AccountWallet:{isPending, error, startDate, endDate, activeTab, data, currentPage, limit}}, props) => {
    return {
        error,
        isPending,
        startDate,
        endDate,
        activeTab,
        data,
        currentPage,
        limit
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeActiveTab: bindActionCreators(changeActiveTab, dispatch),
        changeDateByType: bindActionCreators(changeDateByType, dispatch),
        fetchTransactionsByFilter: bindActionCreators(fetchTransactionsByFilter, dispatch),
        setCurrentPage: bindActionCreators(setCurrentPage, dispatch),
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountWallet);
