import React, { PureComponent } from 'react';
import _head from 'lodash/head';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _difference from 'lodash/difference';
import _without from 'lodash/without';
import _size from 'lodash/size';
import _each from 'lodash/each';
import memoize from 'fast-memoize';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { /* translation, */ pushCustomEventToGoogleAnalytics } from 'helpers/utilsHelper';
import S from 'StyledDropdownMenu.js';
import ShowAllIcon from 'show_all.svg';
import ShowAllIconGrey from 'show_all_grey.svg';
import FavouriteIcon from 'favourite_icon.svg';

class DropdownMenu extends PureComponent {
  constructor(props) {
    super(props);

    const menuItems = [];
    props.config.forEach((item) => {
      if (item.children) {
        const id = item.categoryId;
        const isOpen = false;
        menuItems.push({ id, isOpen });
      }
    });
    this.state = { menuItems };
    this.isOutrightMemoized = memoize(this.isOutright);
  }

  toggleDropdown = (e, id) => {
    e.preventDefault();

    const indexOfItem = this.findIndexOfItem(id);
    const item = _head(this.state.menuItems.slice(indexOfItem, indexOfItem + 1));

    this.updateCategoryItem(indexOfItem, item);
  };

  updateCategoryItem = (indexOfItem, item) => {
    this.setState((prevState) => {
      const itemsBeforeIndex = prevState.menuItems.slice(0, indexOfItem);
      const itemsAfterIndex = prevState.menuItems.slice(indexOfItem + 1);
      const nextState = {
        ...prevState,
        menuItems: [...itemsBeforeIndex, { ...item, isOpen: !item.isOpen }, ...itemsAfterIndex],
      };
      return nextState;
    });
  };

  addCategoryItem = (id, isOpen = false) => {
    this.setState((prevState) => {
      return { ...prevState, menuItems: [...prevState.menuItems, { id, isOpen }] };
    });
  };

  findIndexOfItem = (searchId) => {
    return this.state.menuItems.findIndex(({ id }) => id == searchId);
  };

  findItemByCategoryId = (searchId) => {
    return this.state.menuItems.find(({ id }) => id == searchId);
  };
  isChecked = (leagueId) => {
    const { selectedLeaguesBySport } = this.props;
    return selectedLeaguesBySport.indexOf(leagueId) != -1;
  };

  getMenuItem = (menuItem, idx) => {
    const { categoryName: title, categoryId, sportId, parentCategory, level, eventsCount, children } = menuItem;
    const { selectedLeaguesBySport, outrights } = this.props;

    if (children?.length > 0) {
      const { currentTheme } = this.props;
      
      const itemInState = this.findItemByCategoryId(categoryId);
      const { isOpen = false } = itemInState || {};

      const itemClass = classNames({ 'is-active': isOpen, 'has-submenu': true, 'dropdown-menu-item': true });

      const showAllIconSVG = currentTheme === 'light' ? ShowAllIconGrey : ShowAllIcon;

      const shouldMapByCategories =
        level === 1
          ? selectedLeaguesBySport.hasOwnProperty(categoryId) || outrights.length > 0
          : selectedLeaguesBySport.hasOwnProperty(parentCategory) || outrights.length > 0;

      const isSelectedLeaguesBySportUndefined =
        selectedLeaguesBySport[level === 1 ? categoryId : parentCategory] === undefined
          ? []
          : selectedLeaguesBySport[level === 1 ? categoryId : parentCategory];

      const shouldLvl2BeActive =
        shouldMapByCategories && level === 2
          ? children.every((children) => {
              return isSelectedLeaguesBySportUndefined.concat(outrights).includes(children.categoryId);
            })
          : false;

      const shouldLvl1BeActive = children.every(
        (children) =>
          shouldMapByCategories &&
          level === 1 &&
          children.children?.every((item) => isSelectedLeaguesBySportUndefined.concat(outrights).includes(item.categoryId))
      );

      const categoryIdForAllLeagues = level === 1 ? categoryId : parentCategory;

      const arrayForAllLeagues = level === 1 ? children : [menuItem];

      return (
        <S.DropdownMenuItem key={categoryId} className={itemClass} data-level={level} data-parent-id={parentCategory} data-category-id={categoryId}>
          {/* Currently commented, I don't know if client wants it
            { (level == 2 && idx == 0) && (
              <S.ShowAll className="show-all" onClick={this.showAllLeagues.bind(this, parentCategory)}>
                  <S.ShowAllIcon className="show-all-icon" dangerouslySetInnerHTML={{__html: showAllIconSVG}}/>
                  <S.ShowAllTxt>{translation('common_showAll')}</S.ShowAllTxt>
                  <S.EventsCount className="events-count">{parentEventsCount}</S.EventsCount>
                  <S.ArrowDown className="arrow-down"></S.ArrowDown>
              </S.ShowAll>
            )} 
          */}

          <S.DropdownMenuItemInner className="dropdown-menu-item-inner" onClick={(e) => this.toggleDropdown(e, categoryId)}>
            <S.FakeChbox
              className={shouldLvl1BeActive ? 'is-active' : shouldLvl2BeActive ? 'is-active' : null}
              onClick={this.showAllLeagues.bind(this, categoryIdForAllLeagues, arrayForAllLeagues)}
            />

            {level === 1 && <S.Icon src={app.config.getStaticImageForCategories(categoryId)} onError={(e) => app.config.handleIncorrectImages(e)} />}
            {level === 2 && (
              <S.FlagIcon
                className={`flag-icon`}
                src={app.config.getStaticImageForCategories(categoryId)}
                onError={(e) => app.config.handleIncorrectImages(e)}
              />
            )}

            <S.Title className="title">{title}</S.Title>

            {(level === 1 || level === 2) && <S.EventsCount className="events-count">{eventsCount}</S.EventsCount>}

            {level === 1 && <S.ArrowDown className="arrow-down" />}
            {level === 2 && <S.ArrowDown className="arrow-down" />}
          </S.DropdownMenuItemInner>

          <CSSTransition in={isOpen} timeout={1000} className="slide-transition" classNames="slide-transition" unmountOnExit>
            <DropdownMenu {...this.props} config={menuItem.children} submenu={true} />
          </CSSTransition>
        </S.DropdownMenuItem>
      );
    } else if (!children?.length && level ===3) {
      const { toggleFavouriteForLeague, isLogged, favouriteLeagues } = this.props;
      const params = [categoryId, sportId];
      const isSelected = this.isSelected(...params);
      const isFavourite = !!_find(favouriteLeagues, { categoryId });
      const isChecked = this.isSelected(...params);
      const chboxClass = classNames({ 'is-active': isChecked, 'fake-chbox': true });
      return (
        <S.DropdownMenuItem key={categoryId} className="dropdown-menu-item" data-level={level} data-parent-id={parentCategory} data-category-id={categoryId}>
          <S.DropdownMenuItemInner className="dropdown-menu-item-inner">
            <S.FakeChbox className={chboxClass} onClick={(e) => this.toggleSelectedLeague(e, ...params)}/>
            <S.Title onClick={(e) => this.toggleSelectedLeague(e, ...params)} className="title" isSelected={isSelected}>
              {title}
            </S.Title>

            {isLogged && (
              <S.FavouriteIcon
                dangerouslySetInnerHTML={{ __html: FavouriteIcon }}
                onClick={toggleFavouriteForLeague.bind(null, categoryId, !isFavourite)}
                isFavourite={isFavourite}
              />
            )}

            <S.EventsCount className="events-count">{eventsCount}</S.EventsCount>
            <S.Arrow className="arrow" />
          </S.DropdownMenuItemInner>
        </S.DropdownMenuItem>
      );
    }
  };

  isSelected = (leagueId, sportId) => {
    const { selectedLeaguesBySport, outrights, orgCategories } = this.props;
    const isOutright = this.isOutrightMemoized(leagueId, orgCategories);
    if (isOutright) {
      return outrights.indexOf(leagueId) != -1;
    } else {
      const leaguesForSport = _get(selectedLeaguesBySport, [sportId], []);
      return leaguesForSport.indexOf(leagueId) != -1;
    }
  };

  isOutright = (categoryId, categories) => {
    const league = _find(categories, { categoryId });
    return _get(league, 'treatAsSport') == -500;
  };

  showAllLeagues = (sportId, array, e) => {
    e.stopPropagation();
    const { config, toggleSelectedLeague } = this.props;
    const allLeaguesIds = _reduce(
      array,
      (initialArray, { children }) => {
        const categoriesIds = _map(children, 'categoryId');
        return initialArray.concat(categoriesIds);
      },
      []
    );
    // Temporary event
    if (app.config.dataLayerEvents.indexOf('showAllClicked') > -1) {
      pushCustomEventToGoogleAnalytics({
        event: 'showAllClicked',
        sportId: sportId,
      });
    }
    toggleSelectedLeague(allLeaguesIds, sportId);
    this.redirectToEventList(allLeaguesIds, sportId);
  };

  toggleSelectedLeague = (e, leagueId, sportId) => {
    e.preventDefault();
    const { toggleSelectedLeague } = this.props;
    toggleSelectedLeague(leagueId, sportId);
    this.redirectToEventList(leagueId, sportId);
  };

  redirectToEventList = (leagueId, sportId) => {
    const { time, selectedLeaguesBySport, sportsOrder, orgCategories, outrights } = this.props;
    let selectedLeaguesBySportCopy = { ...selectedLeaguesBySport };
    let sportsOrderCopy = [...sportsOrder];
    let outrightsCopy = [...outrights];
    let hasNewLeagues = false;
    const isArray = Array.isArray(leagueId);
    const leagues = [].concat(leagueId);
    _each(leagues, (leagueId) => {
      const isOutright = this.isOutrightMemoized(leagueId, orgCategories);
      if (isOutright) {
        let idOfSport = -1;
        const indexOfLeague = outrights.indexOf(leagueId);

        if (indexOfLeague != -1) {
          if (outrightsCopy.length == 1) {
            const indexOfSportId = sportsOrderCopy.indexOf(idOfSport);
            sportsOrderCopy.splice(indexOfSportId, 1);
          }
          outrightsCopy = _without(outrightsCopy, ...[].concat(leagueId));
        } else {
          const leagues = [].concat(leagueId);
          const newLeaguesIds = _difference(leagues, outrightsCopy);
          if (newLeaguesIds.length) {
            if (isArray) {
              outrightsCopy.push(...newLeaguesIds);
            } else {
              outrightsCopy.unshift(...newLeaguesIds);
            }

            const indexOfSportId = sportsOrderCopy.indexOf(idOfSport);
            if (isArray) {
              if (indexOfSportId == -1) {
                sportsOrderCopy.unshift(idOfSport);
              }
            } else {
              if (indexOfSportId != -1) {
                sportsOrderCopy.splice(indexOfSportId, 1);
              }
              sportsOrderCopy.unshift(idOfSport);
            }
          }
        }
      } else {
        const leaguesForSport = _get(selectedLeaguesBySportCopy, [sportId], []);
        const indexOfLeague = leaguesForSport.indexOf(leagueId);
        if (indexOfLeague != -1) {
          const leaguesForSportSliced = _without(leaguesForSport, ...[].concat(leagueId));
          _set(selectedLeaguesBySportCopy, [sportId], leaguesForSportSliced);

          if (leaguesForSport.length == 1) {
            const indexOfSportId = sportsOrderCopy.indexOf(sportId);
            sportsOrderCopy.splice(indexOfSportId, 1);
          }
        } else {
          const leagues = [].concat(leagueId);
          const newLeaguesIds = _difference(leagues, leaguesForSport);
          if (newLeaguesIds.length) {
            hasNewLeagues = true;
            if (isArray) {
              leaguesForSport.push(...newLeaguesIds);
            } else {
              leaguesForSport.unshift(...newLeaguesIds);
            }
            _set(selectedLeaguesBySportCopy, [sportId], leaguesForSport);

            const indexOfSportId = sportsOrderCopy.indexOf(sportId);
            if (isArray) {
              if (indexOfSportId == -1) {
                sportsOrderCopy.unshift(sportId);
              }
            } else {
              if (indexOfSportId != -1) {
                sportsOrderCopy.splice(indexOfSportId, 1);
              }
              sportsOrderCopy.unshift(sportId);
            }
          }
        }
      }
    });

    if (isArray) {
      let idOfSport = null;
      if (hasNewLeagues) {
        idOfSport = sportId;
      } else {
        idOfSport = -1;
      }
      const indexOfSportId = sportsOrderCopy.indexOf(idOfSport);
      if (indexOfSportId != -1) {
        sportsOrderCopy.splice(indexOfSportId, 1);
      }
      sportsOrderCopy.unshift(idOfSport);
    }

    let allLeaguesFromSports = _reduce(
      sportsOrderCopy,
      (initialArray, sportId, index, orgArray) => {
        let leaguesForSport = null;
        if (sportId == -1) {
          leaguesForSport = outrightsCopy;
        } else {
          leaguesForSport = _get(selectedLeaguesBySportCopy, [sportId], []);
        }
        return initialArray.concat(leaguesForSport);
      },
      []
    );

    let sportsName = _reduce(
      sportsOrderCopy,
      (initialArray, sportId, index, orgArray) => {
        let sportName = null;
        if (sportId == -1) {
          sportName = 'Outrights';
        } else {
          const sportCategory = _find(orgCategories, { sportId });
          sportName = _get(sportCategory, 'sportName');
        }
        sportName = sportName.replace(/\s+/g, '-');
        return initialArray.concat(sportName);
      },
      []
    );

    const params = {};
    params['categories'] = allLeaguesFromSports.join(',');
    params['selectedSports'] = sportsName.join(',');

    const urlParams = {};
    if (time) {
      urlParams['filterEventsByTime'] = time;
    }
    if (_size(params['categories']) && _size(params['selectedSports'])) {
      app.router.redirect('/sports/events/:selectedSports/:categories/', params, urlParams);
    } else {
      app.router.redirect('/');
    }
  };

  render() {
    const { config } = this.props;
    const options = [];
    config.map((item, idx) => {
      options.push(this.getMenuItem(item, idx));
    });

    if (this.props.submenu && this.props.submenu === true) {
      return <S.DropdownSubmenuList className="dropdown-submenu">{options}</S.DropdownSubmenuList>;
    }

    return <S.DropdownMenuList className="dropdown-menu">{options}</S.DropdownMenuList>;
  }
}

export default DropdownMenu;
