import styled from 'styled-components';
const S = {};

S.MinatureProviderComponent = styled.div`
    display: flex;
    background-color: #eaeaea;
    margin-bottom: 2rem;
    align-items: center;
    box-shadow: 0 0 3px #000;
    cursor: pointer;
    min-width: 50%;
    width: 100%;

    div:first-child {
        padding: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center

        img {
            width: 10rem;
            height: fit-content;
    }

    div {
        display: flex;
        flex-direction: column;

        img {
            width: 10rem;
            height: fit-content;
        }
    }
`;

S.Item = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    & > div {
        width: 100%;
        display: flex;
    }
`;

S.PaymentsList = styled.div`
    display: flex;
    flex-direction: row;
    min-width: 50%;
    max-width: 50%;

    .loader {
        position: absolute; 
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto; 
        width: 100px; 
    }
`;

export default S;
