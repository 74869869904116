import styled from 'styled-components';
const S = {};

S.AccountDeposit = styled.div`
    width: 100%;
    padding: 0.8333rem 0 0 2.5rem;
    display: flex;
`;

S.NoActiveProviders = styled.div`
    text-align: center;
    font-size: 1.5rem;
    margin: 2rem;
    
`;


export default S;