
export const LOAD_GAME_TYPE_GROUPS_FOR_SPORT = 'LOAD_GAME_TYPE_GROUPS_FOR_SPORT';

//action creators

const getGameTypeGroupsForSport = (sportIds) => {

    return async(dispatch, getState, {MarketApi}) => {
        dispatch(loadGameTypeGroupsForSportPending());
        try {
            const {code, data} = await MarketApi.getGameTypeGroupsForSport(sportIds);
            if(code != 200){
                throw {message:`data error`};
            }
            
            dispatch(loadGameTypeGroupsForSportSuccess(data));

            return data;
        } catch(error){
            dispatch(loadGameTypeGroupsForSportFailure(data));
            throw error;
        }
    }

};

const loadGameTypeGroupsForSportPending = () => {
    return {
        type: `${LOAD_GAME_TYPE_GROUPS_FOR_SPORT}_PENDING`
    }
};

const loadGameTypeGroupsForSportSuccess = (marketGroupTypes) => {
    return {
        type: `${LOAD_GAME_TYPE_GROUPS_FOR_SPORT}_SUCCESS`,
        payload: {marketGroupTypes}
    }
};

const loadGameTypeGroupsForSportFailure = () => {
    return {
        type: `${LOAD_GAME_TYPE_GROUPS_FOR_SPORT}_FAILURE`
    }
};

export {
    getGameTypeGroupsForSport
}