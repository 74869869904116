import {combineReducers} from 'redux';
import categoriesReducer from 'categoriesReducer.js';
import authReducer from 'authReducer.js';
import topMenuReducer from 'topMenuReducer.js';
import betSlipReducer from 'betSlipReducer.js';
import customerReducer from 'customerReducer.js';
import accountReducer from 'accountReducer.js';
import accountWalletReducer from 'accountWalletReducer.js';
import accountCashbackReducer from 'accountCashbackReducer.js';
import accountFreebetReducer from 'accountFreebetReducer.js';
import accountBonusReducer from 'accountBonusReducer.js';
import accountBetHistoryReducer from 'accountBetHistoryReducer.js';
import paymentProvidersReducer from 'paymentProvidersReducer.js';
import favouritesReducer from 'favouritesReducer.js';
import themeReducer from 'themeReducer.js';
import liveCalendarReducer from 'liveCalendarReducer.js';
import marketReducer from 'marketReducer.js';
import {reducer as formReducer} from 'redux-form';

export default combineReducers({
    form: formReducer,
    Categories: categoriesReducer,
    Auth: authReducer,
    TopMenu: topMenuReducer,
    BetSlip: betSlipReducer,
    Customer: customerReducer,
    Account: accountReducer,
    AccountWallet: accountWalletReducer,
    AccountCashback: accountCashbackReducer,
    AccountFreebet: accountFreebetReducer,
    AccountBonus: accountBonusReducer,
    AccountBetHistory: accountBetHistoryReducer,
    AccountDeposit: paymentProvidersReducer,
    AccountWithdraw: paymentProvidersReducer,
    Favourites: favouritesReducer,
    Theme: themeReducer,
    LiveCalendar: liveCalendarReducer,
    MarketData: marketReducer
});

