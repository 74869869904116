import { translation } from 'helpers/utilsHelper.js';
import { validateDO } from 'helpers/validateHelper.js';

export default {
    REGISTER: {
        loginName: {
            length: {
                minimum: 6,
                tooShort: () => translation('account_registerForm_loginTooShort'),
            },
            format: {
                pattern: /^[\w-]*$/,
                message: () => translation('account_registerForm_wrongLoginPattern'),
            },
            presence: {
                message: () => translation('account_registerForm_loginNameEmpty'),
            },
        },
        password: {
            length: {
                minimum: 8,
                maximum: 25,
                tooShort: () => translation('account_registerForm_passwordTooShort'),
                tooLong: () => translation('account_registerForm_passwordTooLong'),
            },
            format: {
                pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$/,
                message: () => translation('account_registerForm_wrongPasswordPattern'),
            },
            presence: {
                message: () => translation('account_registerForm_passwordEmpty'),
            },
        },
        confirmPassword: {
            presence: {
                message: () => translation('account_registerForm_passwordEmpty'),
            },
            equality: {
                attribute: 'password',
                message: () => translation('account_registerForm_passwordsNotEqual'),
            }
        },
        email: {
            presence: {
                message: () => translation('account_registerForm_emailEmpty'),
            },
            email: {
                message: () => translation('account_registerForm_wrongEmailPattern'),
            },
        },
        mobile: {
            presence: {
                message: () => translation('account_registerForm_phoneEmpty'),
            },
            format: {
                pattern: /^[1-9][0-9]{8}$/,
                message: () => translation('account_registerForm_wrongPhoneNumberPattern'),
            },
            length: {
                minimum: 9,
                maximum: 9,
                tooShort: () => translation('account_registerForm_phoneTooShort'),
                    tooLong: () => translation('account_registerForm_phoneTooLong'),
            },
        },
        acceptRules: {
            inclusion: {
                within: [true],
                message: () => translation('account_registerForm_acceptTerms'),
            },
            presence: {
                message: () => translation('account_registerForm_acceptTerms'),
            },
        },
    },
    LOGIN: {
        login: {
            presence: {
                message: () => translation('loginModal_loginEmpty'),
            }
        },
        password: {
            presence: {
                message: () => translation('loginModal_passwordEmpty'),
            }
        }
    },
    FORGOT_PASSWORD: {
        email: {
            presence: {
                message: () => translation('account_forgotPasswordForm_emailEmpty'),
            },
            email: {
                message: () => translation('account_forgotPasswordForm_wrongEmailPattern'),
            },
        }
    },
    CASHOUT: {
        partialAmount: {
            // presence: {
            //     message: () => translation('cashout_PartialAmountEmpty'),
            // },
            // format: {
            //     pattern: /^\d{0,2}(\.\d{1,2})?$/,
            //     message: 'cashout_InvalidValue',
            // },
            numericality: {
                attribute: 'maximumAmount',
                lessThan: 'maximumAmount',
                notLessThan: () => translation('account_cashout_partialAmount_maxAmount'),
                notValid: () => translation('account_cashout_notValidNumber'),
            },
        },
    },
    CHANGE_PASSWORD: {
        oldPassword: {
            presence: {
                message: () => translation('account_changePasswordForm_oldPasswordEmpty'),
            },
        },
        newPassword: {
            presence: {
                message: () => translation('account_changePasswordForm_newPasswordEmpty'),
            },
            equality: {
                attribute: 'confirmPassword',
                message: () => translation('account_changePasswordForm_passwordsNotEqual'),
            },
            length: {
                minimum: 8,
                maximum: 25,
                tooShort: () => translation('account_changePasswordForm_passwordTooShort'),
                tooLong: () => translation('account_changePasswordForm_passwordTooLong'),
            },
            format: {
                pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$/,
                message: () => translation('account_changePasswordForm_wrongPasswordPattern'),
            },
        },
        confirmPassword: {
            presence: {
                message: () => translation('account_changePasswordForm_confirmPasswordEmpty'),
            },
            equality: {
                attribute: 'newPassword',
                message: () => translation('account_changePasswordForm_passwordsNotEqual'),
            },
        },
    },
    EDIT_ACCOUNT: {
        mobileNumber: {
            presence: {
                message: () => translation('account_editProfileForm_phoneEmpty'),
            },
            format: {
                pattern: /^[1-9][0-9]{8}$/,
                message: () => translation('account_editProfileForm_wrongPhoneNumberPattern'),
            },
            length: {
                minimum: 9,
                maximum: 9,
                tooShort: () => translation('account_editProfileForm_phoneTooShort'),
                tooLong: () => translation('account_editProfileForm_phoneTooLong'),
            },
        },
        countryOfBirth: {
            presence: {
                message: () => translation('account_editProfileForm_countryEmpty'),
            },
        },
        city: {
            presence: {
                message: () => translation('account_editProfileForm_cityEmpty'),
            },
            length: {
                minimum: 2,
                tooShort: () => translation('account_editProfileForm_cityTooShort'),
            },
            format: {
                pattern: /[\p{L}\s]*/,
                message: () => translation('account_editProfileForm_wrongCityPattern'),
            },
        },
        address: {
            presence: {
                message: () => translation('account_editProfileForm_adressEmpty'),
            },
            length: {
                minimum: 2,
                tooShort: () => translation('account_editProfileForm_addressTooShort'),
            },
        },
        documentNumber: {
            presence: {
                message: () => translation('account_editProfileForm_addressEmpty'),
            },
            available: {
                message: () => translation('account_editProfileForm_documentInvalid'),
                check: (value) => validateDO(value),
            },
        },
        postalCode: {
            presence: {
                message: () => translation('account_editProfileForm_postalCodeEmpty'),
            },
            format: {
                pattern: /^[A-Za-z0-9\-]{5,10}$/,
                message: () => translation('account_editProfileForm_wrongPostalPattern'),
            },
        }
    },
    PARAGONEX_AUTHORIZATION: {
        password: {
            length: {
                minimum: 5,
                maximum: 20,
                tooShort: () => translation('paragonex_passwordTooShort'),
                tooLong: () => translation('paragonex_passwordTooLong'),
            },
            presence: {
                message: () => translation('paragonex_passwordEmpty'),
            },
        },
        confirmPassword: {
            presence: {
                message: () => translation('paragonex_passwordEmpty'),
            },
            equality: {
                attribute: 'password',
                message: () => translation('paragonex_passwordsNotEqual'),
            }
        },
    },
    PARAGONEX_TRANSFER: {
        payoutAmount: {
            presence: {
                message: () => translation('paragonex_payoutAmountEmpty'),
            },
            format: {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: () => translation('paragonex_wrongAmountPattern'),
            },
        },
        depositAmount: {
            presence: {
                message: () => translation('paragonex_depositAmountEmpty'),
            },
            format: {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: () => translation('paragonex_wrongAmountPattern'),
            },
        }
    }
};

