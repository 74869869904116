import React, { useState } from 'react';
import {Field} from 'redux-form';
import {createMarkup} from 'cmsActions.js';
import S from 'StyledProviderContent.js';
import {translation} from 'helpers/utilsHelper.js';
import Select, { components } from 'react-select';

const Item = ({item}) => {
  return (
      <S.Item>
          {item}
      </S.Item>
  )
}

const GroupComponents = ({items}) => {
  return (
      <S.GroupComponents>
          {items}
      </S.GroupComponents>
  );
}

const InputComponent = ({providerId, name, onChange, disabled, required, onKeyDown, onClick, value, placeholder, type, label, limits, className, isDeposit, balance, ifInitialValueDisabled}) => {
  placeholder ? placeholder = translation(placeholder) : ''; 
  label ? label =  translation(label) : '';

  let max = undefined;
  let min = undefined;

  if (limits) {
      const {maxTotalAmountOnAttempts, minAmount} = limits;
      'maxTotalAmountOnAttempts' in  limits ?  max = maxTotalAmountOnAttempts : undefined;
      'minAmount' in  limits ?  min = minAmount : min = 1;
  } else if (!isDeposit && name == 'transactionAmount') {
      min = 0.1;
      max = balance;
  } else if (name == 'transactionAmount') {
      min = 0.1;
      max = 1000000;
  }

  const minValue = min => value =>
      value && value < min && [`${translation('payment_valueTooSmall')} ${min}`] || undefined;
  const maxValue = max => value =>
      value && value > max && [`${translation('payment_valueTooBig')} ${max}`]  || undefined;

  return (
      <S.InputComponent>
          <Field 
              name={name} 
              onKeyDown={onKeyDown} 
              onClick={onClick} 
              type={type} 
              placeholder={placeholder}
              label={label}
              value={value} 
              required={required}
              disabled={disabled}
              component={RenderField}
              className={className}
              ifInitialValueDisabled={ifInitialValueDisabled}
              validate={[maxValue(max), minValue(min)]}
              providerId={providerId}
          />
      </S.InputComponent>
  );

}

const RenderField = ({providerId, input, required, label, type, className, ifInitialValueDisabled, meta: { touched, error, warning, initial} }) => {

    const isDisabled = (initial, ifInitialValueDisabled) => {
        return initial && ifInitialValueDisabled ? true : false;
    }

  return (
    <S.InputWrapper>
        <S.Input {...input} data-test={`deposit_provider_input_${providerId}`} required={required} type={type} className={className} disabled={isDisabled(initial, ifInitialValueDisabled)}/>
        <S.Label>{label}</S.Label>
        {touched && ((error && <S.Error>{translation(error[0])}</S.Error>) || (warning && <S.Error>{warning}</S.Error>))}
    </S.InputWrapper>

    )
}

const RenderSelectField = ({ input, label, required, ifInitialValueDisabled, removeOption, meta: { touched, error, warning, initial}, children}) => {

    const isDisabled = (initial, ifInitialValueDisabled) => {
        return initial && ifInitialValueDisabled ? true : false;
    }

    return (
        <S.RenderSelectField>
            <label>{label}</label>
            <select {...input} required={required} disabled={isDisabled(initial, ifInitialValueDisabled)}>
            {children}
            </select>
            {touched && ((error && <S.Error>{translation(error[0])}</S.Error>) || (warning && <S.Error>{warning}</S.Error>))}
            {removeOption && input.value && <button type="button" onClick={removeOption.onClick}>{translation(removeOption.label)}</button>}
        </S.RenderSelectField>
    )
}

const ButtonComponent = React.forwardRef(({providerId, onClick, action, label, type, disabled}, ref) => {
    return (
        <S.ButtonComponent>        
            <button ref={ref} data-test={`deposit_provider_button_${providerId}`} onClick={onClick} type={type} action={action} disabled={disabled}>
                {translation(label)} 
            </button>
        </S.ButtonComponent>
    );
})

const ImageComponent = ({src ,alt, onClick}) => {

  if (Array.isArray(src)) {
      const keySrc = 0;

      var socure = "/images/payments/" + src[keySrc].src;
      alt ? alt = alt : alt = '';

  } else {
      var socure = "/images/payments/" + src;
      alt ? alt = alt : alt = '';
  }

  return (
      <S.ImageComponent onClick={onClick}>
          <img src={socure.toString()} alt={alt.toString()}></img>
      </S.ImageComponent>
  );
}

const SelectComponent = ({data, name, onChange, onClick, value, type, label, required, disabled, ifInitialValueDisabled, removeOption}) => {
  
  if (data != null || required) {

      label ? label =  translation(label) : '';
      return (
          <S.SelectComponent>
              <Field 
                  name={name} 
                  value={value} 
                  onChange={onChange} 
                  onClick={onClick}
                  type={type}
                  required={required}
                  disabled={disabled}
                  label={label}
                  ifInitialValueDisabled={ifInitialValueDisabled}
                  removeOption={removeOption}
                  component={RenderSelectField}>

                  {data.map((option, key) => {

                      if (key == 0 && disabled) {
                          return <option disabled={disabled} key={key} value={option.value}>{option.name}</option>; 
                      } else {
                          return <option key={key} value={option.value}>{option.name}</option>; 
                      }
                           
                  })}
      
              </Field>
          </S.SelectComponent>
      );
  }
}

const TextComponent = ({label}) => {
  
    return (
        <S.TextComponent>
            <label>{translation(label)}</label>
        </S.TextComponent>
    );
}

const ImgAndTextComponent = ({imageComponent, label}) => {
  
  return (
      <S.ImgAndTextComponent>
          <ImageComponent src={imageComponent} />
          <label>{translation(label)}</label>
      </S.ImgAndTextComponent>
  );
}

const ConfirmRowComponent = ({label, value}) => {
  return (
      <S.ConfirmRowComponent>
          <span>{translation(label)}</span>
          <span>{value}</span>
      </S.ConfirmRowComponent>
  );
}

const ConfirmDataComponent = ({titleLabel, label, value, item}) => {

  return (
      <S.ConfirmDataComponent>
            <span>{translation(titleLabel)}</span>
            <div>
                <span>{translation(label)}</span>
                <span>{translation(value)}</span>
            </div>
            {item}
      </S.ConfirmDataComponent>
  )
}

const CmsComponent = ({item}) => {
    const html = 'data' in item ? item.data.html : item.html;
    return (
        <div dangerouslySetInnerHTML={createMarkup(html)}></div>
    )
}

const ExternalComponent = ({item}) => {
  return (
      <div>
          <span>{translation('m_payment_waitingForRedirect')}</span>
          <div id="externalForm" dangerouslySetInnerHTML={createMarkup(item)}></div>
      </div>
  )
}

const IframeComponent = ({url}) => {
  return (
      <S.IframeComponent>
          <iframe src={url} frameBorder="0" sandbox='allow-same-origin allow-scripts allow-popups allow-forms' allowFullScreen></iframe>
      </S.IframeComponent>
  )
}

const LimitsComponent = ({min, max, fee, minLabel, maxLabel, feeLabel, transactionCurrency, className}) => {

    return (
        <div className={className}>
            {min ? 
            <div>
                <span>{translation(minLabel)}</span>
            <span>&nbsp;{min}&nbsp;{transactionCurrency}</span>
            </div> : <div></div>}
            {max ?
            <div>
                <span>{translation(maxLabel)}</span>
                <span>&nbsp;{max}&nbsp;{transactionCurrency}</span>
            </div> : <div></div>}
            {fee ?
            <div>
                <span>{translation(feeLabel)}</span>
                <span>&nbsp;{fee}&nbsp;{transactionCurrency}</span>
            </div> : <div></div>}
        </div>
    )
}

const MenuComponent = ({items}) => {
    return (
        <div>
  
        </div>
    )
}
  
const LockComponent = ({src, label}) => {
    var socure = MOBILE_URL + "images/payments/" + src;
  
    return (
      <S.LockComponent>
        <img src={socure.toString()}></img>
        <span>{translation(label)} </span>
      </S.LockComponent>
    )
}

const ConfirmOffersCanceledOnWithdrawalComponent = ({titleLabel, label, value, item}) => {
    return (
        <S.ConfirmOffersCanceledOnWithdrawalComponent>
            <span>{translation(titleLabel)}</span>
            <div>
                <span>{translation(label)}</span>
                <span>{translation(value)}</span>
            </div>
            <S.ConfirmRowComponent>
                {item}
            </S.ConfirmRowComponent>
        </S.ConfirmOffersCanceledOnWithdrawalComponent>

    )
}

              
const InfoWonAmountToPayoutComponent = ({transactionAmount, transactionCurrency, labelLocked, labelUnlocked}) => {

    if (transactionAmount > 0) {
        return (
            <S.InfoWonAmountToPayoutComponentUnlocked>
                <div>{`${translation(labelUnlocked).replace('{{transactionAmount}}', transactionAmount).replace('{{transactionCurrency}}', transactionCurrency)}`}</div>
            </S.InfoWonAmountToPayoutComponentUnlocked>
        )
    } else {
        return (
            <S.InfoWonAmountToPayoutComponentLocked>
                <div>{translation(labelLocked)} </div>
            </S.InfoWonAmountToPayoutComponentLocked>
        ) 
    }
}

const RemoveCardCardComponent = ({cardType, cardNumber, onClickAccept, onClickCancel}) => {
    return (
       <S.RemoveCardCardComponent>
           <span>{`${translation('payment_confirmRemoveCard')} ${cardNumber}`}</span>
           <div>
               <button type="button" onClick ={() => onClickAccept(cardType)}>{translation('common_accept')}</button>
               <button type="button" onClick ={() => onClickCancel()}>{translation('common_cancel')}</button>
           </div>
       </S.RemoveCardCardComponent> 
    );
}

const WinningsBalanceComponent = ({balance, label, currency}) => {
  
    return (
        <S.WinningsBalanceComponent>
            <label>{`${translation(label).replace('{balance}', balance)} ${currency}`}</label>
        </S.WinningsBalanceComponent>
    );
}

const ReactSelectComponent = ({data, name, label, first, required, isSearchable, removeOption}) => {
    return (
        <Field 
            component={RenderReactSelectField} 
            name={name} 
            data={data}
            label={label}
            first={first}
            required={required}
            isSearchable={isSearchable}
            removeOption={removeOption}
        />
    )
}

const RenderReactSelectField = ({input, data, label, first, required, isSearchable, removeOption, meta: { touched, error, warning}}) => {

    const Option = props => {
        return (
            <S.SelectOption>
                <components.Option {...props} />
                {Boolean(removeOption) && Boolean(props.data.value) && <img onClick={() => removeOption.onClick(props.data.value)} src="/images/svg/close_btn_blue.svg"/>}
            </S.SelectOption>
        );
    };

    if (data != null || required) {

        const selectedOption = data.filter(key => (key.value == input.value));
  
        label ? label =  translation(label) : '';
        return (
            <S.ReactSelectComponent>
                <label>{label}</label>
                <Select 
                    defaultValue={selectedOption ? selectedOption : { label: translation(first), value: 0 }}
                    isSearchable={isSearchable} 
                    name={input.name} 
                    options={data} 
                    components={{ Option }} 
                    value={input.value.value}
                    onChange={(value) => input.onChange(value.value)}
                    onBlur={() => input.onBlur(input.value.value)}
                    className="react-select-container"
                />
                {touched && ((error && <S.Error>{translation(error[0])}</S.Error>) || (warning && <S.Error>{warning}</S.Error>))}
            </S.ReactSelectComponent>
        )
    }
}

export {
    Item, 
    GroupComponents, 
    InputComponent, 
    ButtonComponent, 
    SelectComponent, 
    ImgAndTextComponent, 
    ImageComponent,
    ConfirmRowComponent, 
    ConfirmDataComponent,
    ExternalComponent,
    IframeComponent,
    CmsComponent,
    LimitsComponent,
    MenuComponent,
    LockComponent,
    ConfirmOffersCanceledOnWithdrawalComponent,
    TextComponent,
    InfoWonAmountToPayoutComponent,
    RemoveCardCardComponent,
    WinningsBalanceComponent,
    ReactSelectComponent
};

