import React, {Component} from 'react';
import S from 'StyledPaymentList.js';
import {connect} from 'react-redux';
import ProviderContent from 'ProviderContent.js';
import {CSSTransition} from 'react-transition-group';
import {translation} from 'helpers/utilsHelper.js';
import paymentPrepareTransaction from 'paymentPrepareTransaction.enum.js';

class PaymentsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            drawing: [],
            selectedProvider: null
        };
    }

    componentDidMount = () => {
        try {
            this.drawComponents();
        } catch (error) {
            console.log(`DrawComponent error: ${error}`);
        }
    };

    drawComponents = () => {

        const {paymentData, userData, isLogged} = this.props;
        const {PROVIDER}  = paymentPrepareTransaction;
        let temp = [];

        temp.push(
            paymentData.map((provider, index) => {
                const {providerId} = provider;
                const providerForm = PROVIDER[providerId];
                let active = false;
                let customProps = {};

                if (providerForm && 'minatureComponent' in providerForm) {
                    const {img} = providerForm.minatureComponent;

                    if (img) {
                        customProps['img'] = img;
                    }
                }

                return (
                    <MinatureProviderComponent 
                        onClick={() => this.activeProvider(index)}
                        active={active}
                        index={index}
                        key={providerId} 
                        providerId={providerId}
                        img={customProps.img}
                        providerForm={providerForm}
                        paymentData={paymentData[index]}
                        userData={userData}
                        isLogged={isLogged}
                        shortDescription={PROVIDER[providerId] ? PROVIDER[providerId].minatureComponent.shortDescription : null}
                        providerName={PROVIDER[providerId] ? PROVIDER[providerId].providerName : null}
                    ></MinatureProviderComponent>
                )

            })
        )

        this.setState({drawing: temp});
    }

    activeProvider = (index) => {
        this.setState({selectedProvider: index});
    }

    render() {
        const {paymentData, miniature, userData, isLogged} = this.props;
        const {PROVIDER}  = paymentPrepareTransaction;
        
        if (miniature) {
            return (
                <>
                    <S.PaymentsList>
                        {this.state.drawing.map((item, index) => <Item item={item} key={index}></Item>)}
                    </S.PaymentsList>
                    {paymentData.map((provider, index) => { 
                    
                        const {providerId} = provider;
                        const providerForm = PROVIDER[providerId];
                    
                        return (<CSSTransition
                            in={index == this.state.selectedProvider ? true : false}
                            key={`${index}_${providerId}`}
                            timeout={50}
                            classNames="provider-transition"
                            unmountOnExit
                            apper>
                            <ProviderContent
                                key={providerId} 
                                providerForm={providerForm} 
                                paymentData={paymentData[index]}
                                userData={userData}
                                isLogged={isLogged}
                                form={`providerContentForm_${providerId}`}
                            />
                        </CSSTransition>)
                    })}
                </>
            );
        } else {
            return (
                <S.PaymentsList>
                    {paymentData.map((provider, index) => <ProviderContent 
                        key={provider.providerId} 
                        providerForm={PROVIDER[paymentData[index].providerId]} 
                        paymentData={paymentData[index]}
                        limits={PROVIDER[paymentData[index].providerId]}
                        userData={userData}
                        isLogged={isLogged}
                        form ={`providerContentForm_${provider.providerId}`}
                    />)}
                </S.PaymentsList>     
            );
        }
    }
}

const MinatureProviderComponent = ({img, paymentData, shortDescription, providerId, index, onClick, providerName}) => {
    const src = `images/payments/${img}`;
    return (
        <div>
            <S.MinatureProviderComponent onClick={() => onClick(index)}>
                <div>
                    <div>
                        <img src={src}/>
                    </div>
                    <div>
                        <span>{providerName ? translation(providerName + providerId) : paymentData.providerName}</span>
                        {shortDescription ? <span>{translation(shortDescription + providerId)}</span> : <span></span>}
                    </div>
                </div>
            </S.MinatureProviderComponent>
        </div>
    )
}

const Item = ({item}) => {
    return (
        <S.Item>
            {item}
        </S.Item>
    )
}

const mapStateToProps = (state, props) => {
    const {ProviderContent:{isPending, hasError, error}} = state;

    return {
        isPending,
        hasError,
        error
    }
};

export default connect(mapStateToProps)(PaymentsList);

