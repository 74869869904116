import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import S from 'StyledLiveCalendar.js';
import _find from 'lodash/find';
import _each from 'lodash/each';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import _reduce from 'lodash/reduce';;
import {redirect} from 'utilsHelper.js';
import {formatDate} from 'datesHelper.js';
import {getByRemoteId} from 'eventActions.js';

const LiveCalendarItem = ({eventName, sportName, parentName, categoryName, eventStart, sportId, extras, remoteId, getByRemoteId})=>{

    const [prematchData, setPrematchData] = useState(null);

    const getPrematchData = async ()=>{
        const data = await getByRemoteId(remoteId);
        return data[0];
    }

    useEffect(()=>{
        let isCancelled = false;

        const fetchPrematch = async ()=>{
            const event = await getPrematchData();
            if(!isCancelled){
                setPrematchData(event)
            }
        }
        fetchPrematch()
       return ()=> isCancelled = true;
    },[remoteId])

    

    const onClickHandler = (e)=>{
        if(!prematchData ||!prematchData.eventId ||e.target.classList.contains("flashscore-link")){
            return false;
        }
        redirect(`/sports/event/${prematchData.eventId}`);
    };

    const hasStream = !!extras?.hasPerformVideo || extras?.betradarLivestreamLCO || extras?.BetbazarStreamingData;
    const startDate = formatDate(new Date(eventStart), 'yyyy-MM-dd HH:mm');
    const dateParts = startDate.split(/\s+/);

    return <S.LiveCalendarItem  onClick={onClickHandler}>

    <S.LiveCalendarItemIcon>
        <use href={`#sport-${sportId}`}></use>
    </S.LiveCalendarItemIcon>

    <S.LiveCalendarItemDateTime>
        <S.Date>{dateParts[0]}</S.Date>
        <S.Time>{dateParts[1]}</S.Time>
    </S.LiveCalendarItemDateTime>

    <S.LiveCalendarItemInfo>
        <S.LiveCalendarItemCategories>{sportName}-{parentName}, {categoryName}</S.LiveCalendarItemCategories>
        <S.LiveCalendarItemEventName>{eventName}</S.LiveCalendarItemEventName>
    </S.LiveCalendarItemInfo>

    <S.LiveCalendarIcons>
        {hasStream && <S.LiveCalendarStreamIcon>
        <svg className ="icon-stream" xmlns="http://www.w3.org/2000/svg" width="15.972" height="16.242" viewBox="0 0 15.972 16.242">
            <g id="tv_8_" data-name="tv (8)" transform="translate(-1.35 -1.081)">
                <rect id="Rectangle_276" data-name="Rectangle 276" width="14.672" height="11.004" rx="2" transform="translate(2 5.668)" fill="none"  strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3"/>
                <path id="Path_1062" data-name="Path 1062" d="M14.336,2,10.668,5.668,7,2" transform="translate(-1.332 0)" fill="none"  strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3"/>
            </g>
        </svg>
        </S.LiveCalendarStreamIcon>}

        {prematchData?.flashscoreLink && (
          <S.LiveCalendarFlashscoreLink
            className="flashscore-link"
            onClick={() => {
              window.open(
                prematchData.flashscoreLink,
                "flashscore",
                "menubar=1,resizable=1,width=600,height=800"
              );
            }}
          />
        )}

        <S.LiveCalendarItemArrow>
            <svg xmlns="http://www.w3.org/2000/svg" width="8.786" height="5.043"
                viewBox="0 0 8.786 5.043">
                <path id="Path_3680" data-name="Path 3680" d="M8,15.474,11.474,12l3.474,3.474"
                    transform="translate(-7.081 -11.35)" fill="none"
                    strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3"></path>
            </svg>
        </S.LiveCalendarItemArrow>
    </S.LiveCalendarIcons>
    

</S.LiveCalendarItem>
}

const mapDispatchToProps = (dispatch) => {

    return {
        getByRemoteId: bindActionCreators(getByRemoteId, dispatch)
    }
};

export default connect(null, mapDispatchToProps)(LiveCalendarItem);