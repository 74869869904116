import styled from 'styled-components';
const S = {};

S.AccountEditProfile = styled.div`
    flex: 1;
    width: 100%;
    padding: .8333rem 2.5rem 2.5rem;
    display: flex;
    padding-left: 30.7692px;
    padding-right: 30.7692px;
    justify-content: center;
`;

export default S;