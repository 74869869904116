import React, {PureComponent, memo, useMemo, useRef} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import S from 'StyledLiveCalendar.js';
import _find from 'lodash/find';
import _each from 'lodash/each';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import _reduce from 'lodash/reduce';
import {fetchLiveCalendarEvents, changeDate, changeSport, changeStreamFilter} from 'liveCalendarActions.js';
import Loader from 'Loader.js';
import {translation, redirect} from 'utilsHelper.js';
import {formatDate} from 'datesHelper.js';
import {Swiper, SwiperSlide} from 'swiper/react';
import {liveCalendarSelector, allEventsSelector} from 'liveCalendarSelector.js';
import LiveCalendarItem from './LiveCalendarItem';
import 'swiper/swiper.min.css'

const SliderArrow = ({onClick, className})=> {
    return <button className={`swiper-arrow ${className}`} onClick={onClick}>
        <svg xmlns="http://www.w3.org/2000/svg" width="8.786" height="5.043" viewBox="0 0 8.786 5.043">
            <path id="Path_3680" data-name="Path 3680" d="M8,15.474,11.474,12l3.474,3.474" transform="translate(-7.081 -11.35)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3"></path>
        </svg> 
    </button>;
} 

const SportsFilter = memo(({changeSport, selectedSport, allEvents}) => {

    const swiperRef = useRef();
    const getSportsList = useMemo(() => {

        let allSports = _reduce(allEvents, (initialArray, {sportId, sportName, sportSortOrder}) => {
            const idOfSportExist = _find(initialArray, {sportId});
            if (!idOfSportExist) {
                initialArray.push({sportId, sportName, sportSortOrder});
            }
            return initialArray;
        }, []);

        allSports = _sortBy(allSports, ['sportSortOrder']);

        const options = [];
        const selectSport = {
            value: 0,
            label: `${translation('show_all')}`
        };

        options.push(selectSport);

        _each(allSports, ({sportId, sportName}) => {
            const newOption = {
                value: sportId,
                label: sportName
            };
            options.push(newOption);
        });
        return options;
    },[])

    const onSportChange = (value) => {
        changeSport(value);
    };

    const formatOptionLabel = ({value, label}) => {
        const isActive = selectedSport == value;
        return (
           <SwiperSlide style={{width:"auto"}} key={value}>
             <S.SportsFilterOption className="sport-option" onClick={()=>onSportChange(value)} isActive={isActive}>
                <S.SportsFilterOptionInner>
                <S.SportsFilterOptionIcon className="sport-icon">
                    <use href={`#sport-${value}`}></use>
                </S.SportsFilterOptionIcon>
                <S.SportsFilterOptionLabel>{label}</S.SportsFilterOptionLabel>
                </S.SportsFilterOptionInner>
            </S.SportsFilterOption>
           </SwiperSlide>
        )
    };

    return (
            <S.SportsFilter className="sports-filter" >
                <SliderArrow className="swiper-prev" onClick={() => swiperRef.current?.slidePrev()}/>
                 <Swiper slidesPerView={'auto'} onBeforeInit={(swiper) => {swiperRef.current = swiper}} >
                {_map(getSportsList, (option)=>formatOptionLabel(option))}
                 </Swiper>
                 <SliderArrow className="swiper-next" onClick={() => swiperRef.current?.slideNext()}/>
            </S.SportsFilter>
    );
});

const DatesFilter = memo(({changeDate, selectedDate}) => {

    const datesList = useMemo(() => {
        const dictDayNames = app.service.Dict.getDaysNames();
        const datesRange = [];
        const today = new Date();
        const todayFormatted = formatDate(today, 'yyyy-MM-dd');
        const todayToDisplay = formatDate(today, 'dd.MM');
        for (let i = 0; i < 7; i++) {
            const nextDate = new Date(today);
            nextDate.setDate(nextDate.getDate() + i);
            const formattedDate = formatDate(nextDate, 'yyyy-MM-dd');
            let dayName = (nextDate.getDay() == 0) ? dictDayNames[6]['name'] : dictDayNames[nextDate.getDay() - 1]['name'];
            let dateToDisplay = formatDate(nextDate, 'dd.MM');

            if (formattedDate == todayFormatted) {
                dayName = translation(`today`);
                dateToDisplay = todayToDisplay;
            }

            const date = {
                formattedDate,
                dayName,
                dateToDisplay
            };
            datesRange.push(date);
        }
        return datesRange;
    }, []);

    return (
        <S.ScrollWrapper >
        <S.DatesFilter className="dates-filter">
            {_map(datesList, ({formattedDate, dayName, dateToDisplay}) => {
                const isActive = (selectedDate == formattedDate);
                return (
                    <S.DatesFilterItem key={formattedDate}
                                       isActive={isActive}
                                       onClick={changeDate.bind(null, formattedDate)}>
                        <S.DatesFilterItemText>{dateToDisplay}</S.DatesFilterItemText>
                        <S.DatesFilterItemText>{dayName}</S.DatesFilterItemText>
                    </S.DatesFilterItem>
                )
            })}
        </S.DatesFilter>
        </S.ScrollWrapper>
    );
});

const StreamFilter = memo(({showStreamingOnly, changeStreamFilter})=>{

    const toggleFilter =()=> changeStreamFilter(!showStreamingOnly);

    return <S.LivestreamFilterButton onClick={toggleFilter} isActive = {showStreamingOnly}>
                <svg className ="icon-stream" xmlns="http://www.w3.org/2000/svg" width="15.972" height="16.242" viewBox="0 0 15.972 16.242">
                                        <g id="tv_8_" data-name="tv (8)" transform="translate(-1.35 -1.081)">
                                            <rect id="Rectangle_276" data-name="Rectangle 276" width="14.672" height="11.004" rx="2" transform="translate(2 5.668)" fill="none"  strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3"/>
                                            <path id="Path_1062" data-name="Path 1062" d="M14.336,2,10.668,5.668,7,2" transform="translate(-1.332 0)" fill="none"  strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3"/>
                                        </g>
                </svg>
           </S.LivestreamFilterButton>
})

class LiveCalendar extends PureComponent {

    static getDerivedStateFromError = () => {
        return {hasError: true};
    };

    state = {hasError: false};

    componentDidMount = async() => {
        const {fetchLiveCalendarEvents} = this.props;
        try {
            await fetchLiveCalendarEvents();
        } catch (error) {
        }
    };

    onClickHandler = (eventId, e)=>{
        if(window.getSelection().toString().length || !eventId){
            return false;
        }
        redirect(`/live/event/${eventId}`);
    };

    render() {
        const {isPending,...rest} = this.props;
        const {liveEvents} = rest;
        const {hasError} = this.state;
        const isEmpty = !!!liveEvents.length;
     

        if (hasError) {
            return <div>Error fallback component!</div>
        }
        if (isPending) {
            return <Loader color="#F05A22"/>
        }
     

        return (
            <S.LiveCalendar className="live-calendar">

                <S.LiveCalendarFilters className="live-calendar-filters">

                    <SportsFilter {...rest}/>
                    <S.FiltersWrapper>
                        <DatesFilter  {...rest}/>
                        <StreamFilter {...rest}/>
                    </S.FiltersWrapper>
                    

                </S.LiveCalendarFilters>

                {isEmpty && (
                <S.LiveCalendarEmptyList>
                        <div className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15.25" height="15.25" viewBox="0 0 15.25 15.25">
                                <path id="Path_10534" data-name="Path 10534" d="M130.122,52.5a7.625,7.625,0,1,0,5.392,2.233A7.626,7.626,0,0,0,130.122,52.5Zm0,14.077a6.452,6.452,0,1,1,4.562-1.89A6.452,6.452,0,0,1,130.122,66.577Zm-.519-9.159a.748.748,0,0,1-.214-.519.8.8,0,0,1,.015-.144.713.713,0,0,1,.109-.264.833.833,0,0,1,.091-.111.76.76,0,0,1,1.038,0,.748.748,0,0,1,.214.519.8.8,0,0,1,0,.144.713.713,0,0,1-.109.264,1.09,1.09,0,0,1-.091.111.736.736,0,0,1-1.038,0Zm2.044,5.933a.586.586,0,0,1-.587.587h-1.877a.587.587,0,1,1,0-1.173h.352V59.538h-.293a.587.587,0,1,1,0-1.173h.88a.586.586,0,0,1,.587.587v3.812h.352a.586.586,0,0,1,.587.587Z" transform="translate(-122.497 -52.5)" fill="#bcbcbc"/>
                            </svg>
                        </div>
                        <div className="message">{translation("livecalendar_emptyList")}</div>
                    </S.LiveCalendarEmptyList>)}
                {!isEmpty &&<S.LiveCalendarList className="live-calendar-wrapper">
               
                    {_map(liveEvents, (event) => {
                        
                        return (<LiveCalendarItem key={event.eventId} {...event}/>
                   
                        )
                    })}

                </S.LiveCalendarList>}

            </S.LiveCalendar>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {LiveCalendar:{isPending, error, selectedDate, selectedSport, showStreamingOnly}} = state;
    const liveEvents = liveCalendarSelector(state, props);
    const allEvents = allEventsSelector(state, props);
    return {
        selectedDate,
        selectedSport,
        liveEvents,
        isPending,
        error,
        showStreamingOnly,
        allEvents
    }
};

const mapDispatchToProps = (dispatch) => {
    const actionCreators = {
        changeDate,
        changeSport,
        fetchLiveCalendarEvents,
        changeStreamFilter
    };

    const bindedActionCreators = bindActionCreators(actionCreators, dispatch);
    return {
        ...bindedActionCreators,
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveCalendar);


