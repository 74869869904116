export default {
    HOME: {
        href: '/sports',
        className: 'sport',
        translationKey: 'topMenu_navbar_sports'
    },

    LIVES: {
        href: '/live',
        className: 'live',
        translationKey: 'topMenu_navbar_inPlay'
    },
    VIRUTALS: {
        href: '/virtuals',
        className: 'virtual',
        translationKey: 'topMenu_navbar_virtuals'
    },
    SLOTS: {
        href: '/slots',
        className: 'slots',
        translationKey: 'topMenu_navbar_slots'
    },
    INTEGRATIONS: {
        href: '/pages/integrations',
        className: 'integrations',
        translationKey: 'topMenu_navbar_integrations'
    },
    LOTTO: {
        href: '/lotto',
        className: 'lotto',
        translationKey: 'topMenu_navbar_numberGames'
    },
    BOA: {
        href: '/boa',
        className: 'boa',
        translationKey: 'topMenu_navbar_boa'
    }
};