import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import S from 'StyledTopMenu.js';
import TopMenuList from 'TopMenuList.js';
import UnloggedPanel from 'UnloggedPanel.js';
import LoggedPanel from 'LoggedPanel.js';
import {changeActiveItem} from 'topMenuActions.js';
import {logInUser, logOutUser, forgotPassword, registerUser, loginCustomerByCookie} from 'authActions.js';
import {toggleBalance} from 'betSlipActions.js';
import {toggleAccountModal} from 'accountActions.js';
import store from 'store';

class TopMenu extends Component {

    static getDerivedStateFromError = (error) => {
        return {hasError: true};
    };

    state = {hasError: false};

    componentDidMount = () => {
        try {
            this.watchRoute();
            this.selectTopMenuItemsFromPath();
        } catch (error) {
            console.log('topMenuSelection error:', error);
        }
    };

    selectTopMenuItemsFromPath = () => {
        const { changeActiveItem, changeSubmenuActiveItem } = this.props;
        const currentRoutePath = app.router.getCurrentRoute();

        if (currentRoutePath) {
            switch (true) {
                case /^\/sports.*?/.test(currentRoutePath):
                    changeActiveItem('HOME');
                    break;
                case /^\/live.*?/.test(currentRoutePath):
                    changeActiveItem('LIVES');
                    break;
                case /^\/virtuals.*?/.test(currentRoutePath):
                    changeActiveItem('VIRUTALS');
                    break;
                case /^\/slots.*?/.test(currentRoutePath):
                    changeActiveItem('SLOTS');
                    break;
                case /\/integrations.*?/.test(currentRoutePath):
                    changeActiveItem('INTEGRATIONS');
                    break;
                case /^\/lotto.*?/.test(currentRoutePath):
                    changeActiveItem('LOTTO');
                    break;
                default:
                    changeActiveItem('HOME');
            }
        } else {
            changeActiveItem('HOME');
        };
    };


    watchRoute = () => {
        app.router.onRouteChange('topMenuSelection', function () {
            handleTopMenuChange();
        });

        const handleTopMenuChange = () => {
            this.selectTopMenuItemsFromPath();
        }
    };

    loginOnSubmit = async(postData) => {
        const {logInUser, loginCustomerByCookie} = this.props;
        try {
            await loginCustomerByCookie();
            const {getState} = store;
            const {Auth:{isLogged, user}} = getState();
            let userData;
            if (isLogged){
                userData = user;
            } else {
                userData = await logInUser(postData);
            }
            return userData;
        } catch (error) {
            throw error;
        }
    };

    registerOnSubmit = async(postData) => {
        const {registerUser} = this.props;
        try {
            const newUser = await registerUser(postData);
            return newUser;
        } catch (error) {
            throw error;
        }
    };

    forgotPasswordOnSubmit = async(postData) => {
        const {forgotPassword} = this.props;
        try {
            const user = await forgotPassword(postData);
            return user;
        } catch (error) {
            throw error;
        }
    };

    render() {

        const {isLogged, logOutUser, user, balanceId, toggleBalance, toggleAccountModal, isOpen, ...rest} = this.props;
        const {hasError} = this.state;
        if (hasError) {
            return <div>Error fallback component!</div>
        }

        return (
            <S.TopMenu>

                <S.MainRow className="main-row">

                    {TERMINAL!=true &&
                    (
                        <>
                        {isLogged ?
                            (<LoggedPanel user={user} logOut={logOutUser}
                                          toggleBalance={toggleBalance} balanceId={balanceId} isAccountOpen={isOpen}
                                          toggleUserAccountModal={toggleAccountModal}></LoggedPanel>) :
                            (<UnloggedPanel loginOnSubmit={this.loginOnSubmit} registerOnSubmit={this.registerOnSubmit}
                                            forgotPasswordOnSubmit={this.forgotPasswordOnSubmit}/>)
                        }
                        </>
                    )
                    }


                </S.MainRow>

                <S.MenuRow className="menu-list">

                    <S.Link href="/">
                        <S.Logo src="/images/logo.png"></S.Logo>
                    </S.Link>

                    <TopMenuList {...rest}/>

                </S.MenuRow>

            </S.TopMenu>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {TopMenu:{activeItem}, Auth:{isLogged, user}, BetSlip:{balanceId}, Account:{isOpen}} = state;
    return {
        activeItem,
        isLogged,
        user,
        balanceId,
        isOpen
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeActiveItem: bindActionCreators(changeActiveItem, dispatch),
        logOutUser: bindActionCreators(logOutUser, dispatch),
        logInUser: bindActionCreators(logInUser, dispatch),
        registerUser: bindActionCreators(registerUser, dispatch),
        forgotPassword: bindActionCreators(forgotPassword, dispatch),
        toggleBalance: bindActionCreators(toggleBalance, dispatch),
        toggleAccountModal: bindActionCreators(toggleAccountModal, dispatch),
        loginCustomerByCookie: bindActionCreators(loginCustomerByCookie, dispatch),
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);

