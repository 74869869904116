import styled from 'styled-components';
import {Input} from 'global/styles/styles.js';

const S = {};

S.Item = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

S.ConfirmOffersCanceledOnWithdrawalComponent = styled.div`
    padding: 2rem 0;
    
    & > span {
        color: red;
        font-size: 1.2rem;
    } 

    & > div:first-of-type {
        display: flex;
        justify-content: space-between;
        font-weight: 400;
        color: #a3a3a3;
    }
`;

S.ConfirmDataComponent = styled.div`
    & > span {
        color: #676767;
        font-size: 1.2rem;
    } 

    & > div:first-of-type {
        display: flex;
        justify-content: space-between;
        
    }
`;

S.ProviderContent = styled.form`
    display: flex;
    align-items: center;
    flex-direction: column;
    min-width: ${({onlyOne}) => {
        return onlyOne ? '100%' : '50%';
    }};

    max-width: ${({onlyOne}) => {
        return onlyOne ? '100%' : '50%';
    }};

 
    &.provider-transition-enter {
        opacity: 0;
        transform: scale(0.9);
    }

    &.provider-transition-enter-active {
        opacity: 0;
        transform: translateX(0);
        transition: opacity 300ms, transform 300ms;
    }

    &.provider-transition-exit {
        opacity: 0;
    }

    &.provider-transition-exit-active {
        opacity: 0;
        transform: scale(0.9);
        transition: opacity 300ms, transform 300ms;
    }

    & > ${S.Item}{
        margin: 1rem 0;

        & > ${S.ConfirmOffersCanceledOnWithdrawalComponent} {
            width: ${({onlyOne}) => {
                return onlyOne ? '50%' : '62%';
            }};
        }

        & > ${S.ConfirmDataComponent} {
            width: ${({onlyOne}) => {
                return onlyOne ? '50%' : '62%';
            }};
        }
        
    }
`;

S.ImgAndTextComponent = styled.div`
    display: flex;
    justify-content: space-between;

    & > div:first-of-type {
        border-radius: 2rem;
        background: #157E8C;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3rem;
        height: 3rem;
        margin: 0.2rem 2rem;

        & > img {
            width: 2rem;
        }
    }

    & > label {
        font-weight: 300;
        font-size: 1.2rem;
        letter-spacing: 1px;
        opacity: 0.8;
        font-family: sans-serif;
    } 

`;

S.InputComponent = styled.div`
    width: 50%;
`;

S.RenderSelectField = styled.div`
`;

S.SelectComponent = styled.div`
    width: 50%;

    label {
        font-weight: normal;
        font-size: 0.8rem;
        color: #787d83;
        margin: 0;
        padding: 0 0.5rem 0.2rem;
    }

   ${S.RenderSelectField} {
        & > select {
            width: 100%;
            height: 34px; 
            font-size: 1.33rem;
            border-radius: .42rem;
            background-color: #f6f6f6;
        }
    }
`;

S.InputWrapper = styled.div`
    position: relative;
`;

S.Label = styled.label`
    pointer-events: none;
    transition: .2s;
    position: absolute;
    top: 0.3rem;
    left: 0.7rem;
    color: #787d83!important;
    font-size: 1.33rem;
    font-weight: 400;
`;

S.Input = styled(Input)`
    box-shadow: none;
    border-radius: .42rem;
    border: .08rem solid #e1e1e1;
    color: #000;
    font-size: 1.33rem;
    padding: 0.3rem 0.3rem;
    background-color: #f6f6f6;
    display: block;
    width: 100%;
    height: 34px;
    &:valid{
        & + ${S.Label}{
            margin-top: -1.62rem;
            font-size: 0.8rem;
        }
    }

    &.without-floating-label {
        & + ${S.Label}{
            margin-top: -1.62rem;
            font-size: 0.8rem;
            color: #fff;
        }
    }
`;

S.Error = styled.div`
    color: red;
    margin: 5px 0;
`;

S.ButtonComponent = styled.div`

    margin: 2rem 0 0 0;

     & > button {
        background: #fff;
        line-height: 2.33rem;
        border-radius: .33rem;
        color: #000;
        font-size: 1.4rem;
        font-style: italic;
        font-weight: 700;
        width: 8rem;
        letter-spacing: 1px;
     }

`;

S.ImageComponent = styled.div`
    max-height: 7rem;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
        width: 10rem;
    }
`;

S.GroupComponents = styled.div`
    display: flex;
`;

S.ConfirmRowComponent = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    width: 100%;
    font-weight: 600;
    font-family: sans-serif;

`;

S.IframeComponent = styled.div`
    height: 100%;
    
    & > iframe {
        height: 75vh;
    } 
`;

S.InfoWonAmountToPayoutComponentUnlocked = styled.div`

`;

S.InfoWonAmountToPayoutComponentLocked = styled.div`

`;

S.RemoveCardCardComponent = styled.div`
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #eab000;
    border-left: 8px solid #ce9c09;
    box-shadow: 1px 3px 4px rgba(0,0,0,.2);
    padding: 0.5rem;
    cursor: auto;
    border-radius: 5px;
    text-align: center;
    
    & > div {
        margin: 0.25rem;
        
        & > button {
            margin: 0 2rem;
            border: none;
            background: none;
            pointer-events: auto;
            font-weight: 600;
            color: #fff;
        }

        & > button:first-of-type {
            color: #09A83B;
        }
    }
`;

S.WinningsBalanceComponent = styled.div`

`;

S.ReactSelectComponent = styled.div` 

`;

S.SelectOption = styled.div` 

`;

export default S;
