import React from "react";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css"

let notificationDOMRef = null;

export const addNotification = (type, title, message, content, container = 'top-center', insert = 'top') => {
    removeNotification();
    notificationDOMRef.addNotification({
        title: title,
        message: message,
        type: type,
        insert: insert,
        container: container,
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismissable: { click: true },
        content: content
    });
}

export const removeNotification = () => {
    const { notifications } = notificationDOMRef.state;

    if (Array.isArray(notifications) && notifications.length) {
        notificationDOMRef.removeNotification(notifications[0].id);
    }
}

export default class Notifications extends React.Component {

    render() {
        return (
            <ReactNotification ref={ref => notificationDOMRef = ref} />
        );
    }
}
