import { translation } from 'helpers/utilsHelper.js';

export default {
    PROVIDER_20: {
        'bank->bankId': {
            presence: {
                message: () => translation('payments_required'),
            },
        },
        'bank->accountType': {
            presence: {
                message: () => translation('payments_required'),
            },
        },
        'security->securityToken': {
            presence: {
                message: () => translation('payments_required'),
            },
        },
        'transactionAmount': {
            format: {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: () => translation('payments_transactionAmountPattern'),
            },
            presence: {
                message: () => translation('payments_amountEmpty'),
            }
        },
        'bank->accountNumber': {
            format: {
                pattern: /^\d+$/,
                message: () => translation('payments_accountNumberPattern'),
            },
            length: {
                minimum: 10,
                maximum: 10,
                tooShort: () => translation('payment_accoutNuberTooShort'),
                tooLong: () => translation('payment_accoutNuberTooLong'),
            },
            presence: {
                message: () => translation('payments_accountNumberEmpty'),
            },
        }
    },
    PROVIDER_27: {
        'transactionAmount': {
            format: {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: () => translation('payments_wrongAmountPattern'),
            },
            presence: {
                message: () => translation('payments_amountEmpty'),
            }
        }
    },
    PROVIDER_32: {
        'bank->bankId': {
            presence: {
                message: () => translation('payments_required'),
            },
        },
        'bank->accountType': {
            presence: {
                message: () => translation('payments_required'),
            },
        },
        'transactionAmount': {
            format: {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: () => translation('payments_transactionAmountPattern'),
            },
            presence: {
                message: () => translation('payments_amountEmpty'),
            }
        },
        'bank->accountNumber': {
            format: {
                pattern: /^\d+$/,
                message: () => translation('payments_wrongAccountNumberPattern'),
            },
            length: {
                minimum: 10,
                maximum: 10,
                tooShort: () => translation('payment_accountNumberTooShort'),
                tooLong: () => translation('payment_accountNumberTooLong'),
            },
            presence: {
                message: () => translation('payments_accountNumberEmpty'),
            },
        },
        'oldSecurityToken': {
            presence: {
                message: () => translation('payments_required'),
            },
        },
        'newSecurityToken': {
            presence: {
                message: () => translation('payments_required'),
            },
        },
        'confirmNewSecurityToken': {
            presence: {
                message: () => translation('payments_required'),
            },
            equality: {
                attribute: 'newSecurityToken',
                message: () => translation('payments_pinNotEqual'),
            }
        },
    },
    PROVIDER_37: {
        'transactionAmount': {
            format: {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: () => translation('payments_wrongAmountPattern'),
            },
            presence: {
                message: () => translation('payments_amountEmpty'),
            }
        }
    },
    PROVIDER_38: {
        'transactionAmount': {
            format: {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: () => translation('payments_wrongAmountPattern'),
            },
            presence: {
                message: () => translation('payments_amountEmpty'),
            }
        },
        'walletId': {
            presence: {
                message: () => translation('payments_accountEmpty'),
            }
        }
    },
    PROVIDER_39: {
        'transactionAmount': {
            format: {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: () => translation('payments_wrongLoginPattern'),
            },
            presence: {
                message: () => translation('payments_amountEmpty'),
            }
        }
    },
    PROVIDER_103: {
        'transactionAmount': {
            format: {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: () => translation('payments_transactionAmountPattern'),
            },
            presence: {
                message: () => translation('payments_amountEmpty'),
            },
        }
    },
    PROVIDER_104: {
        'transactionAmount': {
            format: {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: () => translation('payments_transactionAmountPattern'),
            },
            presence: {
                message: () => translation('payments_amountEmpty'),
            }
        },
        'customer->loginName': {
            presence: {
                message: () => translation('payments_loginNameEmpty'),
            },
        },
        'customerId': {
            format: {
                pattern: /^\d+$/,
                message: () => translation('payments_wrongLoginPattern'),
            },
            presence: {
                message: () => translation('payments_customerIdEmpty'),
            }
        }
    },
    PROVIDER_105: {
        'transactionAmount': {
            format: {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: () => translation('payments_wrongAmountPattern'),
            },
            presence: {
                message: () => translation('payments_amountEmpty'),
            }
        },
        'customer->loginName': {
            presence: {
                message: () => translation('payments_loginNameEmpty'),
            },
        },
        'customerId': {
            format: {
                pattern: /^\d+$/,
                message: () => translation('payments_wrongLoginPattern'),
            },
            presence: {
                message: () => translation('payments_customerIdEmpty'),
            }
        }
    },
    PROVIDER_113: {
        'bank->cbnCode': {
            presence: {
                message: () => translation('payments_required'),
            },
        },
        'transactionAmount': {
            format: {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: () => translation('payments_wrongAmountPattern'),
            },
            presence: {
                message: () => translation('payments_amountEmpty'),
            }
        },
        'additionalData': {
            format: {
                pattern: /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/,
                message: () => translation('payments_wrongLoginPattern'),
            },
            presence: {
                message: () => translation('payments_additionalDataEmpty'),
            },
        },
        'bank->accountNumber': {
            format: {
                pattern: /^\d+$/,
                message: () => translation('payments_accountNumberPattern'),
            },
            length: {
                minimum: 10,
                maximum: 10,
                tooShort: () => translation('payment_accountNumberTooShort'),
                tooLong: () => translation('payment_accountNumberTooLong'),
            },
            presence: {
                message: () => translation('payments_accountNumberEmpty'),
            },
        }
    },
    PROVIDER_114: {
        'bank->cbnCode': {
            presence: {
                message: () => translation('payments_required'),
            },
        },
        'transactionAmount': {
            format: {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: () => translation('payments_wrongAmountPattern'),
            },
            presence: {
                message: () => translation('payments_amountEmpty'),
            }
        },
        'additionalData': {
            format: {
                pattern: /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/,
                message: () => translation('payments_wrongLoginPattern'),
            },
            presence: {
                message: () => translation('payments_additionalDataEmpty'),
            },
        },
        'bank->accountNumber': {
            format: {
                pattern: /^\d+$/,
                message: () => translation('payments_wrongAccountNumberPattern'),
            },
            length: {
                minimum: 10,
                maximum: 10,
                tooShort: () => translation('payment_accountNumberTooShort'),
                tooLong: () => translation('payment_accountNumberTooLong'),
            },
            presence: {
                message: () => translation('payments_accountNumberEmpty'),
            },
        }
    },
    PROVIDER_115: {
        'bank->cbnCode': {
            presence: {
                message: () => translation('payments_required'),
            },
        },
        'transactionAmount': {
            format: {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: () => translation('payments_wrongAmountPattern'),
            },
            presence: {
                message: () => translation('payments_amountEmpty'),
            }
        },
        'additionalData': {
            format: {
                pattern: /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/,
                message: () => translation('payments_wrongLoginPattern'),
            },
            presence: {
                message: () => translation('payments_additionalDataEmpty'),
            },
        },
        'bank->accountNumber': {
            format: {
                pattern: /^\d+$/,
                message: () => translation('payments_wrongAccountNumberPattern'),
            },
            length: {
                minimum: 10,
                maximum: 10,
                tooShort: () => translation('payment_accountNumberTooShort'),
                tooLong: () => translation('payment_accountNumberTooLong'),
            },
            presence: {
                message: () => translation('payments_accountNumberEmpty'),
            },
        }
    },
    PROVIDER_234: {
        'phoneNumber': {
            presence: {
                message: () => translation('payments_phoneNumberEmpty'),
            },
            length: {
                minimum: 9,
                maximum: 9,
                tooShort: () => translation('payment_phoneNumberTooShort'),
                tooLong: () => translation('payment_phoneNumberTooLong'),
            }
        },
        'transactionAmount': {
            format: {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: () => translation('payments_wrongAmountPattern'),
            },
            presence: {
                message: () => translation('payments_amountEmpty'),
            }
        },
    },
    PROVIDER_235: {
        'phoneNumber': {
            presence: {
                message: () => translation('payments_phoneNumberEmpty'),
            },
            length: {
                minimum: 9,
                maximum: 9,
                tooShort: () => translation('payment_phoneNumberTooShort'),
                tooLong: () => translation('payment_phoneNumberTooLong'),
            }
        },
        'transactionAmount': {
            format: {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: () => translation('payments_wrongTransactionAmount'),
            },
            presence: {
                message: () => translation('payments_empty'),
            }
        }
    },
    PROVIDER_238: {
        'transactionAmount': {
            format: {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: () => translation('payments_wrongTransactionAmount'),
            },
            presence: {
                message: () => translation('payments_empty'),
            }
        }
    },
    PROVIDER_257: {
        'transactionAmount': {
            format: {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: () => translation('payments_wrongTransactionAmount'),
            },
            presence: {
                message: () => translation('payments_empty'),
            }
        }
    },
    PROVIDER_269: {
        'transactionAmount': {
            format: {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: () => translation('payments_wrongTransactionAmount'),
            },
            presence: {
                message: () => translation('payments_empty'),
            }
        }
    },

    PROVIDER_299: {
        'transactionAmount': {
            format: {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: () => translation('payments_wrongAmountPattern'),
            },
            presence: {
                message: () => translation('payments_amountEmpty'),
            }
        }
    },

    PROVIDER_304: {
        'transactionAmount': {
            format: {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: () => translation('payments_wrongTransactionAmount'),
            },
            presence: {
                message: () => translation('payments_empty'),
            }
        }
    },
    PROVIDER_305: {
        'transactionAmount': {
            format: {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: () => translation('payments_wrongTransactionAmount'),
            },
            presence: {
                message: () => translation('payments_empty'),
            }
        }
    },
    PROVIDER_310: {
        'transactionAmount': {
            format: {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: () => translation('payments_wrongAmountPattern'),
            },
            presence: {
                message: () => translation('payments_amountEmpty'),
            }
        },
        'customer->loginName': {
            presence: {
                message: () => translation('payments_loginNameEmpty'),
            },
        },
        'customerId': {
            format: {
                pattern: /^\d+$/,
                message: () => translation('payments_wrongLoginPattern'),
            },
            presence: {
                message: () => translation('payments_customerIdEmpty'),
            }
        },
        'phoneNumber': {
            presence: {
                message: () => translation('payments_phoneNumberEmpty'),
            },
            length: {
                minimum: 8,
                maximum: 10,
                tooShort: () => translation('payment_phoneNumberTooShort'),
                tooLong: () => translation('payment_phoneNumberTooLong'),
            }
        }
    },
    PROVIDER_332: {
        'bank->bankId': {
            presence: {
                message: () => translation('payments_required'),
            },
        },
        'bank->accountType': {
            presence: {
                message: () => translation('payments_required'),
            },
        },
        'transactionAmount': {
            format: {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: () => translation('payments_transactionAmountPattern'),
            },
            presence: {
                message: () => translation('payments_amountEmpty'),
            }
        },
        'bank->accountNumber': {
            format: {
                pattern: /^\d+$/,
                message: () => translation('payments_wrongAccountNumberPattern'),
            },
            length: {
                minimum: 10,
                maximum: 10,
                tooShort: () => translation('payment_accountNumberTooShort'),
                tooLong: () => translation('payment_accountNumberTooLong'),
            },
            presence: {
                message: () => translation('payments_accountNumberEmpty'),
            },
        },
        'oldSecurityToken': {
            presence: {
                message: () => translation('payments_required'),
            },
        },
        'newSecurityToken': {
            presence: {
                message: () => translation('payments_required'),
            },
        },
        'confirmNewSecurityToken': {
            presence: {
                message: () => translation('payments_required'),
            },
            equality: {
                attribute: 'newSecurityToken',
                message: () => translation('payments_pinNotEqual'),
            }
        },
    },
};

