import styled from 'styled-components';
import {List, Button} from 'global/styles/styles.js';
const S = {};

const duration = 300;

S.BetHistoryItemDetails = styled.div`
  &.slide-transition-enter {
      max-height: 0px;
      overflow: hidden;
      transition: max-height ${duration}ms;
  }
  
  &.slide-transition-enter-active {
       max-height: 10000px;
  }
  
  &.slide-transition-exit-active {
       max-height: 0;
  }
  
`;

S.BetHistoryDetailsHeader = styled.div`
    width: 100%;
    background-color: #f3f3f3;
    border-collapse: collapse;
    color: #000;
    display: flex;
    
    & > div{
        background-color: #eaeaea;
        color: #a3a3a3;
        text-align: left;
        padding: .67rem;
        border-right: 1px solid #fff;
        border-bottom: 1px solid #fff;
        height: auto;
        display: flex;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1rem;
        width: 60px;
    
        &.details-time{
            flex: 1 1 0;
            display: flex;
            justify-content: space-between;
        }
        
        &.details-bet{
            flex: 3.5 1 0;
            flex-direction: column;
        }
        
        &.details-result{
            flex: 2 1 0;
            padding-right: 4.69rem!important
        }
        &.details-odds{
             flex: 1 1 0;
        }
        &.details-score{
            flex: 2 1 0;
            padding-right: 2.01rem!important;
        }
        &.details-status{
            flex: 1 1 0;
        }
    }
  
`;

S.BetHistoryDetailsBody = styled.div`
    width: 100%;
    background-color: #f3f3f3;
    color: #000;
    display: flex;
    
    & > div{
           white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 1rem;
            background-color: #eee;
            border-right: 1px solid #fff;
            border-bottom: 1px solid #fff;
            padding: .67rem;
            height: 6.8rem;
            vertical-align: middle;
            text-align: left;
            display: flex;
            
            &.details-time{
                flex: 1 1 0;
               
                & > span{
                 display: block;
                 justify-content: space-between;
                }
            }
            
            &.details-bet{
                flex: 3.5 1 0;
                & > span{
                    flex-direction: column;
                    display: flex;
                    
                    & > .game-name{
                        font-weight: 700;
                    }
                }
            }
       
            &.details-result{
                flex: 2 1 0;
                padding-right: 4.69rem!important
            }
            &.details-odds{
                 flex: 1 1 0;
            }
            &.details-score{
                flex: 2 1 0;
                padding-right: 2.01rem!important;
            }
            &.details-status{
                flex: 1 1 0;
                color: #fff;
                border-right: none;
        
                 &.details_cancel {            
                    background-color: #e5a332;       
                  }
                  &.details_won {
                    background-color: #6CD700;
                  }
                  &.details_lost {
                    background-color: #FF4545;
                  }
                  &.details_undecided {
                    background-color: #00CFFF;
                  }
                  &.details_placed {
                    background-color: #00CFFF;
                  }
               
            }
            
    }
`;

S.BetHistoryDetailsWrapper = styled.div``;

export default S;