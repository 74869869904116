import styled from 'styled-components';
import {List} from 'global/styles/styles.js';
const S = {};

S.CategoriesTimeFilterList = styled(List)`
    padding: 8px 17px;
    background-color: ${({theme:{$categoriesTimeFilterBgColor}})=> $categoriesTimeFilterBgColor};
    display: flex;
    border-bottom: 8px solid ${({theme:{$categoriesTimeFilterBorderBottom}})=> $categoriesTimeFilterBorderBottom};
    height: 56px;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
`;

S.CategoriesTimeFilterItem = styled.li`
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({theme:{$categoriesTimeFilterItemColor}})=> $categoriesTimeFilterItemColor};
    border: 1px solid ${({theme:{$categoriesTimeFilterItemBorderColor}})=> $categoriesTimeFilterItemBorderColor};
    cursor: pointer;
    border-radius: 3px;
    height: 32px;
    width: 32px;
    padding: 0 4px;
    &:last-of-type{
        margin-right: 0;
    }
    &.is-active{
        background-color:  ${({theme:{$categoriesTimeFilterItemActiveBgColor}})=> $categoriesTimeFilterItemActiveBgColor};
        border-color: ${({theme:{$categoriesTimeFilterItemActiveBorderColor}})=> $categoriesTimeFilterItemActiveBorderColor};
        color: ${({theme:{$categoriesTimeFilterItemActiveColor}})=> $categoriesTimeFilterItemActiveColor};
    }
`;

S.TimeIcon = styled.i`
    width: 15px;
    height: 15px;
    align-self: center;
    flex-shrink: 0;
    margin-right: 23px;
    & svg {
        & g {
            & circle,
            & path {
                stroke: ${({ theme: { $iconColorOption1 } }) => $iconColorOption1};
            }
        }
    }
`;


export default S;