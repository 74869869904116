import React from 'react';
import S from 'StyledForgotPasswordForm.js';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {notifyError, translation} from 'helpers/utilsHelper.js';
import ModalOpacity from 'ModalOpacity.js';
import useToggle from 'UseToggle.js'


// Validation
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';

const validate = values => {
    const errors = validation(values, validators.FORGOT_PASSWORD);
    return errors;
};

const renderField = ({
    input,
    label,
    type,
    meta: {touched, error, warning}
}) => {
    return (
        <S.Row>
            <S.InputWrapper>
                <S.Input {...input} type={type} required="required"/>
                <S.Label>{label}</S.Label>
                {touched && ((error && <S.Error>{error[0]}</S.Error>) || (warning && <S.Error>{warning}</S.Error>))}
            </S.InputWrapper>
        </S.Row>
    )
};

const ForgotPasswordForm = ({forgotPasswordOnSubmit,  handleSubmit, pristine, submitting, error}) => {

    const [showSuccessModal, toggleSuccessModal] = useToggle(false);

    const onFormSubmit = async(values) => {
        try {
            await forgotPasswordOnSubmit(values);
            toggleSuccessModal();
        } catch (err) {
            throw new SubmissionError({_error: err});
        }
    };

    return (
        <S.ForgotPasswordForm method="post" onSubmit={handleSubmit(onFormSubmit)}>

            <ModalOpacity isOpen={showSuccessModal} toggleOpen={toggleSuccessModal} showHeaderIcon={false}
                          showHeaderTitle={false}>
                <S.SuccessModal>
                    <S.SuccessModalTitle>{translation('forgotPasswordForm_successTitle')}</S.SuccessModalTitle>
                    <S.SuccessModalMessage>{translation('forgotPasswordForm_resetPasswordSuccess')}</S.SuccessModalMessage>
                </S.SuccessModal>
            </ModalOpacity>

            <S.Info>{translation('forgotPasswordForm_text1')}</S.Info>
            <S.Info>{translation('forgotPasswordForm_text2')}</S.Info>

            <Field
                name="email"
                type="text"
                component={renderField}
                label="E-mail"
            />

            {error && <S.SubmissionError className="submission-error">{error}</S.SubmissionError>}

            <S.SubmitBtn type="submit" disabled={pristine || submitting}>{translation('forgotPasswordForm_recoverPassword')}</S.SubmitBtn>

        </S.ForgotPasswordForm>
    );
};

export default reduxForm({
    form: 'forgotPassword',
    validate
})(ForgotPasswordForm)