import styled,{css} from 'styled-components';
import {Form, Input, Button} from 'global/styles/styles.js';
import StyledLoader from 'StyledLoader.js';
const S = {};

S.CashoutForm = styled(Form)`
    width: 100%;
    margin-top: -24px;
    position: relative;
`;

S.Row = styled.div`
    display: flex;
    flex: 1;
    align-items: flex-start;
`;

S.Label = styled.label`
    color: #787d83!important;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0;
    width: 30%;
    margin-top: 19px;
`;

S.InputWrapper = styled.div`
    position: relative;
    width: 70%;
`;

S.Input = styled(Input)`
    box-shadow: none;
    border-radius: .42rem;
    border: .08rem solid #e1e1e1;
    color: #000;
    font-size: 1.33rem;
    padding: 2.1rem 1.75rem;
    background-color: #f6f6f6;
    display: block;
    width: 100%;
    height: 34px;
    &:disabled {
        border: none;
        background-color: #fff;
    }
`;

S.SubmitBtn = styled(Button)`
    border-radius: .42rem;
    background-color: #00a651;
    height: 4.6rem;
    line-height: 5rem;
    font-size: 1rem;
    font-weight: 600;
    padding: 0;
    width: 100px;
    color: #fff;
    min-width: 20%;
    cursor: pointer;
    
    ${({disabled})=> {
        return (disabled == true) && `
                background: #eee;
                opacity: 1;
                color: #676767
        `;
    }}
`;

S.Error = styled.div`
    color: red;
    margin: 2px 0 0;
    position: absolute;
`;

S.SubmissionError = styled.div`
    margin: 0;
    border-radius: .25rem;
    width: 100%;
    color: red;
    font-size: 1rem;
    text-align: left;
    padding: .83rem;
    background-color: #fba59f;
`;

S.Stake = styled.div`
    font-weight: 600;
    color: #000;
    font-size: 1.1666rem;
    text-align: right;
`;

S.PossibleReturn = styled(S.Stake)``;

S.Button = styled(Button)`
    background: #00a651;
    border-radius: .33rem;
    border: 0;
    color: #fff;
    font-size: 1.1666rem;
    cursor: pointer;
    padding: .66rem 2.67rem;
    width: 9.083rem;
`;

S.ConclusionMsg = styled.div`
    width: 100%;
    text-align: center;
    border-radius: 4px;
    padding: 10px;
    margin: 10px 0;
    position: relative;
    font-weight: 700;
    border: none;
    color: #000;
    font-size: 2.3332rem;
`;

S.Overlay = styled.div`
    position: absolute;
    left: 0;
    top: -1px;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    justify-content: center;
    align-items: center;
    display: flex;
     z-index: 1;
     & ${StyledLoader.LoaderWrapper}{
        position: absolute;
    }
`;

export default S;