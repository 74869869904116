import styled from 'styled-components';
import {List} from 'global/styles/styles.js';
const S = {};

S.FreebetCount= styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #23282e;
    color: #fff;
    text-align: center;
    font-size: 1.1666666667rem!important;
    line-height: 2rem;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 1.6666rem;
`;

S.AccountMenu = styled(List)`
    background: #eaeaea;
    width: 100%;
`;
S.AccountMenuItem = styled.li`
`;

S.AccountMenuLink = styled.a`
    color: #a3a3a3;
    height: 5.83rem;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0 0 0 1.6666rem;
    border-bottom: 1px solid #a3a3a3;
    border-right: 1px solid #a3a3a3;
    font-size: 1.0833rem;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.active,
    &:hover{
        background-color: #fff;
        color: #000;
        border-right: none;
        text-decoration: none;
    }
`;

export default S;